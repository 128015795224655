import adminLabels from "../ApplicationStrings/trans_utils";

export const ADD_EXTENSION_FORMADATA = [
  {
    type: "text",
    name: "name",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.name,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.name,
    },
    label: {
      title: adminLabels.name,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "textarea",
    name: "description",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
        maxLength: 3000,
      },
    ],
    ui: {
      label: adminLabels.description,
      class: "font-weight-bold label-asterisk",
      inputclass: "form-control",
    },
  },
  {
    type: "text",
    name: "version",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.version,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.version,
    },
    label: {
      title: adminLabels.version,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "checkbox",
    name: "showWidget",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 d-flex align-items-center font14",
    value: "0",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.showWidget,
      class: "font-weight-bold mb-0",
    },
  },
  {
    type: "textarea",
    name: "matches",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
        maxLength: 3000,
      },
    ],
    ui: {
      label: adminLabels.matches,
      class: "font-weight-bold label-asterisk",
      inputclass: "form-control",
    },
  },
  {
    type: "textarea",
    name: "exclude_matches",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
        maxLength: 3000,
      },
    ],
    ui: {
      label: adminLabels.exclude_matches,
      class: "font-weight-bold label-asterisk",
      inputclass: "form-control",
    },
  },
  {
    type: "text",
    name: "ssoId",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.ssoId,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.ssoId,
    },
    label: {
      title: adminLabels.ssoId,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "text",
    name: "clientJsBaseUrl",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.clientJsBaseUrl,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.clientJsBaseUrl,
    },
    label: {
      title: adminLabels.clientJsBaseUrl,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "textarea",
    name: "host_permissions",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    value: "",
    validators: [
      {
        required: true,
        maxLength: 3000,
      },
    ],
    ui: {
      label: adminLabels.host_permissions,
      class: "font-weight-bold label-asterisk",
      inputclass: "form-control",
    },
  },
  {
    type: "text",
    name: "login",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.login,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.login,
    },
    label: {
      title: adminLabels.login,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "text",
    name: "customer",
    value: "",
    class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
    validators: [
      {
        required: true,
        maxLength: 120,
      },
    ],
    ui: {
      label: adminLabels.customer,
      class: "col-lg-2 label-asterisk",
      inputclass: "col-lg-6",
    },
    input: {
      id: "name",
      class: "form-control",
      type: "text",
      name: "name",
      placeholder: adminLabels.customer,
    },
    label: {
      title: adminLabels.customer,
      class: "font-weight-bold label-asterisk",
      for: "name",
    },
  },
  {
    type: "checkbox",
    name: "desktopCommunication",
    class: "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 my-2 d-flex align-items-center font14",
    value: "0",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.desktopCommunication,
      class: "font-weight-bold mb-0",
    },
  },
  {
    type: "checkbox",
    name: "iframeInjection",
    class: "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 my-2 d-flex align-items-center font14",
    value: "0",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.iframeInjection,
      class: "font-weight-bold mb-0",
    },
  },
  {
    type: "checkbox",
    name: "testme",
    class: "col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 my-2 d-flex align-items-center font14",
    value: "0",
    checked: false,
    validators: [],
    ui: {
      label: adminLabels.testme,
      class: "font-weight-bold mb-0",
    },
  }
];
