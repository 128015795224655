if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.ja_JP = {
    "myGuideTitle": "MyGuide",
    "addButtonText": "追加",
    "addingButtonText": "追加中...",
    "cancelButtonText": "キャンセル",
    "okButtonText": "OK",
    "updateButtonText": "更新",
    "updatingButtonText": "更新中...",
    "deleteButtonText": "削除",
    "deletingButtonText": "削除中...",
    "uploadButtonText": "アップロード",
    "uploadingButtonText": "アップロード中",
    "saveButtonText": "保存",
    "savingButtonText": "保存中...",
    "saveSettingsButtonText": "更新",
    "noDataFoundText": "該当するデータは見つかりません。",
    "generateButtonText": "生成",
    "generatingButtonText": "生成中...",
    "actionDeclined": "アクションを拒否します",
    "searchPlaceholder": "検索",
    "searchNameIdPlaceholder": "名/姓、メールアドレスの検索",
    "selectDatesPlaceholder": "日付の選択",
    "enterIDPAttributeNamePlaceholder": "IDP属性名を入力してください",
    "domainFormatPlaceholder": "name@domain.com",
    "error": "エラー",
    "yes": "はい",
    "no": "いいえ",
    "all": "すべて",
    "web": "ウェブ",
    "desktop": "デスクトップ",
    "android": "Android",
    "ios": "iOS",
    "mobileWeb": "モバイルウェブ",
    "user": "ユーザー",
    "currentAdminPassword": "現在の管理者パスワード",
    "note": "ノート:",
    "domainMessage": "SSO IDPはデフォルトで組織にバインドされています。 ユーザーがウェブドメインを訪問し、セッションが見つからない場合、デフォルトのSSO URLがトリガーされます。 このセクションでは、SSO URLを特定のドメインにバインドし、SSO URLのみが関連付けられたドメインにトリガーされるようにします。 このようにして、複数のドメインで複数のSSO IDPを持つことができます。",
    "refresh": "更新",
    "iconWorks": "アイコンが機能します",
    "pageNotWorks": "[ページが見つかりません]が機能します",
    "valid": "有効。",
    "selectAll": "すべて選択",
    "guideTitle": "ガイド名",
    "copy": "コピー",
    "copied": "コピーしました",
    "on": "オン",
    "off": "オフ",
    "publish": "公開",
    "unpublished": "未公開",
    "locked": "ロックされています",
    "unlocked": "ロック解除",
    "superAdmin": "スーパー管理者",
    "download": "ダウンロード",
    "noDataFound": "該当するデータが見つかりません",
    "and": "および",
    "guideRoleAdministrator": "組織管理者",
    "guideRoleCreator": "作成者",
    "guideRolePlayer": "プレイヤー",
    "guideRoleAppAdmin": "アプリ管理者",
    "tableHeaderStatus": "ステータス",
    "tableHeaderProgress": "進捗",
    "tableHeaderTitle": "タイトル",
    "tableHeaderType": "タイプ",
    "tableHeaderCreationDate": "作成日",
    "tableHeaderActions": "アクション",
    "tableHeaderMarkAll": "すべてマーク",
    "tableHeaderDescription": "説明",
    "pleaseSelect": "選択してください",
    "close": "終了",
    "clear": "クリア",
    "apply": "適用",
    "patternInvalid": "パターンが無効です",
    "isRequired": "必須",
    "confirmNewPassword": "新しいパスワードの確認",
    "newPassword": "新しいパスワード",
    "currentPassword": "現在のパスワード",
    "addApplicationAddBreadcrumb": "アプリケーションの追加",
    "addApplicationEditBreadcrumb": "アプリケーションの編集",
    "addApplicationUploadThumbnail": "アプリケーションのサムネイル画像のアップロード",
    "addApplicationGuideAutomation": "ガイド自動化",
    "addApplicationApplicationLevelTranslationLanguages": "アプリケーションレベル翻訳言語",
    "addApplicationEnableApplicationLanguageSettings": "アプリケーションレベル言語設定を有効にする",
    "addApplicationPackageId": "パッケージID",
    "addApplicationBundleId": "バンドルID",
    "addApplicationMaxApplicationLimit": "組織がアプリケーション上限を超えているため、新規アプリケーションを追加できません。",
    "addApplicationIdExists": "パッケージID/バンドルIDが既に存在するため、新規アプリケーションを追加できません。",
    "addFolderName": "フォルダ名",
    "addOrganisationName": "組織名",
    "addOrganisationType": "組織タイプ",
    "addUserAvtar": "アバター",
    "addUserFirstName": "名",
    "addUserLastName": "姓",
    "addUserEmailId": "メールアドレス",
    "addUserOrganisationRole": "アプリアクセス役割",
    "addUserLockedStatus": "ロックされたステータス",
    "addUserRole": "ユーザーの役割",
    "addUserUUName": "UUName",
    "labelDoItForMe": "DoItForMe",
    "labelMyChat": "マイチャット",
    "labelTeachMe": "TeachMe",
    "labelTutorialGuides": "チュートリアルガイド",
    "labelGuideTranslation": "ガイド翻訳",
    "labelShowCurrentPageGuidesOnly": "現在の PageGuides のみ表示",
    "labelTooltipEnhancements": "Tooltip 機能強化",
    "labelUserInformationTracking": "ユーザー情報トラッキング",
    "labelSSO": "SSO",
    "labelMediaFileCreation": "メディアファイルの作成",
    "labelYoutubeSettings": "YouTube設定",
    "labelEnableOrgAndGuidesJSONCaching": "組織 ガイドJSONのキャッシュを有効にする",
    "labelGuideAutomation": "ガイド自動化",
    "labelAPIConnector": "APIコネクター",
    "labelLoadContextChangeOnPageClick": "ページクリック時にコンテキストの変更をロード",
    "labelTaskList": "タスクリスト",
    "labelMediaFileTheme": "メディアファイルのテーマ",
    "labelDisplayGuidePublishingDate": "ガイド公開日の表示",
    "labelMaintenanceMode": "メンテナンスモード",
    "labelEnableGuidemeAsSecondaryOption": "Guidemeを2番目のオプションとして有効化",
    "labelHashPlayerUsersData": "ハッシュプレイヤーユーザーのデータ",
    "labelAllowOnPremiseDeployment": "オンプレミス展開を許可",
    "labelFTPEndpointToSaveContent": "コンテンツを保存するためのFTPエンドポイント",
    "labelEnableInsights": "Insights を有効にする",
    "labelDisplayAcceptCookiePopup": "承諾クッキーポップアップの表示",
    "labelSetRefreshTokensExpiryTime": "更新トークンの有効期限を設定（秒単位）",
    "labelGuideInsights": "Guide Insights",
    "labelRealTimeInsights": "リアルタイム Insights",
    "labelInsightsPriority": "Insights 優先順位",
    "labelMaxGuideLimit": "ガイド上限",
    "labelMaxApplicationLimit": "アプリケーション上限",
    "labelMaxStepLimit": "ステップ上限",
    "labelMaxAdminUsers": "Admin の最大ユーザー数",
    "labelMaxCreatorUsers": "作成者数の上限",
    "labelMaxPlayerUsers": "プレイヤー数の上限",
    "tooltipDoItForMe": "ガイドがDo it for meでプレイ可能にする（自動モード）",
    "tooltipMyChat": "バーチャルチャットアシスタントを有効にします",
    "tooltipTeachMe": "ホストアプリケーションのシミュレーションでのガイド再生を有効にします",
    "tooltipTutorialGuides": "チュートリアルガイド機能を有効にする",
    "tooltipGuideTranslation": "ガイドを有効にして、MyGuideがサポートしている1つ以上の言語に変換できるようにします",
    "tooltipShowBeaconGuidesOnCurrentPage": "現在のページにビーコンを表示するよう設定されたガイドを有効にします。デフォルトでは、ビーコンはページ上に表示され、対応するガイドは現在のページ上に表示されません。",
    "tooltipGuideSegmentation": "ユーザーがユーザー属性データに基づいてアクセスできないガイドを管理",
    "tooltipShowCurrentPageGuidesOnly": "現在のページで使用できないガイドを含むすべてのGuideのタブとフォルダ階層を非表示にします。",
    "tooltipTooltipEnhancements": "Tooltip 機能強化",
    "tooltipUserInformationTracking": "ユニークユーザーデータをトラッキングすることで、より正確な Insights トラッキングを実現します",
    "tooltipSSO": "クライアントIDPとのシングルサインオン統合を有効にします",
    "tooltipMediaFileCreation": "MyGuideのコンテンツを.pdf、.docxなどのさまざまな形式で自動エクスポートします。",
    "tooltipYoutubeSettings": "Youtubeコネクターを使用してYouTubeにガイドを公開できます。",
    "tooltipEnableOrgAndGuidesJSONCaching": "組織JSONですべてのガイドとすべてのステップを含めることができます。公開済みガイドが100より少ない組織にのみ推奨します。組織JSONの合計サイズが4MBを超える場合は、無効にする必要があります。",
    "tooltipAPIConnector": "サードパーティ製APIコネクターでデータをインポートできるようにします。サポートされているコネクターはZendeskなどです。",
    "tooltipLoadContextChangeOnPageClick": "ユーザーがページクリック時にルール検索を制限できるようにします",
    "tooltipTaskList": "タスクとしてユーザーに割り当てるガイドベースのタスクリストの作成を有効にします",
    "tooltipMediaFileTheme": "メディアファイルテーマの選択とカスタマイズを有効にします",
    "tooltipDisplayGuidePublishingDate": "動画、PDFなどのダウンロード可能な形式でのガイド公開日の表示を有効にします。",
    "tooltipMaintenanceMode": "メンテナンスモード",
    "tooltipEnableGuidemeAsSecondaryOption": "デフォルトのshowmeで作成されたガイドのセカンダリモードとして、guidemeを有効にします。",
    "tooltipHashPlayerUsersData": "ハッシュプレイヤーユーザーのデータ",
    "tooltipAllowOnPremiseDeployment": "MyGuideの展開と、ローカル/イントラネット設定としての運用を可能にします",
    "tooltipFTPEndpointToSaveContent": "ftp:から開始する有効なFTPアドレスを入力してください//.例:ftp://1.2.3.4:21またはftp://abc.example.com:21",
    "tooltipEnableInsights": "デフォルトで有効になっています。無効にした場合、組織のすべての種類のインサイトが停止します",
    "tooltipDisplayAcceptCookiePopup": "承諾クッキーポップアップの表示を有効にします",
    "tooltipSetRefreshTokensExpiryTime": "更新トークンの有効期限を設定",
    "tooltipGuideInsights": "ガイド、ツールヒント、ビーコンなどを使用して、MyGuideとのユーザーのやり取りに関するガイドインサイトを有効にします。デフォルトで有効にできます",
    "tooltipRealTimeInsights": "この機能を有効にすると、インサイトポータルの特定のセクションに関するリアルタイムデータの反映を実現します",
    "tooltipAppInsights": "クリックトラッキング、ページトラッキング、ワークフロートラッキングなどのアプリケーションインサイトを有効にします。これは、ユーザーがガイド、ビーコン、ヒントなどを操作しなくても、バックグラウンドで機能します。",
    "tooltipInsightsPriority": "MyGuide の Insights 優先順位の設定",
    "languageTranslationsSettings": "言語変換ルール",
    "englishDefault": "1.言語変換は、デフォルト言語として英語（任意のバリアント）が選択されている場合にのみ使用できます。",
    "languageEdited": "2.デフォルト言語が編集されている場合は、[言語の選択]オプションで以前に追加した言語を再度有効にする必要があります。",
    "defaultLanguage": "デフォルト言語",
    "defaultVoice": "デフォルトの音声",
    "moreLanguage": "その他の言語",
    "guideOrWorkflowAutomation": "ガイド自動化またはワークフロー自動化",
    "cannotBeDisabled": "このオプションは、一度有効にすると無効にできません。",
    "okToApply": "この設定を適用するには、[OK]ボタンをクリックしてください。",
    "addToConnector": "コネクターの追加",
    "doYouWantToDelete": "削除しますか?",
    "segmentData": "データ:",
    "segmentKeyName": "キー名:",
    "ssoDescription": "SSOの説明",
    "ssoIssuerURL": "発行元URL",
    "ssoCertificate": "x.509認定書",
    "ssoAPIKey": "API キー",
    "ssoAPISecret": "API 秘密",
    "ssoNameIdEncrypted": "NameIdを暗号化しますか?",
    "ssoMetaDataSigned": "メタデータに署名しますか?",
    "ssoResponseSigned": "レスポンスに署名しますか?",
    "ssoAssertionSigned": "アサーションに署名しますか?",
    "ssoSignatureAlgorithm": "署名アルゴリズム",
    "ssoDigestAlgorithm": "ダイジェストアルゴリズム",
    "ssoMessageSigned": "メッセージに署名しますか?",
    "ssoWish": "NameId暗号化に同じ認定書を使用しますか?",
    "ssoEncryptCertificate": "暗号化x509認定書",
    "ssoAllowAplicationSingleLayout": "アプリケーションがシングルログアウトを開始できるようにしますか?",
    "ssoAuthRequestSigned": "Authnリクエストに署名しますか?",
    "ssoConfirmationMessage": "SSO確認メッセージ",
    "ssoButtonMessage": "SSOボタンメッセージ",
    "ssoSingleLogoutURL": "シングルログアウトURL",
    "ssoListName": "名前:*",
    "userProvisioningMethod": "ユーザープロビジョニング方法",
    "apiConnectorToken": "トークン",
    "apiConnectorEmailAddress": "メールアドレス",
    "apiConnectorAPIEndpoint": "APIエンドポイント",
    "apiConnectorFolder": "フォルダ",
    "organisationFormDualAuthentication": "2段階認証",
    "organisationFormUploadOrganisationThumbnail": "組織のサムネイル画像のアップロード",
    "organisationFormUploadFirstSlide": "最初のスライド画像をアップロード",
    "organisationFormUploadFirstSlideText": "最初のスライドテキスト",
    "organisationFormUploadLastSlide": "最後のスライド画像をアップロード",
    "organisationFormUploadLastSlideText": "最後スライドテキスト",
    "organisationFormCopyrightText": "著作権テキスト",
    "applicationCreatedText": "アプリケーションが作成されました",
    "applicationUpdatedText": "アプリケーションが更新されました",
    "applicationDeletedText": "アプリケーションが削除されました",
    "applicationAssignedText": "アプリケーションが割り当てられました",
    "automationStatusUpdatedText": "オートメーションステータスが更新されました",
    "roleCreatedText": "役割が作成されました",
    "roleUpdatedText": "役割が更新されました",
    "roleDeletedText": "役割が削除されました",
    "roleAssignedText": "割り当てられた役割",
    "roleAssignedToApplicationText": "役割がアプリケーションに割り当てられました",
    "organisationRoleAssignedText": "組織の役割が割り当てられました",
    "credentialsCreatedText": "資格情報が作成されました",
    "credentialsDeletedText": "資格情報が削除されました",
    "segmentCreatedText": "セグメントが作成されました",
    "segmentUpdatedText": "セグメントが更新されました",
    "segmentDeletedText": "セグメントが削除されました",
    "segmentValueCreatedText": "セグメント値が作成されました",
    "segmentValueDeletedText": "セグメント値が削除されました",
    "userCreatedText": "ユーザーが作成されました",
    "userUpdatedText": "ユーザーが更新されました",
    "userDeletedText": "ユーザーが削除されました",
    "domainCreatedText": "ドメインが作成されました",
    "domainDeletedText": "ドメインが削除されました",
    "updatedDomainSsoMapping": "ドメインSSOマッピングが更新されました",
    "apiConnectorAddedText": "APIコネクタが追加されました",
    "apiConnectorDeletedText": "APIコネクターが削除されました",
    "taskListCreatedText": "タスクリストが作成されました",
    "taskListUpdatedText": "タスクリストが更新されました",
    "taskListDeletedText": "タスクが削除されました",
    "visibilitySettingsUpdatedText": "表示設定が更新されました",
    "adminSettingsUpdatedText": "Admin 設定が更新されました",
    "importedText": "インポート済み",
    "userUnlockedText": "ユーザーがロック解除されました",
    "userStatusChangedText": "ユーザーステータスが変更されました",
    "sessionClearedforUserText": "セッションがユーザーのためにクリアされました",
    "passwordChangedText": "パスワードが変更されました",
    "organisationUpdatedText": "組織が更新されました",
    "organisationActiveStatusChangedText": "組織のアクティブステータスが変更されました",
    "organisationVerifiedStatusChangedText": "組織の検証済みステータスが変更されました",
    "organisationCreatedText": "組織が追加されました",
    "organisationDeletedText": "組織が削除されました",
    "ssoCreatedText": "SSOが追加されました",
    "ssoDeletedText": "SSOが削除されました",
    "changesReflectedText": "変更内容は、数分後にプレイヤーポータルに反映されます",
    "sessionClearedForOrganisationText": "組織のセッションが正常にクリアされました",
    "ssoConfigurationUpdatedText": "SSO設定が更新されました",
    "instantUpdateText": "インスタント更新が正常に完了しました",
    "sessionClearedText": "セッションがクリアされました",
    "successfullyText": "成功",
    "successText": "成功",
    "login": "ログイン",
    "loginInvalidCredentials": "ログイン資格情報が無効です。再試行してください",
    "loginAccessForbidden": "アクセス禁止",
    "loginPassword": "パスワード",
    "loginFillField": "このフィールドに入力してください。",
    "loginFieldMandatory": "このフィールドは必須です。",
    "loginContactEdcast": "ヘルプが必要な場合は、メールでお問い合わせください。",
    "loginCopyright": "著作権©",
    "support": "support@csod.com",
    "forgotPassword": "パスワードを忘れた場合",
    "resetPassword": "パスワードのリセット",
    "forgotPasswordBackToLogin": "ログインに戻る",
    "forgotPasswordResendLink": "リンクの再送信",
    "forgotPasswordInstructions": "-これが有効なMyGuide IDの場合は、新しいパスワードの作成方法に関する指示を含むメールを受信する必要があります。",
    "forgotPasswordLinkSent": "パスワードリセットリンクが送信されました",
    "forgotPasswordDidntRecieveMail": "メールを受信できませんでしたか?",
    "forgotPasswordCheckSpam": "スパムフォルダまたは一括フォルダを確認してください",
    "generatePasswordSuccesfullyReset": "パスワードが正常にリセットされました",
    "generatePasswordLinkExpired": "パスワード生成リンクが失効済みまたはワンタイムパスワードが無効です",
    "generatePasswordMustContain": "新しいパスワードには次を含める必要があります:",
    "generatePasswordCharacterLimit": "8~20文字",
    "generatePasswordOneNumber": "少なくとも1つの数字",
    "generatePasswordOneUpperCase": "大文字を1字以上含める必要があります",
    "generatePasswordOneSpecialCharacter": "少なくとも1つの特殊文字が必要です（例 @,*,!）",
    "generatePasswordShouldMatch": "[新規パスワード] および [新規パスワードの確認] フィールドが一致する必要があります",
    "organisationSelectText": "組織の選択",
    "organisationAddText": "組織の追加",
    "organisationEditText": "組織の編集",
    "organisationActiveText": "アクティブ",
    "organisationVerifiedText": "確認済み",
    "organisationPaid": "有償",
    "organisationTrial": "トライアル",
    "organisationHeading1": "動画 GIF設定",
    "organisationTooltip1": "次の設定を使用して、組織用に生成されたガイドの動画およびGIF形式の構造をカスタマイズします。",
    "dashboardTitle": "Dashboard | MyGuide",
    "dashboardTriggerInstantUpdateButton": "インスタント更新をトリガー",
    "dashboardClearSessionButton": "セッションをクリアします",
    "dashboardTriggerInstantUpdateButtonTooltip": "プレイヤーポータルは1時間ごとに更新されます。インスタント更新をトリガーするには、ここをクリックしてください。変更内容は2~10分後に反映されます。",
    "dashboardClearSessionButtonTooltip": "組織のセッションをクリアします。",
    "dashboardUserAction": "ユーザーアクション",
    "dashboardUserKey": "ユーザーキー",
    "dashboardRegisteredDate": "登録日",
    "apiAndServicesTitle": "APIとサービス",
    "apiAndServicesBreadCrumbTitle": "API サービス",
    "apiAndServicesGenrateCrendentialsButton": "資格情報の生成",
    "apiAndServicesTableHeaderKeyName": "キー名",
    "apiAndServicesTableHeaderKey": "キー",
    "apiAndServicesTableHeaderSecret": "秘密",
    "apiAndServicesTableHeaderAnonymousAccess": "匿名アクセス",
    "apiAndServicesTableHeaderJITProvisioning": "JITプロビジョニング",
    "applicationsTitle": "アプリケーション |",
    "applicationsAssignTitle": "アプリケーションの割当",
    "applicationsBreadCrumbTitle": "アプリケーション",
    "applicationsTableHeaderThumbnail": "サムネイル",
    "applicationsTableHeaderApplicationName": "アプリケーション名",
    "applicationsTableHeaderApplicationId": "アプリID",
    "applicationsTableHeaderMobileApplicationId": "モバイルアプリケーションID",
    "applicationsTableHeaderAutomationStatus": "オートメーションステータス",
    "applicationsUpdateAutomationStatus": "オートメーションステータスの更新",
    "changeAutomationStatus": "このアプリケーションのオートメーションステータスを変更しますか?",
    "rolesAddRoleButtonText": "役割の追加",
    "rolesAddingRoleButtonText": "役割の追加中...",
    "rolesUpdateRoleButtonText": "役割の更新",
    "rolesUpdatingRoleButtonText": "役割の更新中...",
    "usersBreadCrumbTitle": "ユーザー:",
    "segmentsTableHeaderKeyName": "キー名",
    "segmentsListTableHeaderValue": "値",
    "segmentsListTableHeaderIsDefault": "デフォルトですか?",
    "segmentsAddSegmentButtonText": "セグメントの追加",
    "segmentsUpdateSegmentButtonText": "セグメントの更新",
    "segmentsAddValueButtonText": "値の追加",
    "segmentsAddingValueButtonText": "値の追加中...",
    "segmentsAddingSegmentButtonText": "セグメントの追加中...",
    "segmentsUpdatingSegmentButtonText": "セグメントを更新しています。",
    "addSegmentsValue": "セグメント値の追加",
    "domainsAddDomainDomainName": "ドメイン名",
    "domainsAddDomainSSOIDP": "SSO IDP",
    "domainsAddDomainButtonText": "ドメインの追加",
    "domainsAddingDomainButtonText": "ドメインの追加中...",
    "apiConnectorTableHeaderAPIName": "APIの名前",
    "apiConnectorTableHeaderSyncCompleted": "同期済み",
    "ssoListTableHeaderSSOName": "SSO名",
    "ssoListDownloadMetadata": "メタデータのダウンロード",
    "ssoListIdentityProviders": "IDプロバイダー",
    "ssoListDomainMappings": "ドメインマッピング",
    "addSSOIDP": "SSO IDPを追加",
    "addSSO": "SSOを追加",
    "updateSSO": "SSOの更新",
    "featureSettings": "機能設定",
    "insightsSettings": "Insights 設定",
    "limitSettings": "設定の制限",
    "languageTranslations": "言語変換",
    "chooseImage": "画像を選択",
    "adminSettingsWarning": "警告 メンテナンスモードを有効にすると、この組織が無効になります",
    "adminSettingsHashPlayersData": "ハッシュプレイヤーユーザーのデータ",
    "adminSettingsEnableSure": "有効にしますか",
    "adminSettingsYouCanEither": "次の中から有効にできます",
    "adminSettingsGuideAutomation": "ガイド自動化",
    "adminSettingsWorkflowAutomation": "Workflow オートメーション",
    "adminSettingsUnselectProceed": "続行するには、次のいずれかを選択解除してください",
    "activityTableHeaderActivity": "アクティビティ",
    "activityTableHeaderActivityEmailId": "アクティビティメールアドレス",
    "activityTableHeaderActivityIPAddress": "IPアドレス",
    "activityAdmin": "Admin アクティビティ",
    "activityLogin": "ログインアクティビティ",
    "activityType": "アクティビティタイプ",
    "activityAction": "アクション",
    "activityDetails": "アクティビティの詳細",
    "activityTableHeaderActivityIPAddresss": "IPアドレス",
    "userProvisioningTitle": "ユーザー プロビジョニング |",
    "userProvisioningBreadCrumbTitle": "ユーザープロビジョニング",
    "userProvisioningProvideKey": "キー名を指定",
    "exportDataExportGuideButtonText": "ガイドデータのエクスポート",
    "exportDataExportUserButtonText": "ユーザーデータのエクスポート",
    "exportDataTableHeaderExportType": "エクスポートタイプ",
    "exportDataTableHeaderExportedBy": "エクスポート者",
    "guideList": "ガイドリスト",
    "userList": "ユーザーリスト",
    "selectApplications": "アプリケーションの選択",
    "selectApplicationsMessage": "ガイドデータをエクスポートするにはアプリケーションを選択してください",
    "exportDetails": "詳細のエクスポート",
    "taskListAddTaskButtonText": "タスクの追加",
    "taskListAddingTaskButtonText": "タスクの追加中...",
    "taskListUpdateTaskButtonText": "タスクの更新",
    "taskListUpdatingTaskButtonText": "タスクの更新中...",
    "taskListTableHeaderApplication": "アプリケーション",
    "taskListTableHeaderLeaderboard": "ポイントランキング",
    "addGroup": "グループの追加",
    "removeGroup": "グループの削除",
    "updateTaskList": "タスクリストの更新",
    "addTaskList": "タスクリストの追加",
    "formMessage1": "1. ドメイン名は、ピリオド '.' を区切りとして少なくとも2つの部分から構成する必要があります。",
    "formMessage2": "2. ドメイン名部分をハイフン '-' で開始または終了することはできません。",
    "formMessage3": "3. ドメイン名にはa~z、数字0~9、およびハイフンを含めることができます。",
    "formMessage4": "4. ドメイン名の後続部分は、少なくとも2文字にする必要があります。",
    "recommendedSize": "(推奨サイズ500 X 500 px) サポートされているファイル: JPG、PNG",
    "recommendedSize2": "推奨サイズ（500 px X 500 px）。",
    "topLeft": "左上",
    "topRight": "右上",
    "bottomLeft": "左下",
    "bottomRight": "右下",
    "importUserVariable1": "インポートユーザー用CSVファイルのアップロード",
    "importUserVariable2": "CSVファイルをここにドラッグ＆ドロップします",
    "importUserVariable4": "参照",
    "importUserVariable5": "無効なファイルタイプです",
    "importUserVariable6": "一括メールの送信:",
    "importUserVariable7": "はい",
    "importUserVariable8": "いいえ",
    "importUserVariable9": "アップロード",
    "importUserVariable10": "ユーザーファイル履歴のインポート",
    "importUserVariable11": "更新",
    "importUserVariable12": "サンプルCSVのダウンロード",
    "importUserVariable13": "または",
    "generateCredentialsVariable1": "資格情報の生成についての情報",
    "generateCredentialsVariable2": "匿名",
    "generateCredentialsVariable3": "-ユーザーがMyGuideに情報を保存しない場合は、[匿名]を選択してください。",
    "generateCredentialsVariable4": "JIT",
    "generateCredentialsVariable5": "[JIT]を選択した場合、このメールが既にMyGuideに登録済みであるかどうかを確認します。そうでない場合は、ユーザーをMyGuideユーザーとして追加します。",
    "generateCredentialsVariable6": "キー名",
    "generateCredentialsVariable7": "- このフィールドには、このキーを作成する目的を入力します。 例: SSO、テスト、Salesforce、.js など",
    "generateCredentialsVariable8": "画像を選択",
    "generateCredentialsVariable15": "アプリケーションの選択",
    "generateCredentialsVariable16": "アプリケーションは必須",
    "headerVariable1": "組織:",
    "headerVariable3": "パスワードの変更",
    "headerVariable4": "ログアウト",
    "manageContentVariable1": "タスクリスト:",
    "manageContentVariable5": "アプリケーション:",
    "manageContentVariable6": "プレビュー",
    "manageContentVariable10": "ガイドがありません",
    "manageContentVariable12": "ガイドの管理",
    "manageContentVariable13": "フォルダの追加",
    "manageContentVariable17": "全て選択",
    "manageContentVariable18": "ガイド選択済み",
    "manageContentVariable21": "このアプリケーションには公開されているガイドがありません",
    "manageContentVariable27": "前へ",
    "manageContentVariable29": "次へ",
    "manageContentVariable30": "このフォルダにはガイドが含まれているため削除できません。ガイドを削除し、再試行してください",
    "manageRoleVariable5": "役割の追加",
    "manageRoleVariable1": "役割の割当",
    "otpVariable1": "ログインに戻る",
    "otpVariable2": "アカウントの確認",
    "otpVariable3": "ユーザーのロック解除",
    "otpVariable4": "ワンタイムパスワードが送信されました",
    "otpVariable6": "ワンタイムパスワードを再送信",
    "otpVariable7": "ログインに戻る",
    "otpVariable8": "ユーザーアカウントが正常にロック解除されました。",
    "otpVariable9": ". ワンタイムパスワードを入力して、アカウントを確認してください",
    "otpVariable10": "ワンタイムパスワードを入力して、アカウントをロック解除してください",
    "otpVariable11": "ワンタイムパスワード",
    "otpVariable12": "無効なワンタイムパスワード",
    "otpVariable13": "正しいワンタイムパスワードで再試行してください。",
    "otpVariable14": "ワンタイムパスワードを受信できませんでした",
    "otpVariable15": "ワンタイムパスワードを再送信",
    "attributeMapping": "属性マッピング",
    "stepTitle": "ステップタイトル",
    "stepDescription": "ステップの説明",
    "stepTooltips": "ステップのツールチップ",
    "pageThemeNumber": "Page ##",
    "themeListVariable1": "PPT/WORD/PDFのテーマ設定",
    "themeListVariable2": "情報:",
    "themeListVariable3": "アクティブなテーマを使用して、PPT（pptx）、PDF（.pdf）、および文書（.docx）を作成します",
    "themeListVariable4": "アクティブ",
    "themeListVariable5": "有効化",
    "themeListVariable6": "テーマのアクティブ化",
    "themeListVariable7": "デフォルトテーマを変更しますか?",
    "themeListVariable8": "いいえ",
    "themeListVariable9": "はい",
    "usersVariable2": "ステータス",
    "usersVariable3": "すべて",
    "usersVariable4": "アクティブ",
    "usersVariable5": "サスペンド済み",
    "usersVariable6": "すべて",
    "usersVariable7": "組織 Admin",
    "usersVariable8": "作成者",
    "usersVariable9": "プレイヤー",
    "usersVariable10": "アプリ Admin",
    "usersVariable11": "MG Admin",
    "usersVariable12": "アプリアクセス役割",
    "usersVariable13": "すべて",
    "usersVariable14": "データをエクスポート",
    "usersVariable19": "ロックされたステータスの更新",
    "usersVariable20": "更新ステータス",
    "usersVariable22": "このユーザーをロック解除しますか?",
    "usersVariable23": "このユーザーのステータスを更新しますか?",
    "usersVariable25": "ロック解除",
    "usersVariable26": "はい",
    "usersVariable27": "ユーザーの追加",
    "usersVariable28": "ユーザーのインポート",
    "usersVariable29": "ロックされたステータスの更新",
    "usersVariable30": "ステータスの更新",
    "usersVariable31": "ユーザーの役割",
    "usersProvisioningVariable30": "1. 新規プロビジョニングトークンを生成すると、既存のプロビジョニングトークンが削除/置き換えられます。 これにより、旧トークンを使用してユーザープロビジョニングAPIにアクセスできなくなります。",
    "usersProvisioningVariable31": "2. 生成された資格情報は、以下で選択したプロビジョニング方法に依存します。",
    "usersProvisioningVariable1": "このポップアップを閉じる前に、上記の資格情報をコピーしてください。 このポップアップを閉じた後、再度表示することはできません。",
    "usersProvisioningVariable2": "APIトークン",
    "usersProvisioningVariable3": "続行しますか?",
    "tableUnmapped": "マップ解除",
    "tableTitle1": "セグメント値の割当",
    "tableTitle2": "編集",
    "tableTitle3": "コンテンツの管理",
    "tableTitle4": "クリックして編集",
    "tableTitle5": "すべてのセッションからサインアウト",
    "visibility": "表示設定",
    "taskVariable1": "パスは常にスラッシュで始まり、英数字のみと特殊文字（~、=、<>、/、* のみ）が使用できます。パス文字列にスペースを使用できません。",
    "taskVariable2": "このフィールドは必須です。",
    "taskVariable3": "二重スラッシュは使用できません。",
    "taskVariable4": "<>スラッシュの後に一緒に使用できません。",
    "taskVariable5": "エラー: _ と - は角括弧（<>）の中以外では使用できません。",
    "taskVariable6": "パスに~または=を単独で指定することはできません。~は常に=に従います。",
    "taskVariable7": "無効なパラメータです。 パラメータは常に「?」で始まり、「?」の後に「=」を続けることはできません。",
    "taskVariable8": "無効なパラメータです。「」の後には、英数字と特殊文字「-」「,」「 _」が使用できます。",
    "taskVariable9": "無効なパラメータです。 英数字と特殊文字のみ「-」「 _」「=」「」が使用できます。",
    "taskVariable10": "無効なハッシュ: これは常に「#」で始まります。",
    "applications": "アプリケーション",
    "users": "ユーザー",
    "manageRoles": "役割の管理",
    "themes": "テーマ",
    "activity": "アクティビティ",
    "exportData": "データをエクスポート",
    "taskList": "タスクリスト",
    "segments": "セグメント",
    "domains": "ドメイン",
    "adminSettings": "管理者設定",
    "themeSettings": "テーマ設定",
    "guidePublishingDate": "ガイド公開日の表示",
    "firstSlide": "最初のスライド",
    "lastSlide": "最後のスライド",
    "setDisplay": "表示ポジションの設定",
    "setFirstSlide": "最初のスライド表示ポジションを設定",
    "setLastSlide": "最後のスライド表示ポジションを設定",
    "markAll": "すべてマーク",
    "language": "言語",
    "voice": "ボイス",
    "uploadProfileImage": "プロフィール画像のアップロード",
    "downloadSampleCSV": "サンプルCSVのダウンロード",
    "done": "完了",
    "started": "開始済み",
    "pending": "保留中",
    "failed": "失敗",
    "changeUserPassword": "ユーザーパスワードの変更",
    "segmentValues": "セグメント値",
    "tokenGenerated": "トークンが生成されました",
    "availableSoon": "ファイルはすぐに使用できるようになります",
    "exportInProgress": "エクスポートの処理中!",
    "themeActivated": "テーマがアクティブ化",
    "themeUpdated": "テーマがアクティブ化",
    "themeGenerated": "テーマが生成されました",
    "themeActivation": "テーマのアクティブ化",
    "invalidExtension": "無効な拡張子またはサイズが2MBを超えています。",
    "unableUpdateLogo": "ロゴを更新できません",
    "create": "作成",
    "delete": "削除",
    "update": "更新",
    "field": "フィールド",
    "originalValue": "元の値",
    "newValue": "新しい値",
    "message1": "警告 ハッシュプレイヤーユーザーのデータが有効になると、無効にできません",
    "message2": "Insights を有効にするには、以下のオプションのうち少なくとも1つを有効にする必要があります",
    "high": "高",
    "medium": "中",
    "low": "低",
    "view": "表示",
    "details1": "詳細",
    "assign": "割当",
    "label1": "パスワードの更新",
    "label2": "プロフィールの更新",
    "label3": "キーの生成",
    "label4": "キーの更新",
    "label5": "キーの削除",
    "label6": "アプリに割当",
    "label7": "ユーザーの役割",
    "label8": "プロビジョニング",
    "label9": "APIキー",
    "label10": "組織",
    "label11": "アプリケーション",
    "label12": "カテゴリオーダー",
    "label13": "カテゴリ",
    "label14": "ツアーオーダー",
    "label15": "管理者設定の更新",
    "label16": "SSO設定の作成",
    "label17": "SSO設定の更新",
    "label18": "SSO設定の削除",
    "label22": "カテゴリオーダーの更新",
    "label23": "サブカテゴリの作成",
    "label24": "サブカテゴリの更新",
    "label25": "サブカテゴリの削除",
    "label26": "ツアーオーダーの更新",
    "label27": "タスクリストの作成",
    "label28": "タスクリストの削除",
    "label29": "タスクリストの公開",
    "label30": "タスクリストの更新",
    "label31": "タスクリスト設定の更新",
    "label32": "タスクリストフォルダの作成",
    "label33": "タスクリストフォルダの更新",
    "label34": "タスクリストフォルダの削除",
    "label35": "タスクリストコンテンツの更新",
    "label36": "タスクリストの作成完了",
    "exportmessage1": "ガイドメタデータを使用したファイルのエクスポート - 名前、アプリケーション、公開ステータスなど",
    "exportmessage2": "次のようなユーザー詳細でファイルをエクスポートします: 姓、名、メール、役割など",
    "previewMessage": "動画プレビューが存在しません",
    "preview": "プレビュー",
    "detailButtonText": "詳細",
    "taskListNote1": "タスク リストを公開する手順:",
    "taskListNote2": "1. タスクリストの作成",
    "taskListNote3": "2. コンテンツの追加",
    "taskListNote4": "3. 表示設定の追加",
    "taskListNote5": "4. 公開ステータスの変更",
    "serverMessage1": "無効/空白ヘッダー[アクセストークン]",
    "serverMessage2": "[アクセストークン]が失効しました",
    "serverMessage3": "申し訳ありません。リクエストに問題があるか、サーバに問題があるようです。エラーを検知しました。できるだけ早く修正します。",
    "serverMessage4": "ユーザーメールが検証されていません",
    "serverMessage5": "メンテナンス処理中。support@csod.com経由でお問い合わせください",
    "serverMessage6": "無効な/空のパラメータ[トークン]",
    "serverMessage7": "ステップ作成の上限に達しました",
    "serverMessage8": "無効/空パラメータ[アカウントロック解除トークン]",
    "serverMessage9": "この管理者APIサポートは利用できません。このサポートを廃止しました。今後のリリースで削除されます。",
    "serverMessage10": "[承認]ヘッダーの有効期限が切れています",
    "serverMessage11": "必須パラメータがありません。",
    "serverMessage12": "ログイン資格情報が無効です。複数回失敗すると、アカウントがロックされます。",
    "serverMessage13": "無効または失効済みのワンタイムパスワード",
    "serverMessage14": "アップロードしたファイルは有効な画像ではありません。（許可されているのはJPGおよびPNGファイルのみです）",
    "serverMessage15": "セッションが期限切れです",
    "serverMessage16": "ユーザーIDが無効です。再試行してください",
    "serverMessage17": "メールアドレスが正しくありません。再試行してください",
    "serverMessage18": "ユーザーの役割が無効です。再試行してください",
    "serverMessage19": "パラメータ[task_type]が無効です",
    "serverMessage20": "無効な/空のパラメータ[application_id]",
    "serverMessage21": "無効なJSON",
    "serverMessage22": "無効な/空のパラメータ[organization_id]",
    "serverMessage23": "無効な/空のパラメータ[parent_id]",
    "serverMessage24": "無効な/空のパラメータ[category_id]",
    "serverMessage25": "無効な/空のパラメータ[from_category_id/to_category_id]",
    "serverMessage26": "スーパー Admin [organization_id] に必要なパラメータ",
    "serverMessage27": "画像データコンテンツはbase64でエンコードする必要があります",
    "serverMessage28": "無効なドメインID",
    "serverMessage29": "ファイルタイプがサポートされていません",
    "serverMessage30": "無効なパラメータ[file_id]",
    "serverMessage31": "無効な/空のパラメータ[tour_id]",
    "serverMessage32": "無効な/空のパラメータ[step_id]",
    "serverMessage33": "無効な/空のヘッダー[Googleトークン]",
    "serverMessage34": "無効なチームURL",
    "serverMessage35": "無効な/空のヘッダー[Edcastトークン]",
    "serverMessage36": "ファイルサイズは5MB未満である必要があります",
    "serverMessage37": "無効なURLです",
    "serverMessage38": "パスワードには、少なくとも1つの大文字、1つの小文字、1つの数字、および1つの特殊文字を含む8~15文字を指定する必要があります。",
    "serverMessage39": "無効なパスワード",
    "serverMessage40": "無効な/空のパラメータ[country_id]",
    "serverMessage41": "無効な/空のパラメータ[city_id]",
    "serverMessage42": "無効なパラメータ[言語]",
    "serverMessage43": "認証IDが無効です",
    "serverMessage44": "無効なパラメータ[file_id]",
    "serverMessage45": "無効なURL ID",
    "serverMessage46": "無効/空白/期限切れヘッダー[承認]",
    "serverMessage47": "無効な/空のヘッダー[アプリキー]",
    "serverMessage48": "無効な/空のヘッダー[更新トークン]",
    "serverMessage49": "無効な役割ID",
    "serverMessage50": "無効なセグメントID",
    "serverMessage51": "無効なドメイン名",
    "serverMessage52": "セグメントデータの複製",
    "serverMessage53": "無効なサブスクリプションID",
    "serverMessage54": "無効な組織役割ID",
    "serverMessage55": "無効なタスクリストID",
    "serverMessage56": "タスクリストが公開されていません",
    "serverMessage57": "タスクリストが公開されています",
    "serverMessage58": "2044",
    "serverMessage59": "無効なフォルダID",
    "serverMessage60": "2045",
    "serverMessage61": "無効なコンテンツID",
    "serverMessage62": "無効なパラメータ[external_user_id]",
    "serverMessage63": "ステップ言語データが無効です。無効またはジャンクな文字については、ステップタイトルと説明を確認してください",
    "serverMessage64": "無効な職務ID",
    "serverMessage65": "ロックされているユーザーアカウントに対するアクションは許可されていません",
    "serverMessage66": "無効な/空のパラメータ[segments]",
    "serverMessage67": "無効な/空のパラメータ[group_id]",
    "serverMessage68": "無効な/空のパラメータ[sso_id]",
    "serverMessage69": "指定されたURLは無効です",
    "serverMessage70": "無効な/空のパラメータ[group_id]",
    "serverMessage71": "step_audioまたはstep_audio_textフィールドが必要です",
    "serverMessage72": "データ整合性に失敗しました",
    "serverMessage73": "新旧のパスワードを同じにすることはできません。",
    "serverMessage74": "[アクション]フィールドが必要です",
    "serverMessage75": "無効な/空のパラメータ[id]",
    "serverMessage76": "ガイドが既に作成されている場合、サブカテゴリをフォルダ内に作成することはできません。",
    "serverMessage77": "ユーザーが既にスーパー Admin になっている場合、ユーザーの役割を変更することはできません。",
    "serverMessage78": "このアクションは公開済みガイドでは許可されていません。",
    "serverMessage79": "ロック済みガイドは公開できません。",
    "serverMessage80": "このアクションは公開済みカテゴリでは許可されていません。公開を取り消してください。",
    "serverMessage81": "アプリケーションは指定された組織に属する必要があります",
    "serverMessage82": "このアクションを実行する権限がありません。",
    "serverMessage83": "このサブカテゴリにアクションは許可されていません。",
    "serverMessage84": "サブカテゴリが既に作成されている場合、ガイドをカテゴリ内に作成することはできません",
    "serverMessage85": "ステップを作成するには、このガイドのロックを解除する必要があります",
    "serverMessage86": "ステップを編集するには、このガイドのロックを解除する必要があります",
    "serverMessage87": "ステップを削除するには、このガイドのロックを解除する必要があります",
    "serverMessage88": "ステップをコピーして貼り付けるには、このガイドのロックを解除する必要があります",
    "serverMessage89": "このタスクリストにアクションは許可されていません。",
    "serverMessage90": "アクションは公開済みタスクリストには許可されていません。",
    "serverMessage91": "このフォルダにはアクションは許可されていません。",
    "serverMessage92": "これは既にドラフトガイドです。新規作成することはできません",
    "serverMessage93": "ガイドが最高の環境で公開されていない場合、ドラフトガイドを作成できません。",
    "serverMessage94": "このガイドには既にドラフトがあるため、新規ドラフトを作成することはできません。",
    "serverMessage95": "パラメータ[envs]の値が無効です",
    "serverMessage96": "ガイドIDの許可された上限に達しました",
    "serverMessage97": "パラメータ[Keywords]の値が無効です",
    "serverMessage98": "タグステップにはアクションは許可されていません。",
    "serverMessage99": "ガイドを同じカテゴリに貼り付けることはできません",
    "serverMessage100": "同じツアーにステップを貼り付けることはできません",
    "serverMessage101": "ガイドはすぐにエクスポートできるようになります。ステータスをチェックするには、[アカウント]メニューの[タスクステータス]に移動します。",
    "serverMessage102": "アップロードが開始されました。アップロードが完了するまで、ブラウザを更新または閉じないでください。アップロードが完了後にインポートステータスを確認できます",
    "serverMessage103": "ユーザーは既に存在します",
    "serverMessage104": "メールアドレスが既に検証されています",
    "serverMessage105": "このURLは他のアプリケーション設定と重複しています。それ以外のURLを追加してください。ヘルプについては、support@csod.comにお問い合わせください",
    "serverMessage106": "カテゴリは同じアプリケーションに属する必要があります",
    "serverMessage107": "ドメインは既に存在します",
    "serverMessage108": "ガイド作成の上限に達しました",
    "serverMessage109": "ステップIDは同じガイドに属する必要があります",
    "serverMessage110": "この組織はサスペンド済みです。管理者に問い合わせてください。",
    "serverMessage111": "ガイドIDは同じカテゴリに属する必要があります",
    "serverMessage112": "このガイドは、指定されたオブジェクトのペアとフィールド名で既に存在しています",
    "serverMessage113": "ユーザーサスペンド済み",
    "serverMessage114": "ユーザーは既に検証済み",
    "serverMessage115": "この機能はHPEでは無効になっています。",
    "serverMessage116": "ワンタイムパスワードがメールアドレスに正常に送信されました",
    "serverMessage117": "役割が既に存在します",
    "serverMessage118": "国が既に存在します",
    "serverMessage119": "市町村が既に存在します",
    "serverMessage120": "指定されたガイドは既にエクスポート用にキューに追加しました",
    "serverMessage121": "カテゴリの公開が取り消されました",
    "serverMessage122": "カテゴリが公開されました",
    "serverMessage123": "組織が検証されていません。ヘルプについては、support@csod.comにお問い合わせください",
    "serverMessage124": "この組織にはJSONファイルを使用できません。",
    "serverMessage125": "API資格情報が正しくないか、存在しません。",
    "serverMessage126": "このガイドにはJSONファイルを使用できません。",
    "serverMessage127": "使用可能なステップがありません。ガイドを公開するには、少なくとも1つのステップを作成してください。",
    "serverMessage128": "有効な仕事用メールアドレスを入力してください。",
    "serverMessage129": "デフォルトアプリケーション。",
    "serverMessage130": "デフォルトの役割。",
    "serverMessage131": "APIキーはSSO設定に使用されています。",
    "serverMessage132": "このリクエストは処理できません。このリクエストを有効にするには、support@csod.comに連絡してください。",
    "serverMessage133": "アプリケーション作成の上限に達しました",
    "serverMessage134": "この機能は無効になっています",
    "serverMessage135": "指定されたタイムスタンプが無効です。有効なタイムスタンプを入力してください。",
    "serverMessage136": "ユーザー作成の上限に達しました。",
    "serverMessage137": "無効/失効したサブスクリプションです。",
    "serverMessage138": "指定されたSCIM IDが無効です。",
    "serverMessage139": "指定されたフィルターはサポートされていません。",
    "serverMessage140": "指定されたリソースは使用できません。",
    "serverMessage141": "無効なSCIMスキーマです。",
    "serverMessage142": "無効なパラメータ。",
    "serverMessage143": "必要なセグメントがありません。",
    "serverMessage144": "このタスクは既に完了しています。",
    "serverMessage145": "このユーザーアカウントは既にロック解除されています。",
    "serverMessage146": "ログインが複数回失敗したため、アカウントが一時的にロックされています。メールを送信して、ロックを解除する手順を実行しました。さらにサポートが必要な場合は、support@csod.comにお問い合わせください。",
    "serverMessage147": "JSONに指定されたデータが無効です",
    "serverMessage148": "この組織ではSSOが有効ではありません。詳細については、contactsupport@csod.comにお問い合わせください。",
    "serverMessage149": "音声が作成されていません",
    "serverMessage150": "ガイドリストのExcelファイルがすぐに使用できるようになります。",
    "serverMessage151": "指定された組織にツアーが存在しません",
    "serverMessage152": "前のアクションが処理中です。完了すると、次のアクションを実行できます。",
    "serverMessage153": "ツアーが指定されたカテゴリに既に存在します",
    "serverMessage154": "指定されたアプリケーション/カテゴリにファイルをインポートできませんでした。",
    "serverMessage155": "この組織にはメディアファイルテーマが有効になっていません。詳細については、support@csod.comにお問い合わせください。",
    "serverMessage156": "ガイドインポートの送信先が無効です",
    "serverMessage158": "ユーザーリストのExcelファイルがすぐに使用できるようになります。",
    "serverMessage159": "Redisキャッシュが無効です",
    "serverMessage160": "最大5個のガイドをピン留めできます",
    "serverMessage161": "ガイドは既にピン留めされています",
    "serverMessage162": "無効な/空のパラメータ[mobile_app_id]",
    "serverMessage163": "このアプリケーションにはJSONファイルを使用できません。",
    "serverMessage164": "バンドル/パッケージIDが既に存在しています。",
    "serverMessage165": "この名前のセグメントが既に存在します。",
    "serverMessageDefault": "不便で申し訳ありません。詳細については、contactsupport@csod.comにお問い合わせください。",
    "errorMessage1": "セッションが期限切れです",
    "errorMessage2": "サービスがありません。",
    "usersVariable32": "新規社内ユーザの追加",
    "usersVariable33": "組織の割当",
    "customizationCentre": "カスタマイズセンター",
    "customizationCentreBreadcrumb": "カスタマイズセンター",
    "customizationCentreTitle": "カスタマイズセンター |",
    "settings": "設定",
    "brandLogo": "ブランドロゴ",
    "brandingWatermark": "ブランディング透かし",
    "widgetIcon": "ウィジェットアイコン",
    "widgetIconPosition": "ウィジェットアイコンポジション",
    "widgetIconBadge": "ウィジェットアイコンバッジ",
    "widgetIconZIndex": "ウィジェット z-インデックス",
    "hideWidgetIconOnGuidesPage": "ページにガイドが掲載されていない場合はウィジェットを非表示にする",
    "widgetIconVisibility": "ウィジェットアイコンの表示設定",
    "false": "誤",
    "firstSlideImage": "最初のスライドの画像",
    "lastSlideImage": "最後のスライドの画像",
    "stepBalloonTheme": "ステップバルーンのテーマ",
    "stepTitleText": "ステップタイトルのテキスト",
    "dummyText": "Loremはダミーテキストです",
    "prev": "前へ",
    "tooltipBallonInfoTip": "ツールチップテキストの最初の行は「ステップタイトル」、それに続く部分は「ステップの説明」として扱われます",
    "applyStepBallonTheme": "ステップバルーンのテーマの適用",
    "stepSelectorBorderWidth": "ステップセレクタ枠線幅",
    "stepSelectorBorderColor": "ステップセレクタ枠線色",
    "enableStepAudio": "ステップ音声を有効にする",
    "preferredColorsForTitleDescription": "ステップタイトルと説明に優先的に使用する色",
    "reset": "リセット",
    "tooltipSelectorBGColor": "Tooltip セレクタの背景色",
    "notificationHeaderColor": "通知ヘッダー色",
    "pushNotificationDelay": "プッシュ通知の遅延",
    "pushNotificationSnoozeTime": "プッシュ通知スヌーズ時間",
    "chatbotIcon": "チャットボットアイコン",
    "chatbotPosition": "チャットボットポジション",
    "branding": "ブランディング",
    "widget": "ウィジェット",
    "videogif": "動画 & GIF",
    "step": "ステップ",
    "notification": "通知",
    "chatbot": "チャットボット",
    "features": "機能",
    "labels": "ラベル",
    "top": "上",
    "bottom": "下",
    "left": "左",
    "right": "右",
    "chatbotGreetingMsg": "チャットボットの「ようこそ」のメッセージ",
    "defaultChatbotGreeting": "[MyGuide]へようこそ。ご用件は何ですか?（私はボットです）",
    "chatbotGreetingEndMsg": "チャットボットのディスカッション終了メッセージ",
    "defaultChatbotEnd": "完了しました。ありがとうございました",
    "chatbotErrorMsg": "チャットボッのエラーメッセージ",
    "defaultChatbotError": "'申し訳ありません、私にはわかりません。具体的に説明してください",
    "miniPlayerHeader": "ミニプレイヤーヘッダー",
    "showAllGuidesTab": "“すべてのガイド”タブを表示する",
    "defaultGuideActionInPlayer": "プレイヤーのデフォルトガイドアクション",
    "defaultWebPlayerMode": "デフォルトのWeb Playerモード",
    "default": "デフォルト",
    "miniPlayer": "Mini Player",
    "playerPanelDefaultMode": "プレイヤーパネルのデフォルトモード",
    "open": "開く",
    "closed": "終了済み",
    "configureFeedbackOptn": "フィードバックオプションの設定",
    "email": "メール",
    "imageLibrary": "画像ライブラリ",
    "submit": "送信",
    "classic": "クラシック",
    "defaultPagePlayer": "プレイヤーのデフォルトのページ",
    "allGuides": "すべてのガイド",
    "bgColor": "背景色",
    "borderColor": "枠線の色",
    "stepTitleColor": "ステップタイトルの色",
    "stepDescColor": "ステップの説明の色",
    "borderRadius": "枠線の角丸",
    "prevBTNBG": "[前へ]ボタンの背景",
    "prevBTNText": "[前へ]ボタンのテキスト",
    "borderWidth": "枠線の幅",
    "nextBTNBG": "[次へ]ボタンの背景",
    "nextBTNText": "[次へ]ボタンのテキスト",
    "popupWidth": "ポップアップの幅",
    "colorPicker": "色ピッカー",
    "brandLogoTooltip": "画像のサイズ: 100x40px",
    "brandingWatermarkTooltip": "\"下にある動画とGIFの上に重ねるためのブランディング要素。最適な結果を得るには、透過性と推奨サイズを使用してください\n画像のサイズ: 150x150 px\"",
    "widgetIconBadgeTooltip": "現在のページにガイドが掲載されている場合はこのバッジが表示されます",
    "firstSlideText": "最初のスライドのテキスト",
    "slideTextTooltip": "スライドのテキストは動画のみに適用され、GIFには適用されません",
    "lastSlideText": "最後のスライドのテキスト",
    "pushNotifDelayTooltip": "次の時間が経過した後にプッシュ通知ポップアップを表示（許容範囲0~5秒）",
    "pushNotifSnoozeTime": "[選択してください]時間は終了済み通知を表示しない",
    "playerPanelOpenLabelTooltip": "ページをロードするたびにプレイヤーパネルを開く",
    "widgetPosi1": "右上",
    "widgetPosi2": "左上",
    "widgetPosi3": "右下",
    "widgetPosi4": "左下",
    "URL": "URL",
    "urlHostname": "URLホスト名",
    "urlPath": "URLパス",
    "urlParams": "URLパラメータ",
    "urlHash": "URLハッシュ",
    "pageTitle": "Page タイトル",
    "equals": "完全一致",
    "notEquals": "次と等しくない",
    "contains": "次を含む",
    "doesNotContain": "を含まない",
    "startsWith": "開始文字",
    "endsWith": "末尾一致",
    "discardBtnTxt": "変更を破棄",
    "addRule": "ルールの追加",
    "createRules": "ルールの作成",
    "stepRules": "ステップルール",
    "building": "建設",
    "business": "ビジネス",
    "education": "教育",
    "fashion": "ファッション",
    "finance": "財務",
    "food": "食品",
    "healthcare": "医療",
    "miscellenous": "その他",
    "nature": "自然",
    "people": "人",
    "science": "科学",
    "technology": "技術",
    "transport": "輸送",
    "work": "仕事",
    "images": "画像",
    "icons": "アイコン",
    "colors": "色",
    "accessibility": "アクセシビリティ",
    "animal": "動物",
    "audioAndVideo": "音声 & 動画",
    "automotive": "自動車",
    "charity": "チャリティー",
    "chat": "チャット",
    "communication": "コミュニケーション",
    "solid": "実線",
    "trendy": "トレンディ",
    "popular": "人気",
    "random": "ランダム",
    "resumeGuide": "履歴書ガイド:",
    "oopsMessage": "問題メッセージ:",
    "maintaninence": "保守管理:",
    "serviceError": "サーバエラー:",
    "internalUsers": "社内ユーザ",
    "externalUsers": "社外ユーザ",
    "partnerId": "パートナーID",
    "addPartner": "パートナーの追加",
    "partner": "パートナー",
    "partnerAdmin": "パートナー Admin",
    "partners": "パートナー",
    "editPartner": "パートナーの編集",
    "addInternalUser": "新規社内ユーザの追加",
    "editInternalUser": "新規社内ユーザの編集",
    "unassign": "割当の解除",
    "orgAssign": "組織の割当",
    "tableHeaderFolder": "フォルダ",
    "labelAppInsights": "アプリ Insights:",
    "version": "バージョン",
    "decommissionBtn": "使用停止",
    "deleteBackupBtn": "バックアップの削除",
    "reactivateBtn": "再アクティブ化",
    "activeOrgs": "アクティブな組織",
    "suspendedDeletedOrgs": "サスペンド/使用停止された組織",
    "suspendedByUser": "ユーザーによりサスペンド済み:",
    "suspendedByEmail": "メールによりサスペンド済み:",
    "suspendedOn": "サスペンドされた日:",
    "deletedByUser": "ユーザーにより削除:",
    "deletedByEmail": "メールにより削除:",
    "deletedOn": "削除日:",
    "decommissionContent": "この組織を使用停止にしますか?このアクションは元に戻せません。",
    "deleteBackupOf": "バックアップの削除",
    "deleteBackupContent": "この組織のバックアップを削除しますか?このアクションは元に戻せません。",
    "enableMediaNotification": "メディアファイル作成通知を有効にする",
    "guideName": "ガイドの名前",
    "keywords": "キーワード",
    "addKeywords": "キーワードの追加",
    "manageKeywords": "キーワードの管理",
    "published": "公開済み",
    "keywordLoading": "キーワードを更新しています。この処理には数分かかります。移動先は安全です",
    "assignKeywords": "キーワードの割当",
    "keywordTooltip": "キーワードを追加するには、enterキーまたはカンマキーを押します",
    "manageSegments": "セグメントの管理",
    "guides": "ガイド",
    "segmentTitle": "セグメントタイトル",
    "addSegmentTitle": "セグメントタイトルの追加",
    "assignSegments": "セグメントの割当",
    "segmentLoading": "セグメントを更新しています。この処理には数分かかります。移動先は安全です",
    "addSegment": "セグメントの追加",
    "segmentTooltip": "リストから1つまたは複数のセグメントを選択",
    "addASegment": "セグメントの追加",
    "deleteSegment": "セグメントの削除",
    "findReplace": "検索および置換",
    "apps": "アプリ",
    "content": "コンテンツ",
    "text": "テキスト",
    "element": "要素",
    "find": "検索",
    "replace": "置換",
    "selectElement": "要素の選択",
    "elementPreview": "要素プレビュー",
    "action": "アクション",
    "findReplaceSearchPlaceholder": "何をお探しですか?",
    "noGuideFound": "検索に一致するガイドが見つかりません",
    "modifySearchCriteria": "検索条件を変更してください",
    "maxGuideSelected": "一度に最大50のガイドを選択できます",
    "import": "インポート",
    "paste": "貼り付け",
    "importedGuides": "インポート済みガイド",
    "exportedGuides": "エクスポート済みガイド",
    "transferGuides": "ガイドの移動",
    "export": "エクスポート",
    "pageTracking": "Page追跡",
    "featureTracking": "機能追跡",
    "enableMirrorApplications": "ミラーアプリケーションの有効化",
    "selfHostedPlayer": "セルフホストプレイヤー",
    "playerPackages": "プレイヤーパッケージ",
    "createPlayerPackage": "プレイヤーパッケージの作成",
    "playerPackageError": "職務は既に進行中であるため、このアクションは実行できません",
    "selfHostedPlayerURL": "セルフホストプレイヤーURL",
    "selectApps": "アプリを選択",
    "tooltipPageTracking": "これにより、アプリケーションに追加されたドメインのさまざまなURLを追跡可能になります。ページの読み込み時間、ページでの滞在時間などのさまざまな指標とともに。",
    "tooltipFeatureTracking": "これにより、アプリケーションで作成された機能ガイドに基づくクリックトラッキングが可能になります。このトラッキングはユーザーには気づかれません。",
    "commonLoginMsg": "これは、管理者ポータルとMyGuideインサイトの共通ログインです。",
    "serverMessage166": "新しいパスワードは、古い5つのパスワードと同じにすることはできません。",
    "serverMessage167": "[タイトル] フィールドには、英字とスペースのみを使用できます",
    "tutorialSelectorBGColor": "チュートリアルセレクタ枠線色",
    "circular": "円形",
    "rectangular": "長方形",
    "width": "幅",
    "height": "高さ",
    "stepPopupBtnNext": "次へボタン",
    "stepPopupBtnPrev": "前へボタン",
    "enableChat": "チャットボットを有効化",
    "resumeGuideDefaulText": "ガイドを再開",
    "oopsMessageDefaulText": "このガイドにはない手順を実行しているようです。ガイドが不要な場合は、ガイドを終了してください。",
    "maintenanceDefaulText": "メンテナンス処理中。support@csod.com 経由でお問い合わせください",
    "serviceErrorDefaulText": "残念。エラーが発生しました。しばらくしてから再度試してください。また、support@csod.com にメールでお問い合わせください",
    "invalidImageText": "選択したファイルは png または jpeg 形式でなければなりません",
    "tutorialSteps": "チュートリアルステップ",
    "tutorialModal": "チュートリアルバルーンのテーマ",
    "tutorial": "チュートリアル",
    "titleBgColor": "タイトルの背景色",
    "prevBtnLabel": "[前へ] ボタン",
    "nextBtnLabel": "[次へ] ボタン",
    "color": "色",
    "background": "背景",
    "fontSize": "フォント サイズ",
    "outerBox": "外箱",
    "outerBoxShadow": "外部ボックスシャドウ",
    "horizontal": "水平",
    "vertical": "垂直",
    "blur": "ぼかし",
    "spreadRadius": "スプレッド半径",
    "inset": "インセット",
    "noApplicationAssigned": "アプリケーションが割り当てられていません",
    "pasteErrorMessage": "ガイドは同じカテゴリーにペーストすることができません。",
    "pasted": "ペーストしました",
    "exportMessage": "ガイドはまもなくエクスポート可能になります。ステータスを確認するには、ガイドセクション内の「エクスポートされたガイド」に移動してください。",
    "importMessage": "ガイドのインポートを開始しました。ステータスを確認するには、ガイドセクションの「インポート済みのガイド」タブに移動してください。",
    "srNo": "Sr No.",
    "jsonProcessingIncomplete": "This action is not allowed because json processing is not completed",
    "contentCreationNotAllowed": "ミラーアプリケーションに対するコンテンツの作成は許可されていません。",
    "tooltipGuideAutomation": "ガイド自動化機能を有効にする",
    "ssoUrl": "SSO URL",
    "sendFeedbackOptn": "[フィードバックを送る]を有効にする",
    "selectSegment": "セグメントを選択",
    "chatbotVisibilitySettings": "チャットボットの表示設定",
    "padding": "余白:",
    "excludeDomains": "ドメインを除外する",
    "excludeDomainsTooltip": "MyGuideが使用不可のドメインのリストを定義する",
    "firstNameError": "名に特殊文字を含めることはできません",
    "lastNameError": "姓に特殊文字を含めることはできません",
    "enterNewTag": "新しいタグを入力します",
    "noteForCustomizationCentre": "以下の機能が利用可能かどうかは、ターゲット製品カテゴリで利用可能であるかどうかに依存します（例:モバイルアプリ、デスクトップアプリなど）時間の経過とともに変化する可能性があります。",
    "enforceSequence": "シーケンスを適用する",
    "guideSequenceUpdated": "ガイドシーケンスが更新されました",
    "updateSequence": "シーケンスを更新する",
    "shiftUp": "シフトアップ",
    "shiftDown": "シフトダウン",
    "mediaFileCreationForTooltipGuides": "ツールチップガイドのメディアファイルの作成",
    "tooltipMediaFileCreationTooltipGuides": "ツールチップガイドのメディアファイル作成のエクスポートを有効にします。",
    "mediaFileCreationForTooltipGuidesToastInfo": "メディアファイル作成のツールチップガイドを有効にできるのは、[メディアファイル作成]設定が有効になっている場合のみです。",
    "importSegmentValues": "セグメント値のインポート",
    "importSegmentValuesHistory": "Segment値履歴のインポート",
    "configureHelpLocation": "ヘルプロケーションの設定",
    "creataOrganization": "組織の作成",
    "createPartner": "パートナーの作成",
    "createSegment": "Segmentの作成",
    "oneOrMoreGuidesPresent": "選択した1つ以上のガイドがタスクリストに既にある",
    "betaFeatures": "ベータ版の機能",
    "keyboardShortcuts": "キーボードショートカット",
    "keyboardShortcutsTooltip": "キーボードショートカットでは、ワークフローガイドを再生するショートカットを作成できます。作成されたキーボードショートカットの完全なリストを表示するには、ブラウザでカスタマーウェブアプリケーションを表示するときに「?」と入力します。",
    "tableHeaderModificationDate": "変更日",
    "headerVariable6": "ヘルプ",
    "productType": "製品タイプ",
    "guideInventory": "ガイドインベントリ",
    "createdBy": "作成者",
    "modifiedBy": "変更者",
    "guideId": "ガイドID",
    "botGuide": "ボットガイド",
    "tooltipGuide": "ツールチップガイド",
    "workflowGuide": "ワークフローガイド",
    "featureTags": "フィーチャータグ",
    "tutorialGuide": "チュートリアルガイド",
    "guideType": "ガイドタイプ",
    "unpublish": "公開の取り消し",
    "environments": "環境",
    "publishGuide": "ガイドの公開",
    "importSegment": "Segmentsをインポート",
    "importSegmentHistory": "セグメント履歴のインポート",
    "guidePublishInfoMessage": "ガイドが公開されるドメイン:",
    "guidePublishedSuccessfully": "ガイドは正常に公開されました",
    "guideUnpublishedSuccessfully": "ガイドは正常に公開が取り消されました",
    "enableDAP": "インサイトからのDAP通知を有効にする",
    "enableDAPTooltip": "有効の場合、メール通知機能; 通知設定、週単位の使用状況の統計と日次ステップ失敗レポートには、コントロールセンターの Insights ポータルで設定されたデフォルト値が使用されます。この設定が無効の場合、デフォルト設定を上書きし、週単位の使用状況の統計と日次ステップ失敗レポートについて Insights コントロールセンターに対して送信される通知機能; 通知を無効にします",
    "showtooltipsWithWorkflowTutorial": "ワークフローおよびチュートリアルガイドとともにツールチップを表示",
    "showtooltipsWithWorkflowTutorialTooltip": "ツールチップガイドを有効にして、ワークフローまたはチュートリアルガイドのどちらかが表示されているページに表示します。無効の場合、ツールチップガイドはワークフローまたはチュートリアルガイドと同時に表示されません。",
    "exportInventoryToastMessage": "ガイドデータはエクスポートのキューに入れられました。ステータスを確認するには、[ガイドの管理]セクションの[データのエクスポート]に移動します",
    "autobot": "オートボット",
    "globalFeatureFlags": "グローバル機能フラグ",
    "beta": "ベータ版",
    "legacy": "レガシー",
    "enterprise": "エンタープライズ",
    "featureStatusChanged": "グローバル機能フラグが正常に更新されました",
    "confirmChanges": "変更の確認",
    "featureFlagConfirmationMessage": "これはすべての組織に反映されます。続行しますか?",
    "generatePasswordOneLowerCase": "少なくとも1つの小文字",
    "pageEnteredOutsideRange": "入力したページ番号は範囲外です",
    "showing": "表示中",
    "of": ":",
    "segmentation": "セグメント化",
    "customization": "カスタマイズ",
    "administration": "管理",
    "userAttributes": "ユーザー属性",
    "addUserAttributesButtonText": "ユーザー属性の追加",
    "updateUserAttributesButtonText": "ユーザー属性の更新",
    "assignUserAttributes": "ユーザー属性値の割当",
    "userAttributeValues": "ユーザー属性値",
    "addUserAttributeValues": "ユーザー属性値の追加",
    "importUserAttributeValues": "ユーザー属性値のインポート",
    "importUserAttributeValuesHistory": "ユーザー属性値履歴のインポート",
    "importUserAttributes": "ユーザー属性のインポート",
    "importUserAttributesHistory": "ユーザー属性履歴のインポート",
    "addingUserAttributeButtonText": "ユーザー属性を追加しています...",
    "updatingUserAttributeButtonText": "ユーザー属性を更新しています。",
    "userAttributeCreatedText": "ユーザー属性が作成されました",
    "userAttributeUpdatedText": "ユーザー属性が更新されました",
    "userAttributeDeletedText": "ユーザー属性が削除されました",
    "userAttributeValueCreatedText": "ユーザー属性値が作成されました",
    "userAttributeValueDeletedText": "ユーザー属性値が削除されました",
    "widgetIconZIndextooltip": "Z-インデックスはMyGuideが画面上の要素の上にどのように表示されるかを制御するために使用されます。場合によっては、MyGuideコンポーネントが画面上の要素の背後に隠れることがあります。Zインデックスの値を高くしたり低くしたりすると、画面上の要素（-2147483646から2147483646の間の値）の上にMyGuideコンポーネントを表示する可能性が高くなります。",
    "mirrorApplications": "ミラーアプリケーション",
    "enabled": "有効",
    "disabled": "無効",
    "appIntelligence": "アプリインテリジェンス",
    "greaterThan": "より大きい",
    "lessThan": "未満",
    "userCreationDate": "ユーザー作成日",
    "rule_dayOfWeek": "<ul><li>英単語の最初の3文字を使用して、日を参照してください</li><br><li>日曜日を週の開始として検討する</li><br><li></i>ハイフン<i> '-' を使用して範囲を指定してください</li><br><li>または特定の日のみ記載</li><br><li>複数のオプションの場合、<i>パイプ区切り </i> ' | ' を使用できます</li><br><li>承諾された値の例: <br> '月-金'、<br> '火'、<br> '月-水 | 金'</li></ul>",
    "rule_fixTime": "<ul><li>24時間形式で時間を入力してください</li><br><li></i>ハイフン<i> '-' を使用して範囲を指定してください</li><br><li>または開始時刻のみを記載するか、</li><br><br><li>有効な値が指定されていない場合、終了時間は一日の終わり（23:59）と見なされます</li><br><li>複数のオプションの場合、<i>パイプ区切り </i> ' | ' を使用できます</li><br><li>承諾された値の例: <br> '09:00 - 18:00 '、<br> ' 11:00 '、<br> ' 09:00-12:00 | 16:00 '</li></ul>",
    "rule_dateRange": "<span><b>開始日</b>および<b>終了日</b>フィールドは必須です</span>",
    "userProvisioning": "ユーザープロビジョニング",
    "insightsCustomerType": "インサイト顧客タイプ",
    "myguideCustomers": "Cornerstone Guideの顧客",
    "csodInternal": "CSOD内部",
    "insightsCustomerTypeMessage": "この設定を切り替えると、Cornerstone Guide Insightsの古い記録データが消去される可能性があります",
    "insightsCustomerTypeTooltip": "この設定により、有料のCornerstoneGuideとCSODの顧客の間で負荷がシフトするようにデータベースが変更されます",
    "date": "日付",
    "time": "時間",
    "dayOfWeek": "曜日",
    "fixedTime": "一日の決まった時間",
    "dateRange": "日付範囲",
    "displayFrequency": "表示頻度",
    "platFormUsageReport": "プラットフォーム使用状況レポート",
    "platformUsageReportInfo": "全体的なCornerstoneGuideデータをプラットフォームとして示し、すべての組織にわたるユーザーの増加とその消費に関する洞察を提供するレポート",
    "totalUsers": "合計ユーザー数",
    "newUsers": "新規ユーザー",
    "activeUserSessions": "アクティブなユーザーセッション",
    "guidesCreated": "ガイド作成日",
    "guidesPublished": "ガイド公開済み",
    "guidesPlays": "ガイドプレイ",
    "tooltipsCreated": "Tooltips 作成日",
    "tooltipsPublished": "Tooltips 公開済み",
    "tooltipsPlays": "Tooltips プレイ",
    "autoProceedStep": "次のステップに自動で進む",
    "automationStepDelay": "次のステップへの自動続行遅延時間",
    "hideBrandLogo": "ブランドロゴの表示設定",
    "enableUserSessionTracking": "ユーザーセッショントラッキングを有効にする",
    "viewActivityDetails": "アクティビティ詳細の表示",
    "editingSegment": "segment の編集",
    "selectSegmentUserAttribute": "ユーザー属性の選択",
    "selectUserAttributeAndValues": "ユーザー属性と値の選択",
    "editingSegmentInfoMessage": "Segments を使用すると、ターゲットユーザーのグループによるGuidesへのアクセスを制御できます。ユーザー属性とその値は、シングルサインオンなどのユーザー統合を通じて取り込まれるか、セグメンテーションのユーザー属性から手動で管理されるユーザープロファイルに基づいています。さらに、ルールを使用して、特定のPagesや時間フレームなど、セグメント化されたユーザーに対してGuidesを表示する場所を制御します。ユーザー属性とルールを一緒に使用して、 segment を作成します。",
    "numOfPartnerAdminsAllowed": "許可されるパートナーAdminsの数",
    "numOfPartnerAdminExceeded": "現在のパートナーのパートナーAdminsの数を超えています。support@csod.com 経由でお問い合わせください",
    "valueShouldBeGreaterThan": "値の下限は",
    "setVisibilitySetting": "Guide の表示設定を公開後に設定",
    "visibilitySettingsUpdated": "表示設定が更新されました",
    "auto_generate_keywords": "クリエイターに対する Guide の支援",
    "editCredentials": "資格情報の編集",
    "createSegmentOptionOne": "ユーザーが Segment の一部である場合、プレイヤーから Guides を表示します。",
    "createSegmentOptionTwo": "ユーザーが Segment の一部である場合、プレイヤーから Guides を非表示にします。",
    "createSegmentOptionThree": "ユーザーがセグメントの一部である場合、プレイヤーからガイドを表示します。",
    "select": "選択",
    "filters": "フィルタ",
    "labelShowBeaconGuidesOnCurrentPage": "現在の PageのBeaconGuidesを表示",
    "labelGuideSegmentation": "ガイドセグメント化",
    "myGuideLogin": "Cornerstone Guide Adminポータルにログイン",
    "loginRemember": "記録",
    "dashboardNoOfOrganisationsTitle": "組織",
    "dashboardNoOfApplicationsTitle": "アプリケーション",
    "dashboardNoOfUsersTitle": "ユーザー（複数可）",
    "dashboardNoOfPartnersTitle": "パートナー",
    "segmentsTableHeaderIsRequired": "必須",
    "headerVariable2": "役割:",
    "headerVariable5": "Insights",
    "firstPage": "最初のPage",
    "middlePage": "中央のPage",
    "lastPage": "最後のPage",
    "label19": "アプリケーションの作成",
    "label20": "アプリケーションの更新",
    "label21": "アプリケーションの削除",
    "tooltipBalloonTheme": "Tooltip バルーン テーマ",
    "tooltipSelectorBorderColor": "Tooltipセレクタの枠線色",
    "currentPage": "現在 Page",
    "iconColor": "アイコンの色",
    "confirmDeleteSegment": "segment を削除しますか?",
    "dashboardBreadCrumb": "Dashboard",
    "changeIcon": "アイコンの変更",
    "selectFromLibrary": "ライブラリから選択",
    "uploadFromComputer": "コンピューターからアップロード",
    "advanceSettings": "詳細設定",
    "assignedAdmins": "割り当てられた管理者",
    "textIs": "テキストは",
    "testIsNot": "テキストはそうではありません",
    "textContains": "テキストに含まれる内容",
    "textMatches": "テキストの一致",
    "variables": "Variables",
    "deleteApplicationContent": "本当にアプリケーションを削除しますか?",
    "deleteUser": "ユーザの削除",
    "deleteUserContent": "ユーザーを削除しますか?",
    "deleteTasklist": "タスクリストの削除",
    "deleteTasklistContent": "このタスクリストを削除しますか?",
    "deleteFolder": "フォルダの削除",
    "deleteFolderContent": "フォルダを削除しますか?",
    "deleteKey": "キーの削除",
    "deleteKeyContent": "キーを削除しますか?",
    "deleteDomain": "ドメインの削除",
    "deleteDomainContent": "ドメインを削除しますか?",
    "deleteRole": "役割の削除",
    "deleteRoleContent": "役割を削除しますか?",
    "deletePartner": "パートナーの削除",
    "deletePartnerContent": "パートナーを削除しますか?",
    "deleteUserAttribute": "ユーザ属性の削除",
    "deleteUserAttributeContent": "ユーザー属性を削除しますか?",
    "deleteUserAttributeValue": "ユーザ属性値の削除",
    "deleteUserAttributeValueContent": "ユーザー属性値を削除しますか?",
    "deleteSSO": "SSO の削除",
    "deleteSSOContent": "SSO を削除しますか?",
    "filter": "フィルタ",
    "notifThemeProperties": "テーマのプロパティ",
    "notifThemePropertiesInfo": "プッシュ通知のスタイルオプションを設定する",
    "notifHeaderPlaceholder": "あなたのアラート名はこちらです!",
    "notifTitle": "通知タイトル",
    "notifDescription": "通知に簡単な説明を付けてください。",
    "primaryAction": "プライマリ アクション",
    "secondaryAction": "セカンダリ アクション",
    "generalSettings": "一般設定",
    "buttons": "ボタン",
    "paddings": "パディング",
    "alert": "アラート",
    "alertFont": "アラートフォント",
    "fontType": "フォントの種類",
    "alertTextColor": "アラートのテキスト色",
    "alertBackground": "アラートの背景",
    "heading": "見出し",
    "headingFont": "見出しのフォント",
    "headingTextColor": "見出しのテキスト色",
    "descriptionFont": "説明フォント",
    "descriptionTextColor": "説明のテキスト色",
    "buttonFont": "ボタンフォント",
    "buttonTextColor": "ボタンテキスト色",
    "buttonBackground": "ボタンの背景",
    "buttonBorderColor": "ボタンの枠線色",
    "actionButton": "アクションボタン",
    "primaryButton": "プライマリボタン",
    "secondaryButton": "セカンダリーボタン",
    "paddingsSetings": "パディング設定",
    "notificationPreview": "これは通知ウィンドウのプレビューです",
    "notificationWidth": "通知の幅",
    "notificationHeight": "通知の高さ",
    "accessibilityWarning": "色をカスタマイズすると、アクセシビリティに影響する場合があります。デフォルトのカラーオプションから変更する場合は、アクセシビリティコンプライアンスを確認してください。",
    "readyToStartYourJourney": "私たちと一緒に旅を始める準備はできましたか?",
    "viewOrganizations": "組織の表示",
    "associatedOrganizaitons": "関連組織",
    "enterEmail": "メールを入力します",
    "enterPassword": "パスワードを入力",
    "passwordShort": "パスワードは 8 文字以上にする必要があります",
    "passwordLong": "パスワードは 20 文字以下にする必要があります",
    "generateDashboard": "Dashboard を生成",
    "assigned": "割当済み",
    "unassigned": "未割り当て",
    "biConnector": "ビジネスインテリジェンスツールの統合",
    "biConnectorTooltip": "これを有効にすると、Power BI などのさまざまなビジネス インテリジェンス ツール（現在 Cornerstone Guide でサポートされています）を介して Insights データに接続できるようになります。",
    "translation_required": "翻訳が必要ですか?",
    "defaultTooltipImage": "デフォルトTooltip画像",
    "generateVideo": "動画の生成",
    "videoGenerationInProgress": "動画生成が処理中です",
    "downloadVideos": "動画のダウンロード",
    "videoGenerationStarted": "動画生成が正常にトリガーされました",
    "rowsPerPage": "ページあたりの行数",
    "goto": "へ移動",
    "role": "役割",
    "twelveHours": "12時間",
    "twentyFourHours": "24時間",
    "oneWeek": "1週間",
    "twoWeek": "2週間",
    "fourWeeks": "4週間",
    "serverMessage157": "この組織に関連付けられているユーザーが見つかりませんでした。",
    "guidesDoNotExist": "選択したアプリケーションからデータをエクスポートするためのGuidesが見つかりませんでした。",
    "downloadReport": "レポートのダウンロード",
    "reportNotAvailable": "この組織にはレポートを使用できません",
    "customExtention": "カスタム拡張機能",
    "customExtentions": "カスタム拡張機能",
    "name": "名前",
    "description": "説明",
    "showWidget": "ウィジェットを表示",
    "matches": "プレイヤー用ホワイトリスト登録済みドメイン",
    "exclude_matches": "プレイヤー拡張機能用ドメインを除外",
    "ssoId": "SSO ID",
    "clientJsBaseUrl": "埋め込みプレイヤー基盤URL",
    "host_permissions": "ホスト権限",
    "customer": "顧客",
    "desktopCommunication": "デスクトップ通信",
    "iframeInjection": "Iframeコード挿入",
    "testme": "TestMe",
    "identifier": "識別子",
    "addExtension": "拡張機能の追加",
    "deleteExtension": "拡張機能の削除",
    "deleteExtensionWarning": "拡張機能を削除しますか?",
    "extensionAdded": "拡張機能が正常に追加されました",
    "extensionDeleted": "拡張機能が正常に削除されました",
    "extensionUpdate": "拡張機能が正常に更新されました",
    "guideTranslation": "Guide翻訳",
    "translate": "翻訳",
    "nolanguagesAdded": "言語は追加されていません",
    "translationInitiated": "言語翻訳が起動されました。",
    "editGuideLanguage": "Guide言語の編集",
    "translateStepData": "ステップデータの翻訳",
    "disableEditLanguageMsg": "翻訳する言語がありません",
    "translationUpdationSuccessful": "言語翻訳が更新されました",
    "stepNo": "ステップ番号",
    "translationInProgressInfo": "翻訳プロセスは現在、処理中です。完了したら、もう一度やり直してください。",
    "guideDescription": "Guide 説明"
};
module.exports = GmXt.playerLbls.ja_JP;