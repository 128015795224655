<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">

            <div class="sectionBody">
                <div class="d-flex mb-3">
                    <div *ngIf="!this.isEdit;else creatingSegment" class="p-0 sectionHeader">
                        {{this.admin_data.createSegment}}
                    </div>
                    <ng-template #creatingSegment>
                        <div class="p-0 sectionHeader">
                            {{this.admin_data.editingSegment}}
                        </div>
                    </ng-template>
                    <svg-icon src="../../assets/svgs/icon-info.svg" class="ml-2 infoIcon" data-toggle="tooltip"
                        data-placement="bottom" title={{admin_data.editingSegmentInfoMessage}}></svg-icon>
                </div>
                <div *ngIf="formGroup" [formGroup]="formGroup" class="container-fluid bg-white px-2 py-3">
                    <div class="font14">
                        <div>
                            <label class="font16 font-weight-bold user-segment-label"
                                for="segment_title">{{admin_data.segmentTitle}}</label>
                            <input class="form-control w-lg-50 w-50 mb-2 ml-2" name="segment_title" type="text"
                                formControlName="segment_title" required=true />
                        </div>
                        <label class="mt-2 font16 font-weight-bold user-segment-label"
                            for="segment_title">{{admin_data.selectUserAttributeAndValues}}</label>
                        <app-form *ngIf="formGroup && add_segment_formdata.length > 0" [formData]="add_segment_formdata"
                            [formGroup]="formGroup"></app-form>
                        <div class="ml-2 font16 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50 displayBlock">
                            <!-- <select class="form-control" (change)="getChangedValue($event)" style="width: 73%;">
                                <option value="" [selected]="segmentOptionSelected == -1">
                                    {{admin_data.selectSegmentUserAttribute}}
                                </option>
                                <option *ngFor="let segment of unselectedSegmentArray; index as i" value="segment['id']"
                                    [selected]="i === segmentOptionSelected">
                                    {{segment['key_name']}}
                                </option>
                            </select> -->
                            <span class="d-flex align-items-center">
                                <app-custom-select [dataList]="unselectedSegmentArray"
                                (updateDataList)="getChangedValue($event)" [selected]="segmentOptionSelected || ''"
                                componentName="userAttributeSegment" class="segment-dropdown" [ngStyle]="{ 'position': 'relative' }" dropdownPosition="inherit"></app-custom-select>

                                <span role="button" class="primaryFont-color fontWeight-700 font15 addButton ml-3" [attr.aria-disabled]="segmentOptionSelected===''"
                                    (click)="addSegmentField($event)">{{admin_data.addButtonText}}</span>
                            </span>
                        </div>
                    </div>
                    <hr>


                    <!-- <button class="btn btn-secondary minWidth-120 mx-2" type="button" data-dismiss="modal"
                                (click)="unassign()">{{this.admin_data.cancelButtonText}}</button> -->
                    <div>
                        <label class="font16 font-weight-bold user-segment-label"
                            for="segment_title">{{admin_data.createRules}}</label>
                        <div class="ml-2">
                            <div *ngFor="let section of parentRulesSections; index as i" class="group-div">
                                <div>
                                    <div *ngIf="i>0" style="margin-bottom: 10px;">Or</div>
                                    <div class="rule-div" *ngFor="let rule of section; index as j">
                                        <div *ngIf="j>0" style="margin-bottom: 10px;">And</div>
                                        <div class="form-inline">
                                            <!-- <select class="form-control" style="margin-right: 10px; width: 180px;"
                                            (change)="changeRuleType($event, j, i)">
                                            <option *ngFor="let item of rulesTypeLabel"
                                                [selected]="isSelectedRuleType(item, rule)">{{item}}</option>
                                        </select>
                                        <select *ngIf="rule.type === 'Variables'" style="margin-right: 10px; width: 180px;" class="form-control" (change)="changeRuleVariableData($event, j, i)" >
                                            <option value="">{{admin_data.select}}</option>
                                            <option *ngFor="let variable of variables"
                                            [selected]="rule.variableData && rule.variableData === variable">{{variable}}</option>
                                        </select> -->
                                            <!-- <select class=" form-control" style="margin-right: 10px; width: 180px;"
                                            (change)="changeRuleCondition($event, j, i)">
                                            <option *ngFor="let item of getRulesConditionArray(rule.type)"
                                                [selected]="isSelectedRuleCondition(item, rule)">{{item}}</option>
                                        </select> -->

                                            <app-custom-select [dataList]="rulesTypeArray"
                                                (updateDataList)="changeRuleType($event, j, i)" [selected]="rule.type"
                                                componentName="default" [ngStyle]="{ 'width': '180px', 'margin-right': '10px', 'position': 'relative' }" dropdownPosition="inherit"></app-custom-select>

                                            <app-custom-select *ngIf="rule.type === 'Variables'" [dataList]="variables || []"
                                                (updateDataList)="changeRuleVariableData($event, j, i)"
                                                [selected]="rule.variableData || ''" componentName="default" [ngStyle]="{ 'width': '180px', 'margin-right': '10px', 'position': 'relative' }" dropdownPosition="inherit"></app-custom-select>

                                            <app-custom-select [dataList]="getRulesConditionArray(rule.type)"
                                                (updateDataList)="changeRuleCondition($event, j, i)"
                                                [selected]="rule.condition || ''" componentName="default" [ngStyle]="{ 'width': '180px', 'margin-right': '10px', 'position': 'relative' }" dropdownPosition="inherit"></app-custom-select>

                                            <input *ngIf="getCategoryType(rule) === 'text'" type="text"
                                                class="form-control" style="margin-right: 10px; width: 200px;"
                                                (input)="changeRuleValue($event, j, i);validateRule(rule);"
                                                [value]="rule.value" [name]="'rule_value'+i" />
                                            <input *ngIf="getCategoryType(rule) === 'date'" class="form-control px-2"
                                                style="margin-right: 10px; width: 150px;"
                                                placeholder={{this.admin_data.selectDatesPlaceholder}} type="date"
                                                name="daterange" autocomplete="off" min="1920-01-01" max="3000-01-01"
                                                onkeydown="return false"
                                                (input)="changeRuleValue($event, j, i);validateRule(rule);"
                                                [value]="getDateValue(rule, rule.value)" />

                                            <input *ngIf="getCategoryType(rule) === 'time'" class="form-control px-2"
                                                style="margin-right: 10px; width: 150px;" name=""
                                                placeholder={{this.admin_data.selectDatesPlaceholder}} type="time"
                                                name="daterange" onkeydown="return false"
                                                (input)="changeRuleValue($event, j, i);validateRule(rule);"
                                                [value]="rule.value" />
                                            <span class="form-inline" *ngIf="getCategoryType(rule) === 'daterange'">
                                                <label for="from" class="font-weight-bold font14">From: &nbsp; </label>
                                                <input class="form-control px-2"
                                                    style="margin-right: 10px; width: 150px;" name="from"
                                                    placeholder={{this.admin_data.selectDatesPlaceholder}} type="date"
                                                    name="daterange" autocomplete="off" min={{minDate}} max="3000-01-01"
                                                    onkeydown="return false"
                                                    (input)="changeRuleDateRange($event, j, i,true);validateRule(rule);"
                                                    [value]="getDateValue(rule, rule.startDate)" />
                                                <label for="To" class="font-weight-bold  font14">To:&nbsp;</label>
                                                <input name="To" class="form-control px-2"
                                                    style="margin-right: 10px; width: 150px;"
                                                    placeholder={{this.admin_data.selectDatesPlaceholder}} type="date"
                                                    name="daterange" autocomplete="off" min="1920-01-01"
                                                    max="3000-01-01" onkeydown="return false"
                                                    (input)="changeRuleDateRange($event, j, i, false);validateRule(rule);"
                                                    [value]="getDateValue(rule, rule.endDate)" />
                                            </span>
                                            <svg-icon *ngIf="rule.condition === 'Day of the Week'"
                                                src="../../../assets/svgs/icon-info.svg" class="infoIcon mr-2"
                                                data-toggle="tooltip" data-placement="top" data-html="true"
                                                title="{{admin_data.rule_dayOfWeek}}"></svg-icon>
                                            <svg-icon *ngIf="rule.condition === 'Fixed Time in the Day'"
                                                src="../../../assets/svgs/icon-info.svg" class="infoIcon mr-2"
                                                data-toggle="tooltip" data-placement="top" data-html="true"
                                                title="{{admin_data.rule_fixTime}}"></svg-icon>
                                            <svg-icon *ngIf="rule.condition === 'Date Range'"
                                                src="../../../assets/svgs/icon-info.svg" class="infoIcon mr-2"
                                                data-toggle="tooltip" data-placement="top" data-html="true"
                                                title="{{admin_data.rule_dateRange}}"></svg-icon>
                                            <span *ngIf="isDefaultRule(i, j, rule)">
                                                <div style="width: 20px; height: 20px;color: green; display: contents;"
                                                    *ngIf="validateRule(rule); else crossIcon"
                                                    src="../../../assets/svgs/emit_delete.svg">&#10003;</div>
                                                <ng-template #crossIcon>
                                                    <div style="width: 20px; height: 20px;color: red; display: contents;"
                                                        src="../../../assets/svgs/emit_delete.svg">&#10006;</div>
                                                </ng-template>
                                                <svg-icon role="button" style="margin-left: 10px;"
                                                    src="../../../assets/svgs/emit_delete.svg"
                                                    (click)="deleteRule($event, j, i)"></svg-icon>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <span role="button" class="primaryFont-color fontWeight-700 font15 m"
                                    (click)="addRule(i)">{{admin_data.addRule}}</span>
                                <hr>
                            </div>
                            <span role="button" class=" primaryFont-color fontWeight-700 font15"
                                (click)="addGroup()">{{admin_data.addGroup}}</span>
                        </div>
                    </div>
                    <div class="mt-4">
                        <span class="fontWeight-700 font16">{{admin_data.advanceSettings}}</span>
                        <fieldset id="group1" class="ml-2" (change)="changeAdvanceSettings($event)">
                            <span class="form-inline mt-3" >
                                <input type="radio" value="show_guides_user_in_segment" name="group1" id="createSegmentOptionOne" class="mr-2 input-radio-custom"
                                [checked]="advanceSettings['show_guides_user_in_segment'] === 1" >
                                <label for="createSegmentOptionOne" class="font-light-color font14 ">
                                    {{admin_data.createSegmentOptionOne}}
                                </label>
                            </span>
                            <span class="form-inline mt-2">
                                <input type="radio" value="hide_guides_user_in_segment" name="group1" id= "createSegmentOptionThree" class="mr-2 input-radio-custom" [checked]="advanceSettings['hide_guides_user_in_segment'] === 1">
                                <label for="createSegmentOptionTwo" class="font-light-color font14 ">{{admin_data.createSegmentOptionTwo}}</label>
                            </span>
                            <span class="form-inline mt-2">
                                <input type="radio" value="show_guides_user_not_in_segment" name="group1" class="mr-2 input-radio-custom" [checked]="advanceSettings['show_guides_user_not_in_segment'] === 1">
                                <label for="createSegmentOptionThree" class="font-light-color font14 ">{{admin_data.createSegmentOptionThree}}</label>
                            </span>
                        </fieldset>
                    </div>
                    <div class="row m-0 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50">
                        <div
                            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-4 d-flex align-items-center justify-content-start">
                            <app-button [buttonData]="userService.cancel_button"
                                (clickhandler)="redirectToManageSegments()"></app-button>
                            <app-button *ngIf="isEdit" [disabled]="!formGroup ||(!formGroup.valid || !formIsValid)"
                                [buttonData]="userService.update_button" (clickhandler)="editSegment()"></app-button>
                            <app-button *ngIf="!isEdit" [disabled]="!formGroup ||(!formGroup.valid || !formIsValid)"
                                [buttonData]="userService.add_button" (clickhandler)="addSegment()"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>