<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <div class="sectionBody">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 section-header mb-2 p-0 justify-content-between">
                    <span class="section-title">{{this.admin_data.languageTranslations}}</span>
                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl- p-0 text-right" style="min-height: 40px;">
                        <app-custom-select [dataList]="languageArray" componentName="default"
                            [selected]="selectedLanguage" (updateDataList)="setActions($event)"
                            dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                    </div>
                </div>

                <div *ngIf="isTranslationError"
                    class="col-12 alert alert-warning border border-danger rounded d-flex justify-content-start">
                    <p class="mb-0 text-danger warning-text">{{admin_data.translationInProgressInfo}}</p>
                </div>


                <div class="d-flex flex-column my-2 gap_8">
                    <div class="d-flex flex-row align-items-center">
                        <div class="col-xl-3 col-md-4 col-sm-5">
                            {{admin_data.guideTitle}}
                        </div>
                        <div class="col-xl-4 col-md-6 col-sm-7">
                            <textarea name="tour-title" [(ngModel)]="guide_title" class="form-control w-100"></textarea>
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <div class="col-xl-3 col-md-4 col-sm-5">
                            {{admin_data.guideDescription}}
                        </div>
                        <div class="col-xl-9 col-md-8 col-sm-7">
                            <textarea name="tour-desc" [(ngModel)]="guide_description" class="form-control w-100"></textarea>
                        </div>
                    </div>
                </div>
                
                <div>
                    <table class="width-100-percent">
                        <thead>
                            <tr>
                                <th>{{admin_data.stepNo}}</th>
                                <th>{{admin_data.stepTitle}}</th>
                                <th>{{admin_data.stepDescription}}</th>
                                <th>{{admin_data.tableHeaderActions}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let entry of stepData | keyvalue; let i = index;">
                                <td>{{i+1}}</td>
                                <td>
                                    <ng-container *ngIf="entry.key != editKey">
                                        {{stripHtmlTags(entry.value.title)}}
                                    </ng-container>
                                    <ng-container *ngIf="entry.key == editKey">
                                        <editor [init]="getTinyEditorConfig()" [(ngModel)]="entry.value.title"></editor>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngIf="entry.key != editKey">
                                        {{stripHtmlTags(entry.value.description)}}
                                    </ng-container>
                                    <ng-container *ngIf="entry.key == editKey">
                                        <editor [init]="getTinyEditorConfig()" [(ngModel)]="entry.value.description"></editor>
                                    </ng-container>
                                </td>
                                <td>
                                    <svg-icon src="../../../assets/svgs/edit.svg" role="button" (click)="setEditMode(entry.key)"></svg-icon>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="d-flex justify-content-end mt-4">
                    <button class="btn btn-primary mr-2" (click)="updateLanguageData()"
                        [disabled]="isTranslationError">{{this.admin_data.saveButtonText}}</button>
                    <button class="btn btn-secondary" (click)="discard()">{{this.admin_data.discardBtnTxt}}</button>
                </div>
            </div>
        </section>
    </div>
</div>
