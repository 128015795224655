if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.ko_KR = {
    "myGuideTitle": "MyGuide",
    "addButtonText": "추가",
    "addingButtonText": "추가 중...",
    "cancelButtonText": "취소",
    "okButtonText": "확인",
    "updateButtonText": "업데이트",
    "updatingButtonText": "업데이트하는 중...",
    "deleteButtonText": "삭제",
    "deletingButtonText": "삭제 중...",
    "uploadButtonText": "업로드",
    "uploadingButtonText": "업로드하는 중",
    "saveButtonText": "저장",
    "savingButtonText": "저장 중...",
    "saveSettingsButtonText": "설정 저장",
    "noDataFoundText": "데이터가 없습니다!",
    "generateButtonText": "생성",
    "generatingButtonText": "생성 중...",
    "actionDeclined": "작업이 거부되었습니다!",
    "searchPlaceholder": "검색",
    "searchNameIdPlaceholder": "이름/성, 전자 메일 ID 검색",
    "selectDatesPlaceholder": "날짜 선택",
    "enterIDPAttributeNamePlaceholder": "IDP 특성 이름 입력",
    "domainFormatPlaceholder": "name@domain.com",
    "error": "오류",
    "yes": "예",
    "no": "아니요",
    "all": "모두",
    "web": "웹",
    "desktop": "데스크톱",
    "android": "Android",
    "ios": "IOS",
    "mobileWeb": "모바일 웹",
    "user": "사용자",
    "currentAdminPassword": "현재 Admin 암호",
    "note": "참고:",
    "domainMessage": "SSO IDP는 기본적으로 조직에 바인딩되어 있습니다. 따라서 사용자가 웹 도메인을 방문하거나 세션을 찾을 수 없는 경우 기본 SSO URL이 트리거됩니다. 이 섹션에서는 연결된 도메인에서만 SSO URL을 트리거하도록 SSO URL을 특정 도메인에 바인딩할 수 있습니다. 이 방법으로 조직은 여러 도메인에 여러 SSO IDP를 가질 수 있습니다.",
    "refresh": "새로 고침",
    "iconWorks": "아이콘이 작동합니다!",
    "pageNotWorks": "페이지를 찾을 수 없음이 작동합니다!",
    "valid": "유효.",
    "selectAll": "모두 선택",
    "guideTitle": "Guide 제목",
    "copy": "복사",
    "copied": "복사됨!!",
    "on": "사용",
    "off": "해제",
    "publish": "게시",
    "unpublished": "게시 취소됨",
    "locked": "잠김",
    "unlocked": "잠금 해제됨",
    "superAdmin": "수퍼 Admin",
    "download": "다운로드하기",
    "noDataFound": "데이터가 없습니다.",
    "and": "및",
    "guideRoleAdministrator": "조직 Admin",
    "guideRoleCreator": "생성자",
    "guideRolePlayer": "플레이어",
    "guideRoleAppAdmin": "앱 Admin",
    "tableHeaderStatus": "상태",
    "tableHeaderProgress": "진행률",
    "tableHeaderTitle": "제목",
    "tableHeaderType": "유형",
    "tableHeaderCreationDate": "생성 날짜",
    "tableHeaderActions": "작업",
    "tableHeaderMarkAll": "모두 표시",
    "tableHeaderDescription": "설명",
    "pleaseSelect": "선택하십시오.",
    "close": "닫기",
    "clear": "지우기",
    "apply": "신청",
    "patternInvalid": "패턴이 잘못됨",
    "isRequired": "필수임",
    "confirmNewPassword": "새 암호 확인",
    "newPassword": "새 암호",
    "currentPassword": "현재 암호",
    "addApplicationAddBreadcrumb": "응용 프로그램 추가",
    "addApplicationEditBreadcrumb": "응용 프로그램 편집",
    "addApplicationUploadThumbnail": "응용 프로그램의 축소판 이미지 업로드",
    "addApplicationGuideAutomation": "Guide 자동화",
    "addApplicationApplicationLevelTranslationLanguages": "응용 프로그램 수준 번역 언어",
    "addApplicationEnableApplicationLanguageSettings": "응용 프로그램 수준 언어 설정 사용",
    "addApplicationPackageId": "패키지 ID",
    "addApplicationBundleId": "번들 ID",
    "addApplicationMaxApplicationLimit": "조직이 최대 응용 프로그램 한도를 초과했으므로 새 응용 프로그램을 추가할 수 없습니다.",
    "addApplicationIdExists": "패키지 ID/번들 ID가 이미 있으므로 새 응용 프로그램을 추가할 수 없습니다.",
    "addFolderName": "폴더 이름",
    "addOrganisationName": "조직 이름",
    "addOrganisationType": "조직 유형",
    "addUserAvtar": "Avtar",
    "addUserFirstName": "이름",
    "addUserLastName": "성",
    "addUserEmailId": "전자 메일 ID",
    "addUserOrganisationRole": "앱 액세스 역할",
    "addUserLockedStatus": "잠김 상태",
    "addUserRole": "사용자 역할",
    "addUserUUName": "UUName",
    "labelDoItForMe": "DoItForMe",
    "labelMyChat": "내채팅",
    "labelTeachMe": "TeachMe",
    "labelTutorialGuides": "Tutorial Guides",
    "labelGuideTranslation": "Guide 번역",
    "labelShowCurrentPageGuidesOnly": "현재 Page Guides만 표시",
    "labelTooltipEnhancements": "Tooltip 향상",
    "labelUserInformationTracking": "사용자 정보 추적",
    "labelSSO": "SSO",
    "labelMediaFileCreation": "미디어 파일 생성",
    "labelYoutubeSettings": "YouTube 설정",
    "labelEnableOrgAndGuidesJSONCaching": "조직 및 Guides JSON 캐싱 사용",
    "labelGuideAutomation": "Guide 자동화",
    "labelAPIConnector": "API 커넥터",
    "labelLoadContextChangeOnPageClick": "페이지 클릭 시 컨텍스트 변경 사항 로드",
    "labelTaskList": "과제 목록",
    "labelMediaFileTheme": "미디어 파일 테마",
    "labelDisplayGuidePublishingDate": "Guide 게시 날짜 표시",
    "labelMaintenanceMode": "유지 관리 모드",
    "labelEnableGuidemeAsSecondaryOption": "작업 가이드를 보조 옵션으로 사용",
    "labelHashPlayerUsersData": "해시 플레이어 사용자의 데이터",
    "labelAllowOnPremiseDeployment": "온프레미스 배포 허용",
    "labelFTPEndpointToSaveContent": "콘텐츠를 저장할 FTP 끝점",
    "labelEnableInsights": "Insights 사용",
    "labelDisplayAcceptCookiePopup": "쿠키 허용 팝업 표시",
    "labelSetRefreshTokensExpiryTime": "새로 고침 토큰의 만료 시간(초) 설정",
    "labelGuideInsights": "Guide Insights",
    "labelRealTimeInsights": "실시간 Insights",
    "labelInsightsPriority": "Insights 우선 순위",
    "labelMaxGuideLimit": "최대 Guide 한도",
    "labelMaxApplicationLimit": "최대 응용 프로그램 한도",
    "labelMaxStepLimit": "최대 단계 한도",
    "labelMaxAdminUsers": "최대 Admin 사용자",
    "labelMaxCreatorUsers": "최대 생성자 사용자",
    "labelMaxPlayerUsers": "최대 플레이어 사용자 수",
    "tooltipDoItForMe": "안내서를 Do it for me(자동 모드)에서 재생하도록 허용",
    "tooltipMyChat": "가상 채팅 보조 도구 사용",
    "tooltipTeachMe": "호스트 응용 프로그램 시뮬레이션에서 안내서 재생 사용",
    "tooltipTutorialGuides": "Tutorial 안내서 기능 사용",
    "tooltipGuideTranslation": "안내서를 MyGuide에서 지원되는 하나 이상의 언어로 번역할 수 있습니다.",
    "tooltipShowBeaconGuidesOnCurrentPage": "현재 페이지에 표시하도록 비콘을 구성한 안내서를 사용합니다. 기본적으로 비콘이 페이지에 렌더링되고 해당 안내서는 현재 페이지에 표시되지 않습니다.",
    "tooltipGuideSegmentation": "사용자 특성 데이터를 기반으로 사용자가 액세스하고 액세스할 수 없는 안내서 관리",
    "tooltipShowCurrentPageGuidesOnly": "현재 페이지에서 사용할 수 없는 안내서가 포함된 모든 Guides 탭 및 폴더 계층을 숨깁니다.",
    "tooltipTooltipEnhancements": "Tooltip 향상",
    "tooltipUserInformationTracking": "고유 사용자 데이터를 추적하여 더 정확한 Insights 추적 사용",
    "tooltipSSO": "단일 사인온(SSO) 통합을 클라이언트 IDP와 함께 사용",
    "tooltipMediaFileCreation": ".pdf, .docx 등 다양한 형식으로 MyGuide 콘텐츠를 자동으로 내보낼 수 있습니다.",
    "tooltipYoutubeSettings": "Youtube 커넥터를 사용하여 YouTube에 안내서를 게시합니다.",
    "tooltipEnableOrgAndGuidesJSONCaching": "조직 JSON을 사용하여 모든 안내서와 모든 단계를 포함합니다. 100개 미만의 게시된 안내서가 있는 경우에만 권장됩니다. 조직 JSON의 전체 크기가 4MB 이상인 경우 사용해서는 안 됩니다.",
    "tooltipAPIConnector": "타사 API 커넥터를 사용하여 데이터를 가져옵니다. 지원되는 커넥터는 Zendesk 등이 있습니다.",
    "tooltipLoadContextChangeOnPageClick": "사용자가 페이지를 클릭할 때 규칙 검색을 제한할 수 있습니다.",
    "tooltipTaskList": "안내서 기반 과제 목록을 생성하여 사용자에게 과제로 할당할 수 있습니다.",
    "tooltipMediaFileTheme": "미디어 파일 테마를 선택 및 사용자 지정할 수 있습니다.",
    "tooltipDisplayGuidePublishingDate": "비디오, pdf 등의 다운로드 가능한 형식으로 안내서 게시 날짜를 표시할 수 있습니다.",
    "tooltipMaintenanceMode": "유지 관리 모드",
    "tooltipEnableGuidemeAsSecondaryOption": "기본적으로 showme를 사용하여 생성한 안내서에 대해 guideme를 보조 모드로 사용합니다.",
    "tooltipHashPlayerUsersData": "해시 플레이어 사용자의 데이터",
    "tooltipAllowOnPremiseDeployment": "배포를 허용하고 MyGuide를 로컬/인트라넷 설정으로 운영합니다.",
    "tooltipFTPEndpointToSaveContent": "ftp ://로 시작하는 유효한 FTP 주소를 입력하십시오. 예: ftp : //1.2.3.4 : 21 또는 ftp : //abc.example.com : 21",
    "tooltipEnableInsights": "기본적으로 사용됩니다. 사용하지 않는 경우 조직에 대한 모든 유형의 인사이트를 중지합니다.",
    "tooltipDisplayAcceptCookiePopup": "쿠키 수락 팝업 표시를 사용합니다.",
    "tooltipSetRefreshTokensExpiryTime": "새로 고침 토큰에 대한 만료 시간 설정",
    "tooltipGuideInsights": "MyGuide 안내서, 도구 설명, 비콘 등의 사용자 상호 작용에 대한 안내서 인사이트를 사용합니다. 기본적으로 사용됩니다.",
    "tooltipRealTimeInsights": "이 기능을 사용하도록 설정하면 인사이트 포털의 특정 섹션에 대한 실시간 데이터 반영이 제공됩니다.",
    "tooltipAppInsights": "클릭 추적, 페이지 추적, 워크플로우 추적 등의 응용 프로그램 인사이트를 사용합니다. 이 기능은 사용자가 안내서, 비콘, 도구 설명 등과 상호 작용하지 않고 백그라운드에서 작동합니다.",
    "tooltipInsightsPriority": "MyGuide의 Insights 우선 순위 설정",
    "languageTranslationsSettings": "언어 번역 규칙",
    "englishDefault": "1. 사용 지역과 관계없이 영어를 기본 언어로 선택한 경우에만 언어 번역을 사용할 수 있습니다.",
    "languageEdited": "2. 기본 언어를 편집하는 경우 \"언어 선택\" 옵션에서 이전에 추가한 언어를 다시 선택해야 합니다.",
    "defaultLanguage": "기본 언어",
    "defaultVoice": "기본 음성",
    "moreLanguage": "기타 언어",
    "guideOrWorkflowAutomation": "Guide 자동화 또는 Workflow 자동화",
    "cannotBeDisabled": "이 옵션을 사용하도록 설정한 후에는 사용하지 않도록 설정할 수 없습니다.",
    "okToApply": "이 설정을 적용하려면 확인 버튼을 클릭하십시오.",
    "addToConnector": "커넥터 추가",
    "doYouWantToDelete": "삭제하시겠습니까?",
    "segmentData": "데이터:",
    "segmentKeyName": "키 이름:",
    "ssoDescription": "SSO 설명",
    "ssoIssuerURL": "발급자 URL",
    "ssoCertificate": "x.509 인증서",
    "ssoAPIKey": "API 키",
    "ssoAPISecret": "API 비밀",
    "ssoNameIdEncrypted": "NameId를 암호화하시겠습니까?",
    "ssoMetaDataSigned": "메타데이터에 서명하시겠습니까?",
    "ssoResponseSigned": "응답에 서명하시겠습니까?",
    "ssoAssertionSigned": "어설션에 서명하시겠습니까?",
    "ssoSignatureAlgorithm": "서명 알고리즘",
    "ssoDigestAlgorithm": "다이제스트 알고리즘",
    "ssoMessageSigned": "메시지에 서명하시겠습니까?",
    "ssoWish": "NameId 암호화에 동일한 인증서를 사용하시겠습니까?",
    "ssoEncryptCertificate": "암호화 x509 인증서",
    "ssoAllowAplicationSingleLayout": "응용 프로그램에서 단일 로그아웃을 시작하도록 허용하시겠습니까?",
    "ssoAuthRequestSigned": "인증 요청에 서명하시겠습니까?",
    "ssoConfirmationMessage": "SSO 확인 메시지",
    "ssoButtonMessage": "SSO 버튼 메시지",
    "ssoSingleLogoutURL": "단일 로그아웃 URL",
    "ssoListName": "이름:*",
    "userProvisioningMethod": "사용자 프로비저닝 방법",
    "apiConnectorToken": "토큰",
    "apiConnectorEmailAddress": "전자 메일 주소",
    "apiConnectorAPIEndpoint": "API 끝점",
    "apiConnectorFolder": "폴더",
    "organisationFormDualAuthentication": "이중 인증",
    "organisationFormUploadOrganisationThumbnail": "조직 축소판 이미지 업로드",
    "organisationFormUploadFirstSlide": "첫 번째 슬라이드 이미지 업로드",
    "organisationFormUploadFirstSlideText": "첫 번째 슬라이드 텍스트",
    "organisationFormUploadLastSlide": "마지막 슬라이드 이미지 업로드",
    "organisationFormUploadLastSlideText": "마지막 슬라이드 텍스트",
    "organisationFormCopyrightText": "저작권 텍스트",
    "applicationCreatedText": "응용 프로그램 생성됨",
    "applicationUpdatedText": "응용 프로그램 업데이트됨",
    "applicationDeletedText": "응용 프로그램 삭제됨",
    "applicationAssignedText": "응용 프로그램 할당됨",
    "automationStatusUpdatedText": "자동화 상태 업데이트됨",
    "roleCreatedText": "역할 생성됨",
    "roleUpdatedText": "역할 업데이트됨",
    "roleDeletedText": "역할 삭제됨",
    "roleAssignedText": "역할 할당됨",
    "roleAssignedToApplicationText": "응용 프로그램에 할당된 역할",
    "organisationRoleAssignedText": "조직 역할 할당됨",
    "credentialsCreatedText": "자격 증명 생성됨",
    "credentialsDeletedText": "자격 증명 삭제됨",
    "segmentCreatedText": "세그먼트 생성됨",
    "segmentUpdatedText": "세그먼트 업데이트됨",
    "segmentDeletedText": "세그먼트 삭제됨",
    "segmentValueCreatedText": "세그먼트 값 생성됨",
    "segmentValueDeletedText": "세그먼트 값 삭제됨",
    "userCreatedText": "사용자 생성됨",
    "userUpdatedText": "사용자 업데이트됨",
    "userDeletedText": "사용자 삭제됨",
    "domainCreatedText": "도메인 생성됨",
    "domainDeletedText": "도메인 삭제됨",
    "updatedDomainSsoMapping": "업데이트된 도메인 SSO 매핑",
    "apiConnectorAddedText": "API 커넥터 추가됨",
    "apiConnectorDeletedText": "API 커넥터 삭제됨",
    "taskListCreatedText": "과제 목록 생성됨",
    "taskListUpdatedText": "과제 목록 업데이트됨",
    "taskListDeletedText": "과제 삭제됨",
    "visibilitySettingsUpdatedText": "표시 유형 설정이 업데이트되었습니다.",
    "adminSettingsUpdatedText": "Admin 설정 업데이트됨",
    "importedText": "가져옴",
    "userUnlockedText": "사용자 잠금 해제됨",
    "userStatusChangedText": "사용자 상태 변경됨",
    "sessionClearedforUserText": "사용자에 대한 세션 지워짐",
    "passwordChangedText": "암호 변경됨",
    "organisationUpdatedText": "조직 업데이트됨",
    "organisationActiveStatusChangedText": "조직 활성 상태 변경됨",
    "organisationVerifiedStatusChangedText": "조직 확인됨 상태 변경됨",
    "organisationCreatedText": "조직 추가됨",
    "organisationDeletedText": "조직 삭제됨",
    "ssoCreatedText": "SSO 추가됨",
    "ssoDeletedText": "SSO 삭제됨",
    "changesReflectedText": "변경 내용이 몇 분 후에 플레이어 포털에 반영됩니다.",
    "sessionClearedForOrganisationText": "조직에 대한 세션이 지워졌습니다!",
    "ssoConfigurationUpdatedText": "SSO 구성 업데이트됨",
    "instantUpdateText": "즉시 업데이트 성공",
    "sessionClearedText": "세션 지워짐",
    "successfullyText": "성공!",
    "successText": "성공!",
    "login": "로그인",
    "loginInvalidCredentials": "잘못된 로그인 자격 증명입니다. 다시 시도하십시오.",
    "loginAccessForbidden": "액세스 금지",
    "loginPassword": "암호",
    "loginFillField": "이 필드를 입력하십시오.",
    "loginFieldMandatory": "이 필드는 필수 필드입니다.",
    "loginContactEdcast": "도움이 필요하십니까? 전자 메일을 보내 주십시오.",
    "loginCopyright": "Copyright ©",
    "support": "support@csod.com",
    "forgotPassword": "암호 분실",
    "resetPassword": "암호 재설정",
    "forgotPasswordBackToLogin": "로그인으로 돌아가기",
    "forgotPasswordResendLink": "링크 다시 보내기",
    "forgotPasswordInstructions": "- 유효한 MyGuide ID인 경우 새 암호를 만드는 방법에 대한 지침이 포함된 전자 메일을 받아야 합니다.",
    "forgotPasswordLinkSent": "암호 재설정 링크가 전송되었습니다.",
    "forgotPasswordDidntRecieveMail": "전자 메일을 받지 못했습니까?",
    "forgotPasswordCheckSpam": "스팸 또는 대량 폴더에서 수신 메시지 확인",
    "generatePasswordSuccesfullyReset": "암호가 재설정되었습니다.",
    "generatePasswordLinkExpired": "암호 링크 생성 만료 또는 잘못된 OTP",
    "generatePasswordMustContain": "새 암호에 다음이 포함되어야 합니다.",
    "generatePasswordCharacterLimit": "8-20자 사이",
    "generatePasswordOneNumber": "숫자 하나 이상",
    "generatePasswordOneUpperCase": "대문자 1자 이상",
    "generatePasswordOneSpecialCharacter": "특수 문자 한 개 이상(예: @,*,!)",
    "generatePasswordShouldMatch": "새 암호 및 새 암호 확인 필드가 일치해야 합니다.",
    "organisationSelectText": "조직 선택",
    "organisationAddText": "조직 추가",
    "organisationEditText": "조직 편집",
    "organisationActiveText": "활성",
    "organisationVerifiedText": "확인됨",
    "organisationPaid": "유료",
    "organisationTrial": "평가판",
    "organisationHeading1": "비디오 & GIF 설정",
    "organisationTooltip1": "다음 설정은 조직에 대해 생성된 안내서의 비디오 및 GIF 형식 구조를 사용자 지정하는 데 사용됩니다.",
    "dashboardTitle": "Dashboard | MyGuide",
    "dashboardTriggerInstantUpdateButton": "즉시 업데이트 트리거",
    "dashboardClearSessionButton": "세션 지우기",
    "dashboardTriggerInstantUpdateButtonTooltip": "플레이어 포털은 매시간 업데이트됩니다. 즉시 업데이트를 트리거하려면 여기를 클릭하십시오. 변경 내용은 2~10 분 후에 반영됩니다.",
    "dashboardClearSessionButtonTooltip": "조직에 대한 세션을 지웁니다.",
    "dashboardUserAction": "사용자 작업",
    "dashboardUserKey": "사용자 키",
    "dashboardRegisteredDate": "등록한 날짜",
    "apiAndServicesTitle": "API & 서비스 |",
    "apiAndServicesBreadCrumbTitle": "API & 서비스",
    "apiAndServicesGenrateCrendentialsButton": "자격 증명 생성",
    "apiAndServicesTableHeaderKeyName": "키 이름",
    "apiAndServicesTableHeaderKey": "키",
    "apiAndServicesTableHeaderSecret": "비밀",
    "apiAndServicesTableHeaderAnonymousAccess": "익명 액세스",
    "apiAndServicesTableHeaderJITProvisioning": "JIT 프로비저닝",
    "applicationsTitle": "신청 |",
    "applicationsAssignTitle": "응용 프로그램 할당",
    "applicationsBreadCrumbTitle": "신청",
    "applicationsTableHeaderThumbnail": "미리 보기",
    "applicationsTableHeaderApplicationName": "응용 프로그램 이름",
    "applicationsTableHeaderApplicationId": "앱 ID",
    "applicationsTableHeaderMobileApplicationId": "모바일 응용 프로그램 ID",
    "applicationsTableHeaderAutomationStatus": "자동화 상태",
    "applicationsUpdateAutomationStatus": "자동화 상태 업데이트",
    "changeAutomationStatus": "이 응용 프로그램의 자동화 상태를 변경 하 시겠습니까?",
    "rolesAddRoleButtonText": "역할 추가",
    "rolesAddingRoleButtonText": "역할 추가 중...",
    "rolesUpdateRoleButtonText": "역할 업데이트",
    "rolesUpdatingRoleButtonText": "역할 업데이트 중...",
    "usersBreadCrumbTitle": "사용자",
    "segmentsTableHeaderKeyName": "키 이름",
    "segmentsListTableHeaderValue": "값",
    "segmentsListTableHeaderIsDefault": "기본값입니까?",
    "segmentsAddSegmentButtonText": "세그먼트 추가",
    "segmentsUpdateSegmentButtonText": "세그먼트 업데이트",
    "segmentsAddValueButtonText": "값 추가",
    "segmentsAddingValueButtonText": "값 추가 중...",
    "segmentsAddingSegmentButtonText": "세그먼트 추가 중...",
    "segmentsUpdatingSegmentButtonText": "세그먼트 업데이트 중..",
    "addSegmentsValue": "세그먼트 값 추가",
    "domainsAddDomainDomainName": "도메인 이름",
    "domainsAddDomainSSOIDP": "SSO IDP",
    "domainsAddDomainButtonText": "도메인 추가",
    "domainsAddingDomainButtonText": "도메인 추가 중...",
    "apiConnectorTableHeaderAPIName": "API 이름",
    "apiConnectorTableHeaderSyncCompleted": "동기화 완료됨",
    "ssoListTableHeaderSSOName": "SSO 이름",
    "ssoListDownloadMetadata": "메타데이터 다운로드",
    "ssoListIdentityProviders": "ID 공급자",
    "ssoListDomainMappings": "도메인 매핑",
    "addSSOIDP": "SSO IDP 추가",
    "addSSO": "SSO 추가",
    "updateSSO": "SSO 업데이트",
    "featureSettings": "기능 설정",
    "insightsSettings": "Insights 설정",
    "limitSettings": "한도 설정",
    "languageTranslations": "언어 번역",
    "chooseImage": "이미지 선택",
    "adminSettingsWarning": "경고! 유지 관리 모드를 사용하면 이 조직이 사용되지 않습니다.",
    "adminSettingsHashPlayersData": "해시 플레이어 사용자의 데이터",
    "adminSettingsEnableSure": "사용하시겠습니까?",
    "adminSettingsYouCanEither": "다음 중 하나를 사용할 수 있습니다.",
    "adminSettingsGuideAutomation": "Guide 자동화",
    "adminSettingsWorkflowAutomation": "Workflow 자동화",
    "adminSettingsUnselectProceed": "계속하려면 이 중 하나를 선택 취소하십시오.",
    "activityTableHeaderActivity": "활동",
    "activityTableHeaderActivityEmailId": "활동 전자 메일 ID",
    "activityTableHeaderActivityIPAddress": "IP 주소",
    "activityAdmin": "Admin 활동",
    "activityLogin": "로그인 활동",
    "activityType": "활동 유형",
    "activityAction": "작업",
    "activityDetails": "활동 세부 정보",
    "activityTableHeaderActivityIPAddresss": "IP 주소",
    "userProvisioningTitle": "사용자 프로비저닝 |",
    "userProvisioningBreadCrumbTitle": "사용자 프로비저닝",
    "userProvisioningProvideKey": "키 이름 제공",
    "exportDataExportGuideButtonText": "Guide 데이터 내보내기",
    "exportDataExportUserButtonText": "사용자 데이터 내보내기",
    "exportDataTableHeaderExportType": "내보내기 유형",
    "exportDataTableHeaderExportedBy": "내보낸 사람",
    "guideList": "Guide 목록",
    "userList": "사용자 목록",
    "selectApplications": "응용 프로그램 선택",
    "selectApplicationsMessage": "안내서 데이터를 내보낼 응용 프로그램을 선택하십시오.",
    "exportDetails": "세부 정보 가져오기",
    "taskListAddTaskButtonText": "과제 추가",
    "taskListAddingTaskButtonText": "과제 추가 중...",
    "taskListUpdateTaskButtonText": "과제 업데이트",
    "taskListUpdatingTaskButtonText": "과제 업데이트 중...",
    "taskListTableHeaderApplication": "응용 프로그램",
    "taskListTableHeaderLeaderboard": "Leaderboard",
    "addGroup": "그룹 추가",
    "removeGroup": "그룹 제거",
    "updateTaskList": "과제 목록 업데이트",
    "addTaskList": "과제 목록 추가",
    "formMessage1": "1. 도메인 이름에는 '.' 마침표로 구분된 2개 이상의 파트가 포함되어야 합니다.",
    "formMessage2": "2. 도메인 이름 부분은 하이픈 '-'으로 시작하거나 끝날 수 없습니다.",
    "formMessage3": "3. 도메인 이름은 문자 a-z, 숫자 0-9 및 하이픈을 포함할 수 있습니다.",
    "formMessage4": "4. 도메인 이름의 다음 부분은 최소한 2자 이상이어야 합니다.",
    "recommendedSize": "(권장 크기 500X500 px) 지원되는 파일: JPG, PNG",
    "recommendedSize2": "권장 크기(500px X 500px).",
    "topLeft": "왼쪽 상단",
    "topRight": "오른쪽 상단",
    "bottomLeft": "왼쪽 하단",
    "bottomRight": "오른쪽 하단",
    "importUserVariable1": "가져오기 사용자를 위한 CSV 파일 업로드",
    "importUserVariable2": "CSV 파일을 끌어서 여기에 놓기",
    "importUserVariable4": "찾아보기",
    "importUserVariable5": "잘못된 파일 유형",
    "importUserVariable6": "벌크 전자 메일 보내기:",
    "importUserVariable7": "예",
    "importUserVariable8": "아니요",
    "importUserVariable9": "업로드",
    "importUserVariable10": "사용자 파일 기록 가져오기",
    "importUserVariable11": "새로 고침",
    "importUserVariable12": "샘플 CSV 다운로드",
    "importUserVariable13": "또는",
    "generateCredentialsVariable1": "자격 증명 생성 정보",
    "generateCredentialsVariable2": "익명",
    "generateCredentialsVariable3": "- 사용자가 MyGuide의 정보를 저장하지 않으려는 경우 익명을 선택합니다.",
    "generateCredentialsVariable4": "JIT",
    "generateCredentialsVariable5": "JIT를 선택하는 경우 이 전자 메일이 이미 MyGuide에 등록되어 있는지 확인합니다. 그렇지 않으면 개인을 MyGuide 사용자로 추가합니다.",
    "generateCredentialsVariable6": "키 이름",
    "generateCredentialsVariable7": "- 이 필드에 이 키를 만드는 목적을 입력합니다. 예: SSO, 테스트, Salesforce, .js 등",
    "generateCredentialsVariable8": "이미지 선택",
    "generateCredentialsVariable15": "응용 프로그램 선택",
    "generateCredentialsVariable16": "신청 필요",
    "headerVariable1": "조직:",
    "headerVariable3": "암호 변경",
    "headerVariable4": "로그아웃",
    "manageContentVariable1": "과제 목록:",
    "manageContentVariable5": "응용 프로그램:",
    "manageContentVariable6": "미리 보기",
    "manageContentVariable10": "아니요 Guides",
    "manageContentVariable12": "Guides 관리",
    "manageContentVariable13": "폴더 추가",
    "manageContentVariable17": "모두 선택",
    "manageContentVariable18": "Guides 선택됨",
    "manageContentVariable21": "이 응용 프로그램에 사용 가능한 게시 Guides 없음",
    "manageContentVariable27": "이전",
    "manageContentVariable29": "다음",
    "manageContentVariable30": "이 폴더에는 안내서가 포함 되어있으므로 삭제할 수 없습니다. Guides을(를) 제거한 후 다시 시도하십시오!",
    "manageRoleVariable5": "역할 추가",
    "manageRoleVariable1": "역할 지정",
    "otpVariable1": "로그인으로 돌아가기",
    "otpVariable2": "계정 확인",
    "otpVariable3": "사용자 잠금 해제",
    "otpVariable4": "다음으로 OTP를 전송했습니다.",
    "otpVariable6": "OTP 다시 보내기",
    "otpVariable7": "로그인으로 돌아가기",
    "otpVariable8": "사용자 계정이 성공적으로 잠금 해제되었습니다.",
    "otpVariable9": ". 계정을 확인하려면 일회용 암호를 입력하십시오",
    "otpVariable10": "계정을 잠금 해제하려면 일회용 암호를 입력하십시오",
    "otpVariable11": "OPT",
    "otpVariable12": "잘못된 OTP",
    "otpVariable13": "올바른 OTP로 시도하십시오.",
    "otpVariable14": "OTP를 수신하지 않았음",
    "otpVariable15": "OTP 다시 보내기",
    "attributeMapping": "특성 매핑",
    "stepTitle": "단계 제목",
    "stepDescription": "단계 설명",
    "stepTooltips": "단계 도구 설명",
    "pageThemeNumber": "Page ##",
    "themeListVariable1": "PPT, 문서 &PDF 테마 설정",
    "themeListVariable2": "정보:",
    "themeListVariable3": "활성 테마는 PPT(.pptx), PDF(.pdf) 및 문서(.docx)를 만드는 데 사용됩니다",
    "themeListVariable4": "활성",
    "themeListVariable5": "활성화",
    "themeListVariable6": "테마 활성화",
    "themeListVariable7": "기본 테마를 변경하시겠습니까?",
    "themeListVariable8": "아니요",
    "themeListVariable9": "예",
    "usersVariable2": "상태",
    "usersVariable3": "모두",
    "usersVariable4": "활성",
    "usersVariable5": "일시 중단됨",
    "usersVariable6": "모두",
    "usersVariable7": "조직 Admin",
    "usersVariable8": "생성자",
    "usersVariable9": "플레이어",
    "usersVariable10": "앱 Admin",
    "usersVariable11": "MG Admin",
    "usersVariable12": "앱 액세스 역할",
    "usersVariable13": "모두",
    "usersVariable14": "데이터 내보내기",
    "usersVariable19": "잠긴 상태 업데이트",
    "usersVariable20": "상태 업데이트",
    "usersVariable22": "이 사용자를 잠금 해제하시겠습니까?",
    "usersVariable23": "이 사용자의 상태를 업데이트하시겠습니까?",
    "usersVariable25": "잠금 해제",
    "usersVariable26": "예",
    "usersVariable27": "사용자 추가",
    "usersVariable28": "사용자 가져오기",
    "usersVariable29": "잠긴 상태 업데이트",
    "usersVariable30": "상태 업데이트",
    "usersVariable31": "사용자 역할",
    "usersProvisioningVariable30": "1. 새 프로비저닝 토큰을 생성하면 기존 프로비저닝 토큰이 삭제/대체됩니다. 이렇게 하면 이전 토큰을 사용하는 사용자 프로비저닝 API에 액세스할 수 없습니다.",
    "usersProvisioningVariable31": "2. 생성된 자격 증명은 아래 선택한 구축 방법에 따라 달라집니다.",
    "usersProvisioningVariable1": "이 팝업을 닫기 전에 위의 자격 증명을 복사하십시오. 이 팝업을 닫은 후에는 다시 볼 수 없습니다.",
    "usersProvisioningVariable2": "API 토큰",
    "usersProvisioningVariable3": "계속하시겠습니까?",
    "tableUnmapped": "매핑되지 않음",
    "tableTitle1": "세그먼트 값 지정",
    "tableTitle2": "편집",
    "tableTitle3": "콘텐츠 관리",
    "tableTitle4": "편집하려면 클릭",
    "tableTitle5": "모든 세션에서 로그아웃",
    "visibility": "표시 유형",
    "taskVariable1": "경로는 항상 슬래시로 시작하고 영숫자 문자와 특수 문자(~,=,<,>,/,*만)만 허용됩니다. 경로 문자열에는 공백을 사용할 수 없습니다.",
    "taskVariable2": "이 필드는 필수 필드입니다.",
    "taskVariable3": "이중 슬래시는 허용 되지 않습니다.",
    "taskVariable4": "슬래시 뒤에서는 <>와 ~=를 함께 사용할  수 없습니다.",
    "taskVariable5": "오류: _ 및 -는 꺾쇠 괄호(<>)를 제외하고는 허용되지 않습니다.",
    "taskVariable6": "경로에 ~ 또는 =만 있을 수는 없습니다. ~에는 항상 =이 있어야 합니다.",
    "taskVariable7": "잘못된 매개 변수: 항상 ?로 시작되며 매개 변수에서 ? 다음에 =가 있을 수 없습니다.",
    "taskVariable8": "잘못된 매개 변수: & 뒤에는 영숫자 문자와 특수 문자(-, _)가 허용됩니다.",
    "taskVariable9": "잘못된 매개 변수: 영숫자 문자와 특수 문자(-, _, =, &)만 허용됩니다.",
    "taskVariable10": "잘못된 해시: 항상 #으로 시작합니다.",
    "applications": "신청",
    "users": "사용자",
    "manageRoles": "역할 관리",
    "themes": "테마",
    "activity": "활동",
    "exportData": "데이터 내보내기",
    "taskList": "과제 목록",
    "segments": "세그먼트",
    "domains": "도메인",
    "adminSettings": "Admin 설정",
    "themeSettings": "테마 설정",
    "guidePublishingDate": "Guide 게시 날짜 표시",
    "firstSlide": "첫 번째 슬라이드",
    "lastSlide": "마지막 슬라이드",
    "setDisplay": "표시 위치 설정",
    "setFirstSlide": "첫 번째 슬라이드 표시 위치 설정",
    "setLastSlide": "마지막 슬라이드 표시 위치 설정",
    "markAll": "모두 표시",
    "language": "언어",
    "voice": "음성",
    "uploadProfileImage": "프로필 이미지 업로드",
    "downloadSampleCSV": "샘플 CSV 다운로드",
    "done": "완료",
    "started": "시작",
    "pending": "보류 중",
    "failed": "불합격",
    "changeUserPassword": "사용자 암호 변경",
    "segmentValues": "세그먼트 값",
    "tokenGenerated": "토큰 생성됨",
    "availableSoon": "파일을 곧 사용할 수 있습니다.",
    "exportInProgress": "내보내기가 진행 중입니다!",
    "themeActivated": "테마 활성화됨",
    "themeUpdated": "테마 활성화됨",
    "themeGenerated": "테마 생성됨",
    "themeActivation": "테마 활성화",
    "invalidExtension": "잘못된 확장명 또는 크기가 2MB보다 큽니다.",
    "unableUpdateLogo": "로고를 업데이트할 수 없음",
    "create": "만들기",
    "delete": "삭제",
    "update": "업데이트",
    "field": "필드",
    "originalValue": "원래 값",
    "newValue": "새 값",
    "message1": "경고! 해시 플레이어 사용자의 데이터를 사용하도록 설정한 후에는 사용하지 않도록 설정할 수 없습니다.",
    "message2": "Insights를 사용하도록 설정하려면 다음 옵션 중 하나 이상을 사용하도록 설정해야 합니다.",
    "high": "높음",
    "medium": "보통",
    "low": "낮음",
    "view": "보기",
    "details1": "세부 정보",
    "assign": "지정",
    "label1": "암호 업데이트",
    "label2": "프로필 업데이트",
    "label3": "키 생성",
    "label4": "키 업데이트",
    "label5": "키 삭제",
    "label6": "앱에 지정",
    "label7": "사용자 역할",
    "label8": "프로비저닝",
    "label9": "Api 키",
    "label10": "조직",
    "label11": "응용 프로그램",
    "label12": "범주 순서",
    "label13": "범주",
    "label14": "투어 순서",
    "label15": "Admin 설정 업데이트",
    "label16": "SSO 설정 만들기",
    "label17": "SSO 설정 업데이트",
    "label18": "SSO 설정 삭제",
    "label22": "범주 순서 업데이트",
    "label23": "하위 범주 만들기",
    "label24": "하위 범주 업데이트",
    "label25": "하위 범주 삭제",
    "label26": "투어 순서 업데이트",
    "label27": "과제 목록 만들기",
    "label28": "과제 목록 삭제",
    "label29": "과제 목록 게시",
    "label30": "과제 목록 업데이트",
    "label31": "과제 목록 설정 업데이트",
    "label32": "과제 목록 폴더 만들기",
    "label33": "과제 목록 폴더 업데이트",
    "label34": "과제 목록 폴더 삭제",
    "label35": "과제 목록 콘텐츠 업데이트",
    "label36": "과제 목록 만들기 완료",
    "exportmessage1": "안내서 메타 데이터 - 이름, 응용 프로그램, 게시 상태 등과 함께 파일 내보내기",
    "exportmessage2": "다음과 같은 사용자 세부 정보와 함께 파일 내보내기: 성과 이름, 전자 메일, 역할 등",
    "previewMessage": "비디오 미리 보기가 없습니다.",
    "preview": "미리 보기",
    "detailButtonText": "세부 정보",
    "taskListNote1": "과제 목록을 게시하는 단계:",
    "taskListNote2": "1. 과제 목록 만들기",
    "taskListNote3": "2. 콘텐츠 추가",
    "taskListNote4": "3. 표시 유형 설정 추가",
    "taskListNote5": "4. 게시 상태 변경",
    "serverMessage1": "잘못된/빈 머리글 [AccessToken]",
    "serverMessage2": "[AccessToken] 만료됨",
    "serverMessage3": "죄송합니다. 요청 또는 서버에 문제가 있는 것 같습니다. 오류에 대한 알림을 받았습니다. 최대한 빨리 해결하겠습니다.",
    "serverMessage4": "사용자 전자 메일이 확인되지 않았습니다.",
    "serverMessage5": "유지 관리가 진행 중입니다. support@csod.com을 통해 문의하십시오.",
    "serverMessage6": "잘못된/빈 매개 변수 [토큰]",
    "serverMessage7": "단계를 만들기 위한 최대 한도에 도달했습니다.",
    "serverMessage8": "잘못된/빈 매개 변수 [계정 잠금 해제 토큰]",
    "serverMessage9": "이 AdminAPI에 대한 지원을 사용할 수 없습니다. 이 기능은 사용되지 않습니다. 향후 릴리스에서 제거될 예정입니다.",
    "serverMessage10": "[권한 부여] 머리글 만료됨",
    "serverMessage11": "필요한 매개 변수가 누락되었습니다.",
    "serverMessage12": "잘못된 로그인 자격 증명입니다. 여러 번 실패하는 경우 계정이 잠깁니다.",
    "serverMessage13": "잘못되었거나 만료된 OTP",
    "serverMessage14": "업로드된 파일은 올바른 이미지가 아닙니다. (JPG 및 PNG 파일만 허용됩니다.)",
    "serverMessage15": "세션 만료됨",
    "serverMessage16": "사용자 ID가 잘못되었습니다. 다시 시도하십시오.",
    "serverMessage17": "전자 메일 ID가 잘못되었습니다. 다시 시도하십시오.",
    "serverMessage18": "사용자 역할이 잘못되었습니다. 다시 시도하십시오.",
    "serverMessage19": "잘못된 매개 변수 [task_type]",
    "serverMessage20": "잘못된/빈 매개 변수 [application_id]",
    "serverMessage21": "잘못된 JSON",
    "serverMessage22": "잘못된/빈 매개 변수 [organization_id]",
    "serverMessage23": "잘못된/빈 매개 변수 [parent_id]",
    "serverMessage24": "잘못된/빈 매개 변수 [category_id]",
    "serverMessage25": "잘못된/빈 매개 변수 [from_category_id/to_category_id]",
    "serverMessage26": "수퍼 Admin [organization_id]에 필요한 매개 변수",
    "serverMessage27": "이미지 데이터 콘텐츠는 base64로 인코딩해야 합니다.",
    "serverMessage28": "잘못된 도메인 ID",
    "serverMessage29": "지원되지 않는 파일 유형",
    "serverMessage30": "잘못된 매개 변수 [file_id]",
    "serverMessage31": "잘못된/빈 매개 변수 [tour_id]",
    "serverMessage32": "잘못된/빈 매개 변수 [step_id]",
    "serverMessage33": "잘못된/빈 머리글 [GoogleToken]",
    "serverMessage34": "잘못된 팀 URL",
    "serverMessage35": "잘못된/빈 머리글 [EdcastToken]",
    "serverMessage36": "파일 크기는 5MB 미만이어야 함",
    "serverMessage37": "잘못된 URL",
    "serverMessage38": "암호는 8 ~ 15자여야 하며 하나 이상의 대문자, 소문자 1개, 숫자 값 1개 및 특수 문자 1개를 포함해야 합니다.",
    "serverMessage39": "잘못된 암호",
    "serverMessage40": "잘못된/빈 매개 변수 [country_id]",
    "serverMessage41": "잘못된/빈 매개 변수 [city_id]",
    "serverMessage42": "잘못 된 매개 변수 [언어]",
    "serverMessage43": "인증 ID가 잘못되었습니다.",
    "serverMessage44": "잘못된 매개 변수 [file_id]",
    "serverMessage45": "잘못된 URL ID",
    "serverMessage46": "잘못되었거나 비어 있거나 만료된 머리글 [권한 부여]",
    "serverMessage47": "잘못된/빈 머리글 [AppKey]",
    "serverMessage48": "잘못된/빈 머리글 [RefreshToken]",
    "serverMessage49": "잘못된 역할 ID",
    "serverMessage50": "잘못된 세그먼트 ID",
    "serverMessage51": "잘못된 도메인 이름",
    "serverMessage52": "중복 세그먼트 데이터",
    "serverMessage53": "잘못된 구독 ID",
    "serverMessage54": "잘못된 조직 역할 ID",
    "serverMessage55": "잘못된 과제 목록 ID",
    "serverMessage56": "과제 목록이 게시 취소되었습니다.",
    "serverMessage57": "과제 목록이 게시되었습니다.",
    "serverMessage58": "2044",
    "serverMessage59": "잘못된 폴더 ID",
    "serverMessage60": "2045",
    "serverMessage61": "잘못된 콘텐츠 ID",
    "serverMessage62": "잘못된 매개 변수 [external_user_id]",
    "serverMessage63": "잘못된 단계 언어 데이터입니다. 잘못된 또는 정크 문자에 대한 단계 제목과 설명을 확인하십시오.",
    "serverMessage64": "잘못된 직무 ID",
    "serverMessage65": "잠긴 사용자 계정에서는 작업을 수행할 수 없습니다.",
    "serverMessage66": "잘못된/빈 매개 변수 [세그먼트]",
    "serverMessage67": "잘못된/빈 매개 변수 [group_id]",
    "serverMessage68": "잘못된/빈 매개 변수 [sso_id]",
    "serverMessage69": "잘못된 URL이 제공됨",
    "serverMessage70": "잘못된/빈 매개 변수 [group_id]",
    "serverMessage71": "step_audio 또는 step_audio_text 필드가 필요합니다.",
    "serverMessage72": "데이터 무결성 실패",
    "serverMessage73": "기존 암호와 새 암호가 같을 수 없습니다.",
    "serverMessage74": "작업 필드는 필수입니다.",
    "serverMessage75": "잘못된/빈 매개 변수 [id]",
    "serverMessage76": "Guide가 이미 생성된 경우 범주 내에 하위 범주를  만들 수 없습니다.",
    "serverMessage77": "이미 수퍼 Admin 사용자인 경우 사용자 역할을 변경할 수 없습니다.",
    "serverMessage78": "이 작업은 게시된 안내서에서 허용되지 않습니다.",
    "serverMessage79": "잠긴 안내서는 게시할 수 없습니다.",
    "serverMessage80": "이 작업은 게시된 범주에서 허용되지 않습니다. 게시를 취소하십시오.",
    "serverMessage81": "응용 프로그램은 지정된 조직에 속해야 합니다.",
    "serverMessage82": "이 작업을 수행할 권한이 없습니다.",
    "serverMessage83": "이 하위 범주에 대한 작업은 허용되지 않습니다.",
    "serverMessage84": "하위 범주가 이미 생성된 경우 범주 내에 Guide를  만들 수 없습니다.",
    "serverMessage85": "단계를 만들려면 이 안내서의 잠금을 해제해야 합니다.",
    "serverMessage86": "단계를 편집하려면 이 안내서의 잠금을 해제해야 합니다.",
    "serverMessage87": "단계를 삭제하려면 이 안내서의 잠금을 해제해야 합니다.",
    "serverMessage88": "단계를 복사 및 붙여 넣으려면 이 안내서의 잠금을 해제해야 합니다.",
    "serverMessage89": "이 과제 목록에 대한 작업은 허용되지 않습니다.",
    "serverMessage90": "게시된 과제 목록에서는 작업이 허용되지 않습니다.",
    "serverMessage91": "이 폴더에 대한 작업은 허용되지 않습니다.",
    "serverMessage92": "이미 초안 안내입니다. 새로 만들 수 없습니다.",
    "serverMessage93": "안내서가 최상위 환경에 게시되지 않은 경우 초안 안내선을 만들 수 없습니다.",
    "serverMessage94": "이 안내서에 이미 초안이 있습니다 .이 안내서에 대한 새 초안을 만들 수 없습니다.",
    "serverMessage95": "매개 변수 [envs]의 값이 잘못되었습니다.",
    "serverMessage96": "Guide id의 허용된 제한에 도달했습니다.",
    "serverMessage97": "매개 변수 [키워드]의 값이 잘못되었습니다.",
    "serverMessage98": "태그 단계에는 작업이 허용되지 않습니다.",
    "serverMessage99": "동일한 범주에 Guides를 붙여 넣을 수 없습니다.",
    "serverMessage100": "동일한 투어에 단계를 붙여 넣을 수 없습니다.",
    "serverMessage101": "Guide는 곧 내보낼 수 있습니다. 상태를 확인하려면 계정 메뉴에서 [과제 상태]로 이동하십시오.",
    "serverMessage102": "업로드가 시작되었습니다. 업로드가 완료될 때까지 브라우저를 새로 고치거나 닫지 마십시오. 업로드가 완료되면 가져오기 상태를 확인할 수 있습니다.",
    "serverMessage103": "사용자가 이미 있습니다.",
    "serverMessage104": "전자 메일 ID가 이미 확인됨",
    "serverMessage105": "이 URL이 다른 응용 프로그램 구성과 충돌합니다. 다른 URL을 추가하십시오. 도움이 필요한 경우 support@csod.com에 문의하십시오.",
    "serverMessage106": "범주는 동일한 응용 프로그램에 속해야 합니다.",
    "serverMessage107": "도메인이 이미 있습니다.",
    "serverMessage108": "안내서를 만들기 위한 최대 한도에 도달했습니다.",
    "serverMessage109": "단계 ID는 동일한 안내서에 속해야 합니다.",
    "serverMessage110": "이 조직은 일시 중단되었습니다. 관리자에게 문의하십시오.",
    "serverMessage111": "Guide ID는 동일한 범주에 속해야 합니다.",
    "serverMessage112": "Guide에 지정한 개체와 필드 이름  쌍이 이미 있습니다.",
    "serverMessage113": "사용자 일시 중단됨",
    "serverMessage114": "사용자가 이미 확인됨",
    "serverMessage115": "이 기능은 HPE에서 사용할 수 없습니다.",
    "serverMessage116": "전자 메일 주소로 OTP가 성공적으로 전송되었습니다.",
    "serverMessage117": "역할이 이미 있음",
    "serverMessage118": "국가가 이미 있음",
    "serverMessage119": "구/군/시가 이미 있음",
    "serverMessage120": "지정된 안내서가 내보내기 대기열에 이미 추가되었습니다.",
    "serverMessage121": "범주 게시 취소됨",
    "serverMessage122": "범주 게시됨",
    "serverMessage123": "조직이 확인되지 않았습니다. 도움이 필요한 경우 support@csod.com에 문의하십시오.",
    "serverMessage124": "이 조직에서 JSON 파일을 사용할 수 없습니다.",
    "serverMessage125": "API 자격 증명이 잘못되었거나 없습니다.",
    "serverMessage126": "이 안내서에서 JSON 파일을 사용할 수 없습니다.",
    "serverMessage127": "사용 가능한 단계가 없습니다. 안내서를 게시할 단계를 하나 이상 만드십시오.",
    "serverMessage128": "유효한 직장 전자 메일 주소를 입력하십시오.",
    "serverMessage129": "기본 응용 프로그램입니다.",
    "serverMessage130": "기본 역할.",
    "serverMessage131": "API 키는 SSO 설정에 사용됩니다.",
    "serverMessage132": "이 요청을 처리할 수 없습니다. 이 요청을 사용하려면 연락처 support@csod.com에 문의하십시오.",
    "serverMessage133": "응용 프로그램을 만들기 위한 최대 한도에 도달했습니다.",
    "serverMessage134": "이 기능은 사용할 수 없습니다.",
    "serverMessage135": "제공된 타임 스탬프가 잘못되었습니다. 올바른 시간 스탬프를 입력하십시오.",
    "serverMessage136": "사용자를 만들기 위한 최대 한도에 도달했습니다.",
    "serverMessage137": "잘못된/만료된 구독입니다.",
    "serverMessage138": "제공된 scim ID가 잘못되었습니다.",
    "serverMessage139": "제공된 필터가 지원되지 않습니다.",
    "serverMessage140": "지정한 리소스를 사용할 수 없습니다.",
    "serverMessage141": "잘못된 SCIM 스키마입니다.",
    "serverMessage142": "잘못된 매개 변수.",
    "serverMessage143": "필요한 세그먼트가 누락되었습니다.",
    "serverMessage144": "이 과제는 이미 완료되었습니다.",
    "serverMessage145": "이 사용자 계정은 이미 잠금 해제되어 있습니다.",
    "serverMessage146": "과도한 로그인 실패로 인해 계정이 일시적으로 잠겼습니다. 잠금을 해제하는 단계가 포함된 전자 메일을 전송했습니다. 도움이 필요한 경우 support@csod.com에 문의하십시오.",
    "serverMessage147": "제공된 JSON 데이터가 잘못되었습니다.",
    "serverMessage148": "SSO는 이 조직에 사용할 수 없습니다. 자세한 내용은 연락처 support@csod.com에 문의하십시오.",
    "serverMessage149": "오디오가 생성되지 않았습니다.",
    "serverMessage150": "안내서 목록의 Excel 파일을 곧 사용할 수 있습니다.",
    "serverMessage151": "지정한 조직에 투어가 없습니다.",
    "serverMessage152": "이전 작업이 완료되면 다음 작업을 수행할 수 있습니다.",
    "serverMessage153": "지정된 범주에 투어가 이미 있습니다.",
    "serverMessage154": "지정된 응용 프로그램/범주로 파일을 가져올 수 없습니다.",
    "serverMessage155": "이 조직에서 미디어 파일 테마를 사용할 수 없습니다. 자세한 내용은 support@csod.com에 문의하십시오.",
    "serverMessage156": "Guide를 가져올 대상이  잘못되었습니다.",
    "serverMessage158": "사용자 목록의 Excel 파일을 곧 사용할 수 있습니다.",
    "serverMessage159": "리디렉션 캐시를 사용할 수 없습니다.",
    "serverMessage160": "최대 5개의 안내서를 고정할 수 있습니다.",
    "serverMessage161": "Guide가 이미 고정되어 있습니다.",
    "serverMessage162": "잘못된/빈 매개 변수 [mobile_app_id]",
    "serverMessage163": "이 응용 프로그램에서 JSON 파일을 사용할 수 없습니다.",
    "serverMessage164": "번들/패키지 ID가 이미 있습니다.",
    "serverMessage165": "이 이름의 세그먼트가 이미 있습니다.",
    "serverMessageDefault": "불편을 끼쳐 드려서 죄송합니다. 자세한 내용은 연락처 support@csod.com에 문의하십시오.",
    "errorMessage1": "세션 만료됨!",
    "errorMessage2": "서비스를 사용할 수 없습니다!",
    "usersVariable32": "내부 사용자 추가",
    "usersVariable33": "조직 할당",
    "customizationCentre": "사용자 지정 센터",
    "customizationCentreBreadcrumb": "사용자 지정 센터",
    "customizationCentreTitle": "사용자 지정 센터 |",
    "settings": "설정",
    "brandLogo": "브랜드 로고",
    "brandingWatermark": "브랜딩 워터마크",
    "widgetIcon": "위젯 아이콘",
    "widgetIconPosition": "위젯 아이콘 위치",
    "widgetIconBadge": "위젯 아이콘 배지",
    "widgetIconZIndex": "위젯 z-인덱스",
    "hideWidgetIconOnGuidesPage": "페이지에 안내서가 없으면 위젯 숨기기",
    "widgetIconVisibility": "위젯 아이콘 표시 유형",
    "false": "False",
    "firstSlideImage": "첫 번째 슬라이드 이미지",
    "lastSlideImage": "마지막 슬라이드 이미지",
    "stepBalloonTheme": "단계 풍선 테마",
    "stepTitleText": "단계 제목 텍스트",
    "dummyText": "Lorem Ipsum이 더미 텍스트입니다.",
    "prev": "이전",
    "tooltipBallonInfoTip": "Tooltip 텍스트의 첫 번째 줄은 '단계 제목'으로 처리되고 다음 부분은 '단계 설명'으로 취급됩니다",
    "applyStepBallonTheme": "단계 풍선 테마 적용",
    "stepSelectorBorderWidth": "단계 선택기 테두리 너비",
    "stepSelectorBorderColor": "단계 선택기 테두리 색",
    "enableStepAudio": "단계 오디오 사용",
    "preferredColorsForTitleDescription": "단계 제목 및 설명의 기본 색",
    "reset": "다시 설정",
    "tooltipSelectorBGColor": "Tooltip 선택기 배경 색",
    "notificationHeaderColor": "알림 헤더 색",
    "pushNotificationDelay": "푸시 알림 지연",
    "pushNotificationSnoozeTime": "푸시 알림 스누즈 시간",
    "chatbotIcon": "챗봇 아이콘",
    "chatbotPosition": "챗봇 위치",
    "branding": "브랜딩",
    "widget": "위젯",
    "videogif": "비디오 및 GIF",
    "step": "단계",
    "notification": "알림",
    "chatbot": "챗봇",
    "features": "기능",
    "labels": "레이블",
    "top": "상단",
    "bottom": "아래쪽",
    "left": "왼쪽",
    "right": "오른쪽",
    "chatbotGreetingMsg": "챗봇 인사말 메시지",
    "defaultChatbotGreeting": "안녕하세요! MyGuide를 시작합니다. 무엇을 도와 드릴까요? 저는 봇입니다.",
    "chatbotGreetingEndMsg": "챗봇 대화 메시지 끝",
    "defaultChatbotEnd": "완료되었습니다. 감사합니다!",
    "chatbotErrorMsg": "챗봇 오류 메시지",
    "defaultChatbotError": "죄송합니다. 이해하지 못했어요. 더 구체적으로 지정해 주시겠어요?",
    "miniPlayerHeader": "미니 플레이어 헤더",
    "showAllGuidesTab": "\"모든 Guides\" 탭 표시",
    "defaultGuideActionInPlayer": "플레이어의 기본 안내서 작업",
    "defaultWebPlayerMode": "기본 웹 플레이어 모드",
    "default": "기본값",
    "miniPlayer": "Mini Player",
    "playerPanelDefaultMode": "플레이어 패널 기본 모드",
    "open": "오픈",
    "closed": "마감",
    "configureFeedbackOptn": "피드백 옵션 구성",
    "email": "전자 메일",
    "imageLibrary": "이미지 라이브러리",
    "submit": "전송",
    "classic": "클래식",
    "defaultPagePlayer": "플레이어의 기본 페이지",
    "allGuides": "모든 Guides",
    "bgColor": "배경색",
    "borderColor": "테두리 색",
    "stepTitleColor": "단계 제목 색",
    "stepDescColor": "단계 설명 색",
    "borderRadius": "테두리 반경",
    "prevBTNBG": "이전 버튼 배경",
    "prevBTNText": "이전 버튼 텍스트",
    "borderWidth": "테두리 두께",
    "nextBTNBG": "다음 버튼 배경",
    "nextBTNText": "다음 버튼 텍스트",
    "popupWidth": "팝업 너비",
    "colorPicker": "색상 선택기",
    "brandLogoTooltip": "이미지 크기: 100 x 40 px",
    "brandingWatermarkTooltip": "비디오와 GIF에서 맨 아래에 오버레이하는 브랜딩 요소입니다. 최상의 결과를 위해 투명 및 권장 차원을 사용하십시오.\n이미지 크기: 150 x 150 px",
    "widgetIconBadgeTooltip": "이 배지는 현재 페이지에 안내서가 있는 경우 표시됩니다",
    "firstSlideText": "첫 번째 슬라이드 텍스트",
    "slideTextTooltip": "슬라이드 텍스트는 비디오 및 GIF가 아닌 경우에만 적용됩니다.",
    "lastSlideText": "마지막 슬라이드 텍스트",
    "pushNotifDelayTooltip": "(허용 범위 0 ~ 5초) 이후 푸시 알림 팝업 표시",
    "pushNotifSnoozeTime": "[선택] 시간 동안 마감된 알림 표시 안 함",
    "playerPanelOpenLabelTooltip": "페이지를 로드할 때마다 플레이어 패널 열기",
    "widgetPosi1": "오른쪽 상단",
    "widgetPosi2": "왼쪽 상단",
    "widgetPosi3": "오른쪽 하단",
    "widgetPosi4": "왼쪽 하단",
    "URL": "URL",
    "urlHostname": "URL 호스트 이름",
    "urlPath": "URL 경로",
    "urlParams": "URL 매개 변수",
    "urlHash": "URL 해시",
    "pageTitle": "Page 제목",
    "equals": "같음",
    "notEquals": "같지 않음",
    "contains": "포함",
    "doesNotContain": "포함하지 않음",
    "startsWith": "시작",
    "endsWith": "종료",
    "discardBtnTxt": "취소",
    "addRule": "규칙 추가",
    "createRules": "규칙 만들기",
    "stepRules": "단계 규칙",
    "building": "작성 중",
    "business": "중소기업",
    "education": "교육",
    "fashion": "패션",
    "finance": "재무",
    "food": "음식",
    "healthcare": "의료",
    "miscellenous": "기타",
    "nature": "자연",
    "people": "사람",
    "science": "과학",
    "technology": "기술",
    "transport": "교통",
    "work": "작업",
    "images": "이미지",
    "icons": "아이콘",
    "colors": "색",
    "accessibility": "접근성",
    "animal": "동물",
    "audioAndVideo": "오디오 및 비디오",
    "automotive": "자동차",
    "charity": "자선",
    "chat": "채팅",
    "communication": "커뮤니케이션",
    "solid": "실선",
    "trendy": "트렌디",
    "popular": "인기",
    "random": "임의",
    "resumeGuide": "Guide 다시 시작:",
    "oopsMessage": "오류 메시지:",
    "maintaninence": "유지 관리:",
    "serviceError": "서비스 오류:",
    "internalUsers": "내부 사용자",
    "externalUsers": "외부 사용자",
    "partnerId": "파트너 ID",
    "addPartner": "파트너 추가",
    "partner": "파트너",
    "partnerAdmin": "파트너 Admin",
    "partners": "파트너",
    "editPartner": "파트너 편집",
    "addInternalUser": "내부 사용자 추가",
    "editInternalUser": "내부 사용자 편집",
    "unassign": "할당 취소",
    "orgAssign": "조직 할당",
    "tableHeaderFolder": "폴더",
    "labelAppInsights": "앱 Insights:",
    "version": "버전",
    "decommissionBtn": "해제",
    "deleteBackupBtn": "백업 삭제",
    "reactivateBtn": "재활성화",
    "activeOrgs": "활성 조직",
    "suspendedDeletedOrgs": "일시 중단 / 해제된 조직",
    "suspendedByUser": "사용자에 의해 일시 중단됨:",
    "suspendedByEmail": "전자 메일에 의해 일시 중단됨:",
    "suspendedOn": "일시 중단된 날짜:",
    "deletedByUser": "사용자에 의해 삭제됨:",
    "deletedByEmail": "전자 메일에 의해 삭제됨:",
    "deletedOn": "삭제된 날짜:",
    "decommissionContent": "이 조직을 해제하시겠습니까? 이 작업은 취소할 수 없습니다.",
    "deleteBackupOf": "백업 삭제",
    "deleteBackupContent": "조직 백업을 삭제하시겠습니까? 이 작업은 취소할 수 없습니다.",
    "enableMediaNotification": "미디어 파일 생성 알림 사용",
    "guideName": "Guide 이름",
    "keywords": "키워드",
    "addKeywords": "키워드 추가",
    "manageKeywords": "키워드 관리",
    "published": "게시됨",
    "keywordLoading": "키워드를 업데이트 중입니다. 이 작업은 몇 분 정도 걸립니다. 이동해도 안전합니다.",
    "assignKeywords": "키워드 지정",
    "keywordTooltip": "키워드를 추가하려면 Enter 키 또는 쉼표를 누르십시오.",
    "manageSegments": "세그먼트 관리",
    "guides": "Guides",
    "segmentTitle": "세그먼트 제목",
    "addSegmentTitle": "세그먼트 제목 추가",
    "assignSegments": "세그먼트 지정",
    "segmentLoading": "세그먼트 업데이트 중. 이 작업은 몇 분 정도 걸립니다. 이동해도 안전합니다.",
    "addSegment": "세그먼트 추가",
    "segmentTooltip": "목록에서 하나 이상의 세그먼트 선택",
    "addASegment": "세그먼트 추가",
    "deleteSegment": "세그먼트 삭제",
    "findReplace": "찾기 및 바꾸기",
    "apps": "앱",
    "content": "콘텐츠",
    "text": "텍스트",
    "element": "요소",
    "find": "찾기",
    "replace": "바꾸기",
    "selectElement": "요소 선택",
    "elementPreview": "요소 미리 보기",
    "action": "작업",
    "findReplaceSearchPlaceholder": "무엇을 찾고 계십니까?",
    "noGuideFound": "검색 조건과 일치하는 Guide를 찾을 수 없습니다.",
    "modifySearchCriteria": "검색 기준을 수정하십시오.",
    "maxGuideSelected": "한 번에 최대 50개의 Guide를 선택할 수 있습니다.",
    "import": "가져오기",
    "paste": "붙여넣기",
    "importedGuides": "가져온 Guides",
    "exportedGuides": "내보낸 Guides",
    "transferGuides": "Guides 전송",
    "export": "내보내기",
    "pageTracking": "Page 추적",
    "featureTracking": "기능 추적",
    "enableMirrorApplications": "미러링 응용 프로그램 사용",
    "selfHostedPlayer": "자체 호스팅 플레이어",
    "playerPackages": "플레이어 패키지",
    "createPlayerPackage": "플레이어 패키지 만들기",
    "playerPackageError": "작업이 이미 진행 중이므로 이 작업은 허용되지 않습니다",
    "selfHostedPlayerURL": "자체 호스팅 플레이어 URL",
    "selectApps": "Select Apps",
    "tooltipPageTracking": "This enables tracking of various URL (of domains have been added into applications). Along with various metrics like page load time, time spend on page, etc.",
    "tooltipFeatureTracking": "This enables click tracking based on feature guides created in application. This tracking is silent and transparent to the user",
    "commonLoginMsg": "This is common login for Admin portal & MyGuide Insights",
    "serverMessage166": "A new password can not be the same as the old 5 password.",
    "serverMessage167": "The Title field may only contain alpha characters and spaces",
    "tutorialSelectorBGColor": "Tutorial selector background color",
    "circular": "Circular",
    "rectangular": "Rectangular",
    "width": "Width",
    "height": "Height",
    "stepPopupBtnNext": "Step Popup Button Next",
    "stepPopupBtnPrev": "Step Popup Button Prev",
    "enableChat": "Activate Chat",
    "resumeGuideDefaulText": "Resume Guide",
    "oopsMessageDefaulText": "It looks like you're performing steps that are not part of this Guide. If you no longer wish to follow the Guide, click on Exit Guide",
    "maintenanceDefaulText": "Maintenance in progress. Please contact us via support@csod.com",
    "serviceErrorDefaulText": "Oops! An error occurred. Please try again after some time. You can also email us at support@csod.com for any assistance",
    "invalidImageText": "Selected files should be in png or jpeg format",
    "tutorialSteps": "Tutorial Steps",
    "tutorialModal": "Tutorial 풍선 테마",
    "tutorial": "Tutorial",
    "titleBgColor": "Title Background Color",
    "prevBtnLabel": "Previous Button",
    "nextBtnLabel": "Next Button",
    "color": "Color",
    "background": "Background",
    "fontSize": "Font Size",
    "outerBox": "Outer Box",
    "outerBoxShadow": "Outer Box Shadow",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Blur",
    "spreadRadius": "Spread Radius",
    "inset": "Inset",
    "noApplicationAssigned": "No Application assigned",
    "pasteErrorMessage": "Guides cannot be pasted in same category",
    "pasted": "Pasted Successfully !!",
    "exportMessage": "Guide will be available for export soon. To check the status, go to 'Exported Guides' under the Guides section",
    "importMessage": "Import of guides started. To check the status, go to 'Imported Guides' tab under the Guides section",
    "srNo": "Sr No.",
    "jsonProcessingIncomplete": "This action is not allowed because json processing is not completed",
    "contentCreationNotAllowed": "Content creation is not allowed for Mirror Applications.",
    "tooltipGuideAutomation": "Guide 자동화 기능 사용",
    "ssoUrl": "SSO URL",
    "mediaFileCreationForTooltipGuides": "도구 설명 가이드를 위한 미디어 파일 생성",
    "tooltipMediaFileCreationTooltipGuides": "도구 설명 가이드에 대한 미디어 파일 생성 내보내기를 활성화합니다.",
    "mediaFileCreationForTooltipGuidesToastInfo": "미디어 파일 생성을 위한 도구 설명 가이드 활성화는 '미디어 파일 생성' 설정이 활성화된 경우에만 가능합니다.",
    "importSegmentValues": "Segment 값 가져오기",
    "importSegmentValuesHistory": "Segment 값 기록 가져오기",
    "sendFeedbackOptn": "피드백 보내기 사용",
    "selectSegment": "Segment 선택",
    "chatbotVisibilitySettings": "챗봇 가시성 설정",
    "padding": "내부 여백:",
    "excludeDomains": "도메인 제외",
    "excludeDomainsTooltip": "MyGuide을(를) 사용할 수 없는 도메인 목록을 정의합니다.",
    "firstNameError": "이름에는 특수 문자가 포함되어서는 안 됩니다.",
    "lastNameError": "성에는 특수 문자가 포함되어서는 안 됩니다.",
    "enterNewTag": "새 태그 입력",
    "noteForCustomizationCentre": "아래 기능의 사용 가능 여부는 대상 제품 범주(예: 모바일 앱, 데스크톱 앱 등)의 사용 가능 여부에 따라 달라질 수 있으며 시간이 지남에 따라 변경될 수 있습니다.",
    "enforceSequence": "시퀀스 적용",
    "guideSequenceUpdated": "Guide 시퀀스 업데이트됨",
    "updateSequence": "시퀀스 업데이트",
    "shiftUp": "위로 이동",
    "shiftDown": "아래로 이동",
    "configureHelpLocation": "도움말 위치 구성",
    "creataOrganization": "조직 만들기",
    "createPartner": "파트너 생성",
    "createSegment": "Segment 만들기",
    "oneOrMoreGuidesPresent": "선택한 하나 이상의 가이드가 작업 목록에 이미 존재합니다.",
    "betaFeatures": "베타 기능",
    "keyboardShortcuts": "바로가기 키",
    "keyboardShortcutsTooltip": "바로가기 키를 사용하면 워크플로 가이드를 재생하는 단축키를 만들 수 있습니다. 생성된 바로가기 키의 전체 목록을 보려면 브라우저에서 고객 웹 애플리케이션을 볼 때 ?를 입력하십시오.",
    "tableHeaderModificationDate": "수정 날짜",
    "headerVariable6": "도움말",
    "productType": "제품 유형",
    "guideInventory": "Guide인벤토리",
    "createdBy": "만든 사람",
    "modifiedBy": "수정한 사람",
    "guideId": "Guide ID",
    "botGuide": "봇 Guide",
    "tooltipGuide": "Tooltip Guide",
    "workflowGuide": "Workflow Guide",
    "featureTags": "기능 Tags",
    "tutorialGuide": "Tutorial Guide",
    "guideType": "Guide 유형",
    "unpublish": "게시 취소",
    "environments": "환경",
    "publishGuide": "Guide 게시",
    "importSegment": "Segments 가져오기",
    "importSegmentHistory": "Segments 기록 가져오기",
    "guidePublishInfoMessage": "Guide가 게시됩니다.",
    "guidePublishedSuccessfully": "Guide가 성공적으로 게시됨",
    "guideUnpublishedSuccessfully": "Guide가 성공적으로 게시 취소됨",
    "enableDAP": "Insights에서 DAP 알림 사용",
    "enableDAPTooltip": "사용 설정된 경우 Insights 포털의 제어 센터 아래 전자 메일 알림 기본 설정 > 주별 사용량 통계 및 일일 걸음 수 실패 보고서에 설정된 기본값을 사용합니다. 이 설정을 사용하지 않도록 설정하면 기본 설정이 무시되고 Insights 제어 센터에서 주간 사용량 통계 및 일일 걸음 수 실패 보고서에 대한 알림이 전송되지 않습니다.",
    "showtooltipsWithWorkflowTutorial": "Workflow 및 Tutorial Guides와 함께 Tooltips 표시",
    "showtooltipsWithWorkflowTutorialTooltip": "Workflow 또는 Tutorial 안내서가 표시된 페이지에 Tooltip 안내서를 표시할 수 있도록 설정합니다. 사용하지 않도록 설정된 경우 Tooltip 안내서가 Workflow 또는 Tutorial 안내서와 병렬로 표시되지 않습니다.",
    "exportInventoryToastMessage": "Guide 데이터가 내보내기를 위해 대기열에 추가되었습니다. 상태를 확인하려면 Guides 관리 섹션에서 데이터 내보내기로 이동합니다",
    "autobot": "오토봇",
    "globalFeatureFlags": "전역 기능 플래그",
    "beta": "베타",
    "legacy": "레거시",
    "enterprise": "엔터프라이즈",
    "featureStatusChanged": "전역 기능 플래그가 업데이트되었음",
    "confirmChanges": "변경 사항 확인",
    "featureFlagConfirmationMessage": "이 기능은 모든 조직으로 전파됩니다. 계속하시겠습니까?",
    "generatePasswordOneLowerCase": "하나 이상의 소문자",
    "pageEnteredOutsideRange": "Page 입력한 숫자가 범위를 벗어났습니다",
    "showing": "표시",
    "of": "/",
    "segmentation": "분할",
    "customization": "사용자 지정",
    "administration": "관리",
    "userAttributes": "사용자 속성",
    "addUserAttributesButtonText": "사용자 속성 추가",
    "updateUserAttributesButtonText": "사용자 속성 업데이트",
    "assignUserAttributes": "사용자 속성 값 지정",
    "userAttributeValues": "사용자 속성 값",
    "addUserAttributeValues": "사용자 속성 값 추가",
    "importUserAttributeValues": "사용자 속성 값 가져오기",
    "importUserAttributeValuesHistory": "사용자 속성 값 기록 가져오기",
    "importUserAttributes": "사용자 속성 가져오기",
    "importUserAttributesHistory": "사용자 속성 기록 가져오기",
    "addingUserAttributeButtonText": "사용자 속성을 추가하고 있습니다.",
    "updatingUserAttributeButtonText": "사용자 속성을 업데이트하는 중입니다.",
    "userAttributeCreatedText": "사용자 속성 생성됨",
    "userAttributeUpdatedText": "사용자 속성 업데이트됨",
    "userAttributeDeletedText": "사용자 속성 삭제됨",
    "userAttributeValueCreatedText": "사용자 속성 값 생성됨",
    "userAttributeValueDeletedText": "사용자 속성 값 삭제됨",
    "widgetIconZIndextooltip": "Z-Index는 MyGuide가 어떻게 화면의 요소 위에 표시되는지 제어하는 데 사용됩니다. 경우에 따라 MyGuide 구성 요소가 화면상의 요소 뒤에 숨겨집니다. Z-인덱스 값을 더 높거나 낮게 설정하면 MyGuide 구성 요소를 화면의 요소 위에 표시하는 가능성이 높아질 수 있습니다. (-2147483646에서 2147483646 사이값)",
    "mirrorApplications": "미러 응용 프로그램",
    "enabled": "사용 가능",
    "disabled": "사용 불가능",
    "appIntelligence": "앱 인텔리전스",
    "greaterThan": "보다 큼",
    "lessThan": "적음",
    "userCreationDate": "사용자 생성 날짜",
    "rule_dayOfWeek": "<ul><li>영어 철자의 첫 3자를 사용하여 일을 나타냅니다.</li><br><li>일요일을 주의 시작으로 간주합니다.</li><br><li></i>하이픈<i>  '-'을 사용하여 범위를 지정합니다.</li><br><li>또는 특정 일만 언급합니다.</li><br><li><i>파이프 구분 기호 </i> ' | '은 여러 옵션의 경우에 사용할 수 있습니다</li><br><li>허용되는 값의 예: <br>'월요일 - 금요일', <br>'화요일', <br>'월요일 - 수요일 | 금요일'</li></ul>",
    "rule_fixTime": "<ul><li>24시간 형식으로 시간 입력</li><br><li></i>하이픈<i>  '-'을 사용하여 범위를 지정합니다.</li><br><li>또는 시작 시간만 언급합니다.</li><br><br><li>올바른 값이 지정되지 않은 경우 종료 시간이 일 종료(23:59)로 간주됩니다.</li><br><li><i>파이프 구분 기호 </i> ' | '은 여러 옵션의 경우에 사용할 수 있습니다</li><br><li>허용되는 값의 예: <br>'09:00 - 18:00', <br>'11:00', <br>'09:00-12:00 | 16:00'</li></ul>",
    "rule_dateRange": "<span><b>시작</b> 및 <b>종료</b> 날짜 필드는 필수입니다.</span>",
    "insightsCustomerType": "Insights 고객 유형",
    "myguideCustomers": "Cornerstone Guide 고객",
    "csodInternal": "CSOD 내부",
    "insightsCustomerTypeMessage": "이 설정을 전환하면 Cornerstone Guide Insights에서 이전에 기록된 데이터가 삭제될 수 있습니다.",
    "insightsCustomerTypeTooltip": "이 설정은 유료 Cornerstone Guide 고객과 CSOD 고객 간에 부하 분산을 위해 데이터베이스를 변경합니다",
    "date": "날짜",
    "time": "시간",
    "dayOfWeek": "요일",
    "fixedTime": "하루 중 고정된 시간",
    "dateRange": "날짜 범위",
    "displayFrequency": "표시 빈도",
    "platFormUsageReport": "플랫폼 사용 현황 보고서",
    "platformUsageReportInfo": "모든 조직에 걸쳐 사용자 및 사용량 증가에 대한 인사이트를 제공하는 플랫폼으로서 전체 Cornerstone Guide 데이터를 보여주는 보고서입니다.",
    "totalUsers": "총 사용자 수",
    "newUsers": "신규 사용자",
    "activeUserSessions": "활성 사용자 세션",
    "guidesCreated": "Guides 생성됨",
    "guidesPublished": "Guides 게시됨",
    "guidesPlays": "Guides 재생",
    "tooltipsCreated": "Tooltips 생성됨",
    "tooltipsPublished": "Tooltips 게시됨",
    "tooltipsPlays": "Tooltips 재생",
    "autoProceedStep": "다음 단계로 자동 진행",
    "automationStepDelay": "다음 단계로 자동 진행 지연",
    "userProvisioning": "사용자 프로비저닝",
    "hideBrandLogo": "브랜드 로고 숨기기",
    "enableUserSessionTracking": "사용자 세션 추적 사용",
    "viewActivityDetails": "활동 세부 정보 보기",
    "editingSegment": "segment 편집",
    "selectSegmentUserAttribute": "사용자 속성 선택",
    "selectUserAttributeAndValues": "사용자 속성 및 값 선택",
    "editingSegmentInfoMessage": "Segments을(를) 사용하면 대상 사용자 그룹의 안내서에 대한 액세스를 제어할 수 있습니다. 사용자 속성 및 해당 값은 Single Sign-On과 같은 사용자 통합을 통해 가져오거나 세그멘테이션의 사용자 속성에서 수동으로 관리되는 사용자 프로필을 기반으로 합니다. 또한 규칙을 사용하여 특정 페이지 또는 기간과 같이 분할된 사용자에게 안내서가 표시되는 위치를 제어할 수 있습니다. segment}을(를) 만들기 위해 사용자 특성과 규칙을 함께 사용합니다.",
    "numOfPartnerAdminsAllowed": "허용된 파트너 관리자 수",
    "numOfPartnerAdminExceeded": "현재 파트너의 파트너 관리자 수가 초과되었습니다. support@csod.com을 통해 문의하십시오.",
    "valueShouldBeGreaterThan": "값은 다음보다 크거나 같아야 합니다.",
    "setVisibilitySetting": "게시 후 Guide의 가시성 설정",
    "visibilitySettingsUpdated": "가시성 설정 업데이트됨",
    "auto_generate_keywords": "Guide 크리에이터를 위한 지원",
    "editCredentials": "자격 증명 편집",
    "createSegmentOptionOne": "사용자가 Guides의 일부인 경우 플레이어에서 Segment을(를) 표시합니다.",
    "createSegmentOptionTwo": "사용자가 Guides의 일부인 경우 플레이어에서 Segment을(를) 숨깁니다.",
    "createSegmentOptionThree": "사용자가 Guides의 일부가 아닌 경우 플레이어에서 Segment을(를) 표시합니다.",
    "select": "선택",
    "filters": "필터",
    "labelShowBeaconGuidesOnCurrentPage": "현재 Page에 Beacon Guides 표시",
    "labelGuideSegmentation": "Guide 세그멘테이션",
    "myGuideLogin": "Cornerstone Guide Admin 포털에 로그인하십시오.",
    "loginRemember": "정보 저장",
    "dashboardNoOfOrganisationsTitle": "조직",
    "dashboardNoOfApplicationsTitle": "응용 프로그램",
    "dashboardNoOfUsersTitle": "사용자",
    "dashboardNoOfPartnersTitle": "파트너",
    "segmentsTableHeaderIsRequired": "필수입니까?",
    "headerVariable2": "역할:",
    "headerVariable5": "Insights",
    "firstPage": "첫 번째 Page",
    "middlePage": "가운데 Page",
    "lastPage": "마지막 Page",
    "label19": "응용 프로그램 생성",
    "label20": "응용 프로그램 업데이트",
    "label21": "응용 프로그램 삭제",
    "tooltipBalloonTheme": "Tooltip 풍선 테마",
    "tooltipSelectorBorderColor": "Tooltip 선택기 테두리 색",
    "currentPage": "현재 Page",
    "iconColor": "아이콘 색",
    "confirmDeleteSegment": "삭제하시겠습니까 segment?",
    "dashboardBreadCrumb": "Dashboard",
    "changeIcon": "아이콘 변경",
    "selectFromLibrary": "라이브러리에서 선택",
    "uploadFromComputer": "컴퓨터에서 업로드",
    "assignedAdmins": "지정된 관리자",
    "translation_required": "번역이 필요한가요?",
    "defaultTooltipImage": "기본 Tooltip 이미지",
    "advanceSettings": "고급 설정",
    "generateVideo": "비디오 생성",
    "videoGenerationInProgress": "비디오 생성이 진행 중입니다.",
    "downloadVideos": "동영상 다운로드",
    "videoGenerationStarted": "비디오 생성이 성공적으로 시작되었습니다.",
    "rowsPerPage": "페이지당 행 수",
    "goto": "이동:",
    "role": "역할",
    "twelveHours": "12 시간",
    "twentyFourHours": "24 시간",
    "oneWeek": "1주",
    "twoWeek": "2주",
    "fourWeeks": "4주",
    "textIs": "텍스트임",
    "testIsNot": "텍스트가 아님",
    "textContains": "텍스트에 다음이 포함됨",
    "textMatches": "텍스트 일치",
    "variables": "Variables",
    "deleteApplicationContent": "응용 프로그램을 삭제하시겠습니까?",
    "deleteUser": "사용자 삭제",
    "deleteUserContent": "사용자를 삭제하시겠습니까?",
    "deleteTasklist": "작업 목록 삭제",
    "deleteTasklistContent": "작업 목록을 삭제하시겠습니까?",
    "deleteFolder": "폴더 삭제",
    "deleteFolderContent": "폴더를 삭제하시겠습니까?",
    "deleteKey": "키 삭제",
    "deleteKeyContent": "키를 삭제하시겠습니까?",
    "deleteDomain": "도메인 삭제",
    "deleteDomainContent": "도메인을 삭제하시겠습니까?",
    "deleteRole": "역할 삭제",
    "deleteRoleContent": "역할을 삭제하시겠습니까?",
    "deletePartner": "파트너 삭제",
    "deletePartnerContent": "파트너를 삭제하시겠습니까?",
    "deleteUserAttribute": "사용자 속성 삭제",
    "deleteUserAttributeContent": "사용자 속성을 삭제하시겠습니까?",
    "deleteUserAttributeValue": "사용자 속성 값 삭제",
    "deleteUserAttributeValueContent": "사용자 특성 값을 삭제하시겠습니까?",
    "deleteSSO": "SSO 삭제",
    "deleteSSOContent": "SSO를 삭제하시겠습니까?",
    "filter": "필터",
    "notifThemeProperties": "테마 속성",
    "notifThemePropertiesInfo": "푸시 알림의 스타일 지정 옵션 설정",
    "notifHeaderPlaceholder": "경고 이름입니다!",
    "notifTitle": "알림 제목",
    "notifDescription": "알림에 간단한 설명을 입력하십시오.",
    "primaryAction": "기본 작업",
    "secondaryAction": "보조 작업",
    "generalSettings": "일반 설정",
    "buttons": "버튼",
    "paddings": "패딩",
    "alert": "경고",
    "alertFont": "경고 글꼴",
    "fontType": "글꼴 유형",
    "alertTextColor": "경고 텍스트 색",
    "alertBackground": "경고 배경",
    "heading": "제목",
    "headingFont": "제목 글꼴",
    "headingTextColor": "제목 텍스트 색",
    "descriptionFont": "설명 글꼴",
    "descriptionTextColor": "설명 텍스트 색",
    "buttonFont": "버튼 글꼴",
    "buttonTextColor": "버튼 텍스트 색",
    "buttonBackground": "단추 배경",
    "buttonBorderColor": "단추 테두리 색",
    "actionButton": "작업 버튼",
    "primaryButton": "기본 단추",
    "secondaryButton": "보조 버튼",
    "paddingsSetings": "패딩 설정",
    "notificationPreview": "알림 창 미리 보기입니다.",
    "notificationWidth": "알림 너비",
    "notificationHeight": "알림 높이",
    "accessibilityWarning": "색상을 사용자 지정하면 접근성에 영향을 줄 수 있습니다. 기본 색상 옵션에서 변경하는 경우 접근성 준수 여부를 확인하십시오.",
    "readyToStartYourJourney": "우리와 함께 여행을 시작할 준비가 되셨습니까?",
    "viewOrganizations": "조직 보기",
    "associatedOrganizaitons": "연관 조직",
    "enterEmail": "이메일 입력",
    "enterPassword": "비밀번호 입력",
    "passwordShort": "암호는 8자 이상이어야 합니다.",
    "passwordLong": "암호는 20자 이하여야 합니다.",
    "generateDashboard": "Dashboard 생성",
    "assigned": "지정 완료",
    "unassigned": "미지정",
    "biConnector": "비즈니스 인텔리전스 도구 통합",
    "biConnectorTooltip": "이 기능을 사용하면 Power BI 등과 같은 다양한 비즈니스 인텔리전스 도구(현재 Cornerstone Guide에서 지원됨)를 통해 Insights 데이터에 연결할 수 있습니다.",
    "serverMessage157": "이 조직과 연결된 사용자를 찾을 수 없습니다'.",
    "guidesDoNotExist": "선택한 애플리케이션에서 데이터를 내보내기 위한 가이드를 찾을 수 없습니다.",
    "downloadReport": "보고서 다운로드",
    "reportNotAvailable": "이 조직에서 보고서를 사용할 수 없습니다.",
    "customExtention": "사용자 지정 확장",
    "customExtentions": "사용자 지정 확장",
    "name": "이름",
    "description": "설명",
    "showWidget": "위젯 표시",
    "matches": "플레이어에 대한 허용 도메인",
    "exclude_matches": "플레이어 확장에 대한 도메인 제외",
    "ssoId": "SSO 아이디",
    "clientJsBaseUrl": "내장형 플레이어 기반 URL",
    "host_permissions": "호스트 권한",
    "customer": "고객",
    "desktopCommunication": "데스크톱 통신",
    "iframeInjection": "Iframe 삽입",
    "testme": "TestMe",
    "identifier": "식별자",
    "addExtension": "확장 추가",
    "deleteExtension": "확장 프로그램 삭제",
    "deleteExtensionWarning": "이 확장을 삭제하시겠습니까?",
    "extensionAdded": "확장이 추가되었습니다.",
    "extensionDeleted": "확장이 삭제되었습니다.",
    "extensionUpdate": "확장이 업데이트되었습니다.",
    "guideTranslation": "Guide 번역",
    "translate": "번역하기",
    "nolanguagesAdded": "추가된 언어가 없습니다.",
    "translationInitiated": "언어 번역이 트리거되었습니다.",
    "editGuideLanguage": "Guide 언어 편집",
    "translateStepData": "단계 데이터 번역",
    "disableEditLanguageMsg": "번역할 해당 언어가 없습니다.",
    "translationUpdationSuccessful": "언어 번역이 업데이트되었습니다.",
    "stepNo": "단계 번호.",
    "translationInProgressInfo": "현재 번역 프로세스가 진행 중입니다. 완료되면 다시 시도하십시오.",
    "guideDescription": "Guide 설명"
};
module.exports = GmXt.playerLbls.ko_KR;