export const API_END_POINTS = {
    SIGN_IN: '/user/admin/signin',
    SIGN_IN_NEW: '/user/admin/signin/new',
    HANDOFF_TOKEN: '/accounts/v1/handoff-token/redeem',
    GENERATE_HANDOFF_TOKEN: '/accounts/v1/handoff-token/generate/',
    USER_VERIFY: '/user/verify',
    OTP_RESEND: '/user/otp/resend',

    GET_ACTIVITY: '/organization/activity',
    GET_INDIVIDUAL_ACTIVITY: '/activity/admin_settings',

    GET_ADMIN_SETTINGS: '/organization/setting/admin',
    GET_API_CONNECTOR: '/api_connector',
    CREATE_API_CONNECTOR: '/api_connector',
    GET_CATEGORY: '/category',
    DELETE_CONNECTOR: '/api_connector',
    GET_KEY_LIST: '/apikey',
    GENERATE_SECRET_KEY: '/apikey',
    DELETE_SECRET_KEY: '/apikey',
    GET_APPLICATION_LIST: '/apikey/application/list',
    ASSIGN_APPS_TO_KEYS: '/apikey/assign',
    GET_APPLICATIONS: '/application/list',
    GET_APPLICATION: '/application',
    GET_IMAGE_ID: '/file/image',
    ADD_APPLICATION: '/application',
    EDIT_APPLICATION: '/application',
    DELETE_APPLICATION: '/application',
    GET_DASHBOARD: '/dashboard',
    FORCE_PUSH_UPDATES: '/organization/force_push_updates',
    ORG_CLEAR_SESSION: '/organization/session/clear',
    GET_DOMAIN_LIST: '/domain/list',
    ADD_DOMAIN: '/domain',
    DELETE_DOMAIN: '/domain',
    DOMAIN_SSO_MAP: '/domain/sso/mapping',
    GET_ORGANIZATION_LIST: '/organization/list',
    GET_ORG_LIST_FILTERED: '/organization/list/filter',
    GET_SIGNATURE: '/organization/signature',
    GET_ORGANIZATION_ROLE: '/organization/role',
    GET_ORGANIZATION: '/organization',
    UPDATE_ORGANIZATION: '/organization',
    ADD_ORGANIZATION: '/organization',
    DELETE_ORGANIZATION: '/organization',
    ORGANIZATION_APPROVE: '/organization/approve',
    ORGANIZATION_SUSPEND: '/organization/suspend',
    GET_SUSPENDED_ORGANIZATION: '/organization/deleted/list',
    DELETE_BACKUP: '/organization/backup',
    UPDATE_THEME_SETTING: '/organization/setting/theme',
    GET_ROLE_LIST: '/organization/role/list',
    ADD_ROLE: '/organization/role',
    EDIT_ROLE: '/organization/role',
    DELETE_ROLE: '/organization/role/list',
    APPLICATION_ACCESS: '/application/access',
    GET_SEGMENTS: '/segment/list',
    ADD_SEGMENT: '/segment',
    EDIT_SEGMENT: '/segment',
    DELETE_SEGMENT: '/segment',
    GET_SEGMENT_VALUES: '/segment',
    ADD_SEGMENT_VALUES: '/segment',
    GET_SSO: '/organization/sso',
    UPDATE_SSO: '/organization/sso',
    GET_PROVISION_TOKEN: '/provisioning/token',
    GENERATE_PROVISION_TOKEN: '/provisioning/token',

    GET_USER_TOKEN: '/user/token',
    VERIFY_OTP: '/user/otp/expired',
    PASSWORD_RESET: '/user/password/reset',
    ADD_USER: '/user',
    GET_USER: '/user',
    EDIT_USER: '/user',
    DELETE_USER: '/user',
    USER_ASSIGN_ROLE: '/user/role',
    USER_ASSIGN_ORG_ROLE: '/organization/assign/role',
    USER_ASSIGN_ORG_ROLE_GLOBAL: '/user/organization/mapping',
    SUSPEND_USER: '/user/suspend',
    GET_UPLOAD_FILE: '/file/upload/url',
    IMPORT_USER: '/user/csv/import',
    UPLOAD_FILE_IN_FTP: '/file/upload/ftp',
    GET_TASK_STATUS: '/user/tasks',
    USER_UNLOCK: '/user/unlock',
    USER_OTP_UNLOCK: '/user/otp/unlock',
    UPDATE_PASSWORD: '/user/password',
    CLEAR_SESSION: '/user/session/clear',


    GET_TASKS_LIST: '/task_list/list',
    DELETE_TASKS_LIST: '/task_list',
    UPDATE_TASK_LIST_PUBLISH: '/task_list/publish',

    GET_TASKS: '/task_list',
    ADD_FOLDER: '/task_list/folder',
    UPDATE_FOLDER: '/task_list/folder',
    DELETE_FOLDER: '/task_list/folder',

    GET_TOUR_LIST: '/tour/list',
    SEARCH_TOUR_LIST: '/tour/search',
    GET_CONTENT: '/task_list/content',
    UPDATE_CONTENT: '/task_list/content',

    ADD_TASK_LIST: '/task_list',
    EDIT_TASK_LIST: '/task_list',
    GET_USER_LIST: '/task_list/user/list',
    GET_USER_LIST_BYTYPE: '/task_list/user/list/by_type',
    UPDATE_TASK_LIST_SETTING: '/task_list/setting',

    GET_SSO_LIST: '/organization/sso/list',
    ADD_SSO: '/organization/sso',
    DELETE_SSO: '/organization/sso',

    EXPORT_GUIDE_REPORT: '/tour/list/export',
    EXPORT_USER_DATA: '/user/list/export',
    GET_ORGANIZATION_TASK_STATUS: '/organization/tasks',

    GET_PARTNER_LIST: '/partner/list',
    GET_PARTNER_BY_ID: '/partner',
    ADD_PARTNER: '/partner',
    ADD_INTERNAL_USER: '/user/internal',

    GET_CATEGORY_BY_TOUR_COUNT: '/categories/by_tour_count',
    GET_LIST_USERS: '/user/list',
    PUT_KEYWORDS: '/tour/keywords',

    GET_ALL_SEGMENTS: '/segment/group/list',
    ADD_SEGMENT_GUIDE: '/tour/segments',
    ADD_GROUP: '/segment/group',

    UPDATE_TOUR: '/tour/update',

    GET_CATEGORY_LIST: '/category/list',
    BULK_EXPORT_GUIDES: "/tour/bulk/export",
    BULK_PASTE_GUIDES: "/tour/bulk/copy",
    BULK_IMPORT_GUIDES: "/tour/bulk/import",

    GET_PLAYER_PACKAGES: "/self_hosted_player/pkg/list",
    GENERATE_PLAYER_PACKAGES: "/self_hosted_player/pkg",
    SELF_HOSTED_CONFIG: "/self_hosted_player/config",

    UPDATE_CONTENT_ORDER: '/task_list/content/order',

    IMPORT_SEGMENT_VALUES: '/segment/import/values',
    IMPORT_SEGMENT: '/segment/import',

    GET_GUIDE_INVENTORY_LIST: '/tour/inventory',
    PUBLISH_GUIDE: '/tour/status',
    EXPORT_GUIDE_INVENTORY: '/tour/inventory/export',

    LANGUAGE_SETTING: '/guideme-assests/json/languages.json',

    GET_GLOBAL_FEATURE: '/global_feature',
    POST_GLOBAL_FEATURE: '/global_feature',

    GET_INSIGHT_PRODUCT_USAGE_DATA: '/overview/v1/organization/usage/stats',
    GET_SERVER_PRODUCT_USAGE_DATA: '/dashboard/product_usage_report',
    GET_TOURS: '/tours',
    UPDATE_GUIDE: '/tour/quick/update',

    GET_ORGS_FOR_PARTNER_ADMIN: '/organization/list/by_partner',

    PUT_TASKLIST_LANGUAGE_UPDATE: '/task_list/language/update',
    PUT_FOLDER_TASKLIST_LANGUAGE_UPDATE: '/task_list/folder/language/update',

    GET_ASSIGNED_MG_ADMINS_FOR_ORG: '/user/mg_admin',
    TRIGGER_VIDEO_GENERATION: "/video/generate",

    REGISTER_CLIENT: '/event/v1/client/register',
    GET_CLIENT_SECRET: '/event/v1/client/secret',
    PUSH_EVENT: '/event/v1/push',

    GET_GUIDE_DETAILS: '/tour',
    GET_PLATFORM_USAGE_REPORT: '/download/product/report',

    GET_CUSTOM_EXTENSION: '/player_config/list',
    GET_EXTENSION: '/player_config',

    PUT_GUIDE_TRANSLATION: '/tour/translate',
    UPDATE_LANGUAGE_DATA: '/tour/update_language_data',
    GET_TRANSLATION_STATUS: '/tour/translation/status'
}
