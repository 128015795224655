import { Component, OnInit } from '@angular/core';
import { UserService } from "../common/services/user.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CustomersExtensionService } from '../common/services/customers-extension.service';
import { PaginationService } from '../pagination.service';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, interval } from 'rxjs';
import { ToastModalService } from 'src/app/common/services/toast-modal.service';

import * as moment from 'moment';
declare var bootstrap: any;
declare var $: any;

@Component({
    selector: 'app-customers-extension',
    standalone: false,
    templateUrl: './customers-extension.component.html',
  	styleUrl: './customers-extension.component.scss'
})

export class CustomersExtensionComponent implements OnInit {
    breadcrumb;
	loader = false;
	modalHeader='Delete Extension'
    modalContent

	admin_data = adminLabels;

    configs = [];
    id;
    destroy: Subject<boolean> = new Subject<boolean>();
    destroyed = new Subject();
    private gotoPageSubject = new BehaviorSubject<number>(1);
	tableHeading = [this.admin_data.customer,this.admin_data.identifier, this.admin_data.tableHeaderActions];
    delete_id;
    isDeleteDisabled = false;

    constructor(
        public userService: UserService,
        private router: Router,
		private route: ActivatedRoute,
        private titleService: Title,
		private customersExtensionService: CustomersExtensionService,
		public paginationService: PaginationService,
        private toastService: ToastModalService
    ) {

        this.userService.getUserData();
        // this.paginationService.initializeValues();
        // this.organisationService.getCDNData()
        this.titleService.setTitle(
            this.admin_data.platFormUsageReport + " | Cornerstone Guide"
        );

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd), takeUntil(this.destroy))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(this.admin_data.themes, this.route.url["_value"]);
                let breadCrumb = [
                    {
                        name: this.admin_data.dashboard,
                        route: '/'
                    },
                    {
                        name: this.admin_data.customExtention,
                        route: null,
                    },
                ]
                this.userService.setBreadcrumb(breadCrumb)
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }
    
	ngOnInit() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
		this.getExtensionsList();
    }



	getExtensionsList(){
		this.loader = false;
        this.userService.hide();
        this.destroy.next(true);
        this.destroy.next(false);
        this.configs = [];

        this.customersExtensionService.getCustomExtensions()
            .pipe(takeUntil(this.destroy))
            .subscribe((response) => {
                let count = 0;
                this.configs = response['data']['configs'];
                this.loader = true
            })
	}


    showDeleteModal(id) {
        $("#deleteModal").modal("show");
        this.isDeleteDisabled = false
        this.delete_id = id;
    }

      

    deleteExtension() {
        this.isDeleteDisabled = true;
        this.customersExtensionService.deleteExtension(this.delete_id).subscribe(
          (response) => {
            this.customersExtensionService.showConfirmationModal.next(false);

            this.toastService.showToast = true;
            this.toastService.toastType = "extension_deleted";

            this.toastService.showToastMessage('extension_deleted');

            this.getExtensionsList();
            this.closeModal();

          },
          (error) => {
            this.customersExtensionService.showConfirmationModal.next(false);
            this.isDeleteDisabled = false;
          }
        );
    }

    closeModal() {
        $("#deleteModal").modal("hide");
    }
    

    navigateToAddExtension(id) {
        this.router.navigate(['/extension',id]);
    }

}
