import { Component, OnInit } from '@angular/core';
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";
import { TokenInterceptorInterceptor } from 'src/app/interceptor/token-interceptor.interceptor';
import { ApplicationService } from "../../../common/services/application.service";
import { Application } from "../../../common/services/application.service";
import { UserService } from 'src/app/common/services/user.service';
import { OrganisationService } from 'src/app/common/services/organisation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/common/services/api.service';
import { RoleService } from 'src/app/common/services/role.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApiandservicesService } from 'src/app/common/services/apiandservices.service';
import { ErrorResponseService } from 'src/app/common/services/error-response.service';
import { ToastModalService } from 'src/app/common/services/toast-modal.service';
import { PaginationService } from 'src/app/pagination.service';
import { debounceTime, filter } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
declare var $: any;

@Component({
    selector: 'app-application-list',
    templateUrl: './application-list.component.html',
    styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit {

    assignApp = false;
    admin_data = adminLabels;
    showFilterMenu = false;
    limit = 10;
    offset = 0;
    prevSearch;
    search = '';
    is_searching = false;
    applications: Application[];
    selected_id;
    type = "";
    prevGetApplicationCall;
    lastSearchedValue = "";
    deleteIndex = 0;
    previousPageApps: Application[] = [];
    delete_app_id;
    statusIndex;
    searchInputApp;
    selectedOrg;
    modalHeader: any;
    modalContent: any;
    sortable : any[];
    sortInfo = {};
    private gotoPageSubject = new BehaviorSubject<number>(1);

    deviceList = [
        { value: "", name: this.admin_data.all },
        { value: "guide", name: this.admin_data.web },
        { value: "electron", name: this.admin_data.desktop },
        { value: "android", name: this.admin_data.android },
        { value: "ios", name: this.admin_data.ios },
        { value: "mobile_web", name: this.admin_data.mobileWeb }
    ]

    constructor(
        private interceptor: TokenInterceptorInterceptor,
        public application: ApplicationService,
        public userService: UserService,
        private organisationService: OrganisationService,
        private router: Router,
        private apiService: ApiService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        public apiAndService: ApiandservicesService,
        private toastr: ToastrService,
        private titleService: Title,
        public paginationService: PaginationService,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService
    ) {
        this.route.paramMap.subscribe((params) => {
            if (params.get("key_id")) {
                this.assignApp = true;
                this.setTableHeaders();
                this.selected_id = params.get("key_id");
                this.userService.activeTab = 'api & services';
                this.titleService.setTitle(this.admin_data.apiAndServicesTitle + "Cornerstone Guide");
            } else {
                this.assignApp = false;
                this.selected_id = null;
                this.setTableHeaders();
            }
        })
    }

    ngOnInit() {
        this.roleService.assignRoleList = [];
        this.searchInputApp = document.getElementById("searchInputApp");
        this.is_searching = false;
        if (this.userService.user.role != "superadmin") {
            this.displayAutomationStatus();
            this.getApplications();
        } else if (
            this.userService.user.role == "superadmin" &&
            this.selectedOrg == this.userService.organization_id
        ) {
            this.displayAutomationStatus();
            this.getApplications();
        }

        this.gotoPageSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {

                if (page != this.paginationService.currentPage) {
                    this.offset = this.paginationService.gotoPage(page, this.limit, this.offset);
                    if (this.offset >= 0) {
                        this.getApplications();
                    }
                }
            })
    }

    setTableHeaders() {
        this.applications = null;

        if (this.assignApp) {

            this.application.appTitles = [
                "",
                // this.admin_data.applicationsTableHeaderApplicationName,
                this.admin_data.applicationsTableHeaderApplicationName,
                this.admin_data.tableHeaderType,
                this.admin_data.tableHeaderDescription,
                this.admin_data.applicationsTableHeaderApplicationId,
                this.admin_data.tableHeaderCreationDate,
                this.admin_data.tableHeaderModificationDate,
            ];
            this.application.appDataKeys = [
                "check",
                "title",
                "type",
                "description",
                "mobile_app_id",
                "creation_date",
                "modification_date"
            ];
            this.application.col_widths = ['5%', '20%', '12%', '20%','13%', '15%', '15%'];
        } else {
            this.sortable = [ "creation_date","modification_date"]
            this.application.appTitles = [
                this.admin_data.applicationsTableHeaderThumbnail,
                this.admin_data.applicationsTableHeaderApplicationName,
                this.admin_data.tableHeaderType,
                this.admin_data.applicationsTableHeaderMobileApplicationId,
                this.admin_data.tableHeaderDescription,
                this.admin_data.applicationsTableHeaderApplicationId,
                this.admin_data.applicationsTableHeaderAutomationStatus,
                this.admin_data.tableHeaderCreationDate,
                this.admin_data.tableHeaderModificationDate,
                this.admin_data.tableHeaderActions,
            ];
            this.application.appDataKeys = [
                "image_url",
                "title",
                "type",
                "mobile_app_id",
                "description",
                "mobile_app_id",
                "isAutomationEnabled",
                "creation_date",
                "modification_date",
                "app_action",
            ];
            
            this.application.col_widths = ['10%', '12%', '10%', '18%', '9%', '8%', '11%', '11%', '11%'];
        }
    }

    assignApps() {
        let request = {
            application_ids: JSON.stringify(this.apiAndService.assignApplications),
            key_id: this.selected_id,
        };
        this.apiAndService.assignAppsToKeys(request).subscribe(
            (response) => {
                this.toastService.showToast = true;
                this.toastService.toastType = "assign_app_to_keys";
                this.router.navigate([
                    "/organization",
                    this.userService.organization_id,
                    "get_secret_key",
                ]);
            },
            (error) => {

            }
        );
    }

    displayAutomationStatus() {
        if (this.userService.enableAutomation && !this.assignApp) {
            this.application.appTitles = [this.admin_data.applicationsTableHeaderThumbnail,
            this.admin_data.applicationsTableHeaderApplicationName,
            this.admin_data.tableHeaderType,
            this.admin_data.applicationsTableHeaderMobileApplicationId,
            this.admin_data.tableHeaderDescription,
            this.admin_data.applicationsTableHeaderAutomationStatus,
            this.admin_data.tableHeaderCreationDate,
            this.admin_data.tableHeaderModificationDate,
            this.admin_data.tableHeaderActions
            ];

            this.application.appDataKeys = ['image_url',
                'title',
                'type',
                'mobile_app_id',
                'description', 'isAutomationEnabled',
                'creation_date',
                'modification_date',
                'app_action'];

                this.application.col_widths = ['10%', '20%', '10%', '15%', '19%', '11%', '12%', '12%', '14%'];
        } else if (!this.userService.enableAutomation && !this.assignApp) {
            this.application.appTitles = [this.admin_data.applicationsTableHeaderThumbnail,
            this.admin_data.applicationsTableHeaderApplicationName,
            this.admin_data.tableHeaderType,
            this.admin_data.applicationsTableHeaderMobileApplicationId,
            this.admin_data.tableHeaderDescription,
            this.admin_data.tableHeaderCreationDate,
            this.admin_data.tableHeaderModificationDate,
            this.admin_data.tableHeaderActions
            ];

            this.application.appDataKeys = ['image_url',
                'title',
                'type',
                'mobile_app_id',
                'description',
                'creation_date',
                'modification_date',
                'app_action'
            ];

            this.application.col_widths = ['8%',
                '12%',
                '10%',
                '12%',
                '15%',
                '15%',
                '15%',
                '13%'
            ];
        }
    }
    searchApplicationList() {
        // this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.is_searching = true;
        this.getApplications();
    }
    changeAutomationStatus() {
        let settings = JSON.parse(this.applications[this.statusIndex]['settings']);
        settings['isAutomationEnabled'] = !settings['isAutomationEnabled'];
        this.closeAutomationStatusModal();
        let request = {
            application_id: this.applications[this.statusIndex].application_id,
            title: this.applications[this.statusIndex].title,
            image_id: this.applications[this.statusIndex].image_id,
            description: this.applications[this.statusIndex].description,
            settings: JSON.stringify(settings)
        };
        this.application.editApplication(request).subscribe((response) => {
            this.toastService.showToast = true;
            this.toastService.toastType = "update_automation_status";
            this.getApplications();
        })
    }
    goToAction(event) {
        this.gotoPageSubject.next(Number(event.target.value));
    }

    getAllapplications() {
        let self = this;
        var promise = new Promise((resolve, reject) => {
            let applicationService = this.application;
            let limit = 100;
            let offset = 0;
            let cb = applicationService.getApplications(limit, offset);
            let allApplications = [];
            let fetchRows = false;
            let rows;
            function application() {
                cb.subscribe(
                    (data) => {

                        Array.prototype.push.apply(
                            allApplications,
                            data["data"]["applications"]
                        );
                        offset += 100;
                        cb = applicationService.getApplications(limit, offset);
                        if (!fetchRows) {
                            rows = data["data"]["total_rows"]
                            fetchRows = true;
                        } else {
                            rows = rows % 100;
                        }
                        if (rows / 100 > 1) {
                            application();
                        } else {
                            resolve(allApplications);
                        }
                    },
                    (error) => {
                    }
                );
            }
            application();
        }).then(function (data: []) {
            self.applications = data;
            self.apiAndService
                .getApplicationList(self.selected_id)
                .subscribe((res) => {
                    let apps = res["data"]["application_ids"];

                    self.apiAndService.keyApplications =
                        res["data"]["application_ids"];
                    apps.forEach((data) => {
                        if (self.apiAndService.assignApplications.indexOf(data) == -1) {
                            self.apiAndService.assignApplications.push(data);
                        }
                    });
                });
        });
    }

    getApplications() {
        this.userService.showLoader = true;
        if (this.assignApp) {
            this.getAllapplications();
        }
        else {
            if (this.prevGetApplicationCall) {
                clearTimeout(this.prevGetApplicationCall);
            }
            this.prevGetApplicationCall = setTimeout(() => {
                this.application.getApplications(this.limit, this.offset, this.search, this.type, this.sortInfo['key'], this.sortInfo['order']).subscribe(
                    (response) => {
                        this.processResponse(response);
                        this.application.selectedApplication = null;
                        this.application.isEdit = false;
                        this.previousPageApps = this.applications;
                        this.close();
                        if (this.toastService.showToast) {
                            this.toastService.showToastMessage(this.toastService.toastType);
                        }
                    },
                    (error) => {
                    }
                )
            }, this.is_searching ? 300 : 0);
            // this.prevGetApplicationCall();
        }
    }

    validateInput(event: Event) {
        this.userService.validateInput(event);
        if ((event.target as HTMLInputElement).value != this.lastSearchedValue) {
            this.searchApplicationList();
            this.lastSearchedValue = (event.target as HTMLInputElement).value;
        }
    }
    processResponse(response) {

        this.deleteIndex = 0;

        this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);

        if (response['data']['applications']) {
            let applications = response['data']['applications']
            applications.forEach((app) => {
                app['isAutomationEnabled'] = app['settings'] && JSON.parse(app['settings'])['isAutomationEnabled'] ? true : false;
                if (app['type'] == 'guide') {
                    app['type'] = 'Web';
                }
                else if (app['type'] == 'electron') {
                    app['type'] = 'Desktop';
                }
                else if (app['type'] == 'mobile_web') {
                    app['type'] = 'Mobile Web';
                }

                if (app["mobile_app_id"] == null || app["mobile_app_id"] == "") {
                    app['mobile_app_id'] = '-';
                }
            })
            this.applications = applications;
            this.paginationService.setPaginationStatus(this.applications, this.limit);
        }
    }

    performEdit(application) {
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "application",
            "edit",
            application["application_id"],
        ]);
    }

    close() {
        this.showFilterMenu = false;
        // if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
        //     document.getElementById("filter-dropdown-menu").classList.remove("show");
        //     document.getElementById("filter").classList.remove("show");
        // }
    }
    
    clear(){
        if(this.type !== ""){
            this.type = "";
            this.applyFilter()
        }
    }

    showModal(app_id) {
        let app_name = "";
        this.applications.forEach((elem, i) => {
            if (elem.application_id === app_id) {
                this.deleteIndex = i;
                this.delete_app_id = elem.application_id;
                app_name = elem.title;
            }
        });
        this.modalHeader = this.admin_data.label21
        let str = this.admin_data.deleteApplicationContent
        str = str.split(' ')
        str.splice(str.length-1, 0,"'" + app_name + "'")
        this.modalContent = str.join(' ');
        this.organisationService.showConfirmationModal.next(true);
    }

    showAutomationStatusModal(data) {
        $("#automationStatusModal").modal("show");
        this.applications.forEach((elem, i) => {
            if (elem["application_id"] == data['application_id']) {
                this.statusIndex = i;
            }
        });
    }

    closeAutomationStatusModal() {
        $("#automationStatusModal").modal("hide");
    }

    showRoles(application) {
        this.roleService.assignRoles = true;
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "roles",
            { app_id: application.application_id }
        ]);
    }

    applyFilter() {
        // this.limit = 10;
        this.offset = 0;
        this.paginationService.initializeValues();
        this.is_searching = false;
        this.getApplications();
    }

    appendOffsetAndGetApplications() {
        this.is_searching = false;
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getApplications();
        }
    }

    prependOffsetAndGetApplications() {
        this.is_searching = false;
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getApplications();
        }

    }

    setIndex(ind) {
        this.is_searching = false;
        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getApplications();
        }
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getApplications();
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getApplications();
    }

    deleteApplication(app) {
        this.userService.showLoader = true;
        this.userService.delete_button.disable = true;
        this.userService.delete_button.text = this.admin_data.deletingButtonText;
        this.is_searching = false;
        this.application.deleteApplication(this.delete_app_id).subscribe(
            (response) => {
                this.userService.delete_button.disable=false;
                this.userService.delete_button.text=this.admin_data.deleteButtonText;
                this.organisationService.showConfirmationModal.next(false);
                if (!response['error']) {
                    // this.onClose = true;
                    this.deleteIndex = 0;
                    this.userService.showModal.next(false);
                    // this.pageLimitSet=false;
                    this.toastService.showToast = true;
                    this.toastService.toastType = "delete_application";
                    if (this.applications.length == 1 && this.paginationService.currentPage > 1) {
                        this.prependOffsetAndGetApplications()
                    } else {
                        this.getApplications();
                    }
                } else {
                    this.userService.showModal.next(false);
                    let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                    this.userService.errorMessage = errorResponse;
                    $("#errorModal").modal("show");

                }
        },
            (error) => {
                this.userService.delete_button.disable = false;
                this.userService.delete_button.text = this.admin_data.deleteButtonText;
            }
        );
    }

    setType($event) {
        this.type = $event;
    }

    sortData(columnName){
        
        let offset = this.paginationService.setIndex(1, this.limit, this.offset);
        this.offset = 0;
        if(this.sortInfo){
            if(this.sortInfo['key'] === columnName){
                if(this.sortInfo['order'] === 'asc'){
                    this.sortInfo['order'] = 'desc'
                } else{
                    this.sortInfo['order'] = 'asc'
                }
            } else {
                this.sortInfo['key'] = columnName;
                this.sortInfo['order'] = 'asc'
            }            
            this.getApplications()
        }
    }
}
