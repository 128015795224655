<div>
    <div [ngClass]="className" class="main-drop-div">
        <div>
            <button class="drop-toggle btn flat" [ngClass]="className2" (click)="openCloseDropdown()"
                [disabled]="isDisabled">
                <span>{{placeholder}}</span>
                <img *ngIf="!showDropDown" src="./../../assets/svgs/icon-down-arrow.svg" />
                <img *ngIf="showDropDown" src="./../../assets/svgs/icon-up-arrow.svg" />
            </button>
        </div>

        <div class="drop-show" [ngClass]="position=='up'?'drop-show-up':'drop-show-down'" *ngIf="showDropDown">
            <div class="drop-list-row" *ngIf="searchAllowed">
                <input type="text" class="search-input" [(ngModel)]="searchQuery"
                    (ngModelChange)="onSearchQueryChange()" [placeholder]="this.admin_data.searchPlaceholder" />
            </div>
            <div *ngIf="appList.length>0; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <div class="drop-list-row select-all-row" *ngIf="selectAll && searchQuery.length<1">
                    <div class="custom-check">
                        <div (click)="onSelectAll()">
                            <input type="checkbox" id="select-all" value="" [checked]="getSelectAllStatus()" />
                            <span class="checkmark"></span>
                        </div>
                        <div class="labelText">{{this.admin_data.selectAll}}</div>
                    </div>
                </div>
                <div class="drop-list-row" *ngFor="let app of appList; index as i;">
                    <div class="custom-check">
                        <div (click)="onSelect(getId(app))">
                            <input type="checkbox" id="getId(app)" value="getName(app)" [checked]="app.checked"
                                [disabled]="app.disabled" />
                            <span class="checkmark"></span>
                        </div>
                        <div class="labelText">{{getName(app)}}</div>
                    </div>
                </div>
            </ng-template>
            <ng-template #elseBlock>
                {{this.admin_data.noDataFound}}
            </ng-template>            
        </div>
    </div>
</div>