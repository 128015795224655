import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from "src/environments/environment";
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { GuideData } from 'src/app/guide-inventory/guide-inventory.component'



@Injectable({
    providedIn: 'root'
})
export class GuideInventoryService {
    guideInUse: GuideData
    currentApplicationSelected = [];
    constructor(private apiService: ApiService) { }

    getAllGuides(
        limit: Number,
        offset: Number,
        organization_id: Number,
        application_ids: String[],
        tour_type: String,
        guide_creator: string,
        is_published: string,
        search_text: String,
        sort_by: String,
        sort_order: String,
        date_range_from: String,
        date_range_to: String
    ) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_GUIDE_INVENTORY_LIST +
            "?limit=" + limit +
            "&offset=" + offset +
            "&organization_id=" + organization_id;
        let req = {
            application_ids: application_ids
        }
        // urlPath += "&application_ids=[" + application_ids + "]";
        if (tour_type !== '') urlPath += "&tour_type=" + tour_type;
        if (search_text !== '') urlPath += "&search_text=" + search_text;
        if (date_range_from !== '') urlPath += "&date_range_from=" + date_range_from;
        if (date_range_to !== '') urlPath += "&date_range_to=" + date_range_to;

        if (is_published !== '') urlPath += "&is_published=" + is_published;
        if (sort_by !== '') urlPath += "&sort_by=" + sort_by;
        if (sort_order !== '') urlPath += "&sort_order=" + sort_order;
        return this.apiService.post(urlPath, req);
    }

    publishGuide(request) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.PUBLISH_GUIDE;
        return this.apiService.post(urlPath, request);
    }

    exportGuides(
        organization_id: Number,
        application_ids: String[],
        tour_type: String,
        guide_creator: string,
        is_published: string,
        search_text: String,
        sort_by: String,
        sort_order: String,
        date_range_from: String,
        date_range_to: String
    ) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.EXPORT_GUIDE_INVENTORY +
            "?organization_id=" + organization_id;
        let req = {
            application_ids: application_ids
        }
        if (tour_type !== '') urlPath += "&tour_type=" + tour_type;
        if (search_text !== '') urlPath += "&search_text=" + search_text;
        if (date_range_from !== '') urlPath += "&date_range_from=" + date_range_from;
        if (date_range_to !== '') urlPath += "&date_range_to=" + date_range_to;

        if (is_published !== '') urlPath += "&is_published=" + is_published;
        if (sort_by !== '') urlPath += "&sort_by=" + sort_by;
        if (sort_order !== '') urlPath += "&sort_order=" + sort_order;
        return this.apiService.post(urlPath, req);
    }

    getGuideInfo(tour_id, organization_id) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_TOURS
            + "?organization_id=" + organization_id
            + "&tour_ids=" + encodeURIComponent('[' + tour_id + ']');
        return this.apiService.get(urlPath);
    }

    updateGuide(req) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.UPDATE_GUIDE
        return this.apiService.put(urlPath, req)
    }

    triggerVideoCreation(tour_id) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.TRIGGER_VIDEO_GENERATION +
            "?tour_id=" + tour_id;
        return this.apiService.post(urlPath, null);
    }

    getGuideDetail(category_id, tour_id) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_GUIDE_DETAILS +
            "?tour_id=" + tour_id +
            "&category_id=" + category_id;
        return this.apiService.get(urlPath);
    }

    requestTranslation(tour_id, languages, translate_child) {
        let urlPath = environment.baseUrl + API_END_POINTS.PUT_GUIDE_TRANSLATION
        let req =
        {
            "tour_id": tour_id,
            "languages": languages,
            "translate_child": translate_child
        }
        // “tour_id”: 36632,
        // “translate_child”: true,
        // “languages”:[“hi-IN”]
        return this.apiService.post(urlPath, req)
    }

    updateLanguageData(tour_id, tour_title, tour_description, language, steps) {
        let urlPath = environment.baseUrl + API_END_POINTS.UPDATE_LANGUAGE_DATA
        let req = {
            "tour_id": tour_id,
            "tour_title": tour_title,
            "tour_description": tour_description,
            "language": language,
            "steps": steps
        }
        return this.apiService.put(urlPath, req)
    }

    getTranslationStatus(tour_id: any, language: any) {
        let urlPath =
            environment.baseUrl + API_END_POINTS.GET_TRANSLATION_STATUS +
            "?tour_id=" + tour_id +
            "&language=" + language;
        return this.apiService.get(urlPath);
    }
}