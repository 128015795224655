import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { GuideInventoryService } from 'src/app/common/services/guide-inventory.service';
import { UserService } from 'src/app/common/services/user.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { BreadcrumbComponent } from 'src/app/breadcrumb/breadcrumb.component';
import { OrganisationService } from 'src/app/common/services/organisation.service';
import { ApplicationService } from 'src/app/common/services/application.service';
import { ErrorResponseService } from 'src/app/common/services/error-response.service';
import { ToastModalService } from 'src/app/common/services/toast-modal.service';
declare var $;

@Component({
    selector: 'app-guide-detail',
    templateUrl: './guide-detail.component.html',
    styleUrl: './guide-detail.component.scss'
})
export class GuideDetailComponent {

    guideDetails = {};
    curr_org_id;
    breadcrumb;
    admin_data = adminLabels;
    languageArray = [];
    selectedLanguage = ""
    isTranslationError = false;
    private destroy = new Subject<void>();
    guide_title = "";
    guide_description = "";
    stepData = new Map<number, { title: string, description: string }>();
    defaultLang = "";
    currApplicationData = {};
    editFlag = false;
    editKey;


    constructor(
        public guideInventoryService: GuideInventoryService,
        private route: ActivatedRoute,
        public userService: UserService,
        private router: Router,
        private organisationService: OrganisationService,
        private applicationService: ApplicationService,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService

    ) {
        this.userService.getUserData();

        this.route.paramMap.pipe(takeUntil(this.destroy))
            .subscribe((params) => {
                if (params['params']['org_id']) {
                    if (params['params']['org_id'] !== undefined && params['params']['org_id'] !== '000') {
                        this.curr_org_id = parseInt(params['params']['org_id']);
                        this.userService.organization_id = this.curr_org_id;
                    }
                    if (params['params']['category_id']) {


                        this.guideDetails['category_id'] = params['params']['category_id'];
                        this.guideDetails['tour_id'] = params['params']['guide_id'];
                        this.getGuideDetails();
                    }
                }
            });
        this.userService.activeTab = 'guide_inventory';

    }

    ngOnInit() {
        $('.tooltip').remove();
        this.organisationService.getCDNData();
    }


    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();

    }

    getGuideDetails() {
        this.guideInventoryService.getGuideDetail(this.guideDetails['category_id'], this.guideDetails['tour_id']).subscribe((response) => {
            this.guideDetails = response['data']['tour'];
            this.getApplicationData(this.guideDetails['application_id']);

            this.userService.setBreadcrumb([
                {
                    name: this.admin_data.guideInventory,
                    route: ['/organization', this.curr_org_id, 'guide_explorer']
                },
                {
                    name: this.guideDetails['tour_title'],
                    route: null,
                },
            ]);
            this.breadcrumb = this.userService.breadCrumbs;
            // });
        })
    }

    getTranslationStatus(tour_id, language) {
        this.guideInventoryService.getTranslationStatus(tour_id, language).subscribe((response) => {
            // if (response['error'] == false) {
            if (response['data'] && response['data']['translation_running']) {
                this.isTranslationError = true;
            } else {
                this.isTranslationError = false;
            }
            // } else {
            //     let errorResponse = this.errorResponseService.getErrorResponse(response);
            //     this.userService.errorMessage = errorResponse;
            //     $("#errorModal").modal("show");
            // }
        });
    }

    populateLanguageArray() {
        const organization = JSON.parse(localStorage.getItem('organization'));
        const adminSettings = JSON.parse(organization['admin_settings']);
        const language_settings = adminSettings['language_settings'];
        if (language_settings) {
            if (!language_settings['default']['language'].startsWith("en")) {
                this.defaultLang = language_settings['default']['language'];
                this.languageArray.push({
                    value: language_settings['default']['language'],
                    name: language_settings['default']['name']
                })
            }
            if (language_settings['translations']) {

                language_settings['translations'].forEach((translation) => {
                    this.languageArray.push({
                        value: translation['language'],
                        name: translation['name']
                    })
                })
            }
            const applicationSettings = JSON.parse(this.currApplicationData['settings'])
            if (applicationSettings['enable_application_language_settings']) {
                if (applicationSettings['app_languages'] && applicationSettings['app_languages'].length > 0) {
                    this.languageArray = this.languageArray.filter((language) => {
                        //added default language to dropdown and excluded english language
                        return (!language["value"].startsWith("en") &&
                            (applicationSettings['app_languages'].find(item => language['value'] === item['language']) || language['value'] === language_settings['default']['language']))
                    });
                } else { this.languageArray = [] }
            }
        }
        this.selectedLanguage = this.languageArray.length > 0 ? this.languageArray[0]['value'] : "";
    }

    getApplicationData(app_id) {
        this.applicationService.getApplication(app_id).subscribe((response) => {
            console.log(response);
            this.currApplicationData = response['data']['application']
            this.populateLanguageArray();
            this.getTranslationStatus(this.guideDetails['tour_id'], this.selectedLanguage);
            this.populateData();
        })
    }

    setActions(event) {
        this.selectedLanguage = event;
        this.getTranslationStatus(this.guideDetails['tour_id'], this.selectedLanguage);
        this.populateData();

    }

    getGuideTitle() {
        if (this.selectedLanguage.startsWith("en")) {
            return this.guideDetails['tour_title']
        }
        if (this.guideDetails && this.guideDetails['language_data']) {
            let languageData = JSON.parse(this.guideDetails['language_data']);

            return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['tour_title'] ? languageData[this.selectedLanguage]['tour_title'] : ""
        }
        return "";
    }

    getGuideDescription() {
        if (this.selectedLanguage.startsWith("en")) {
            return this.guideDetails['tour_description']
        }
        if (this.guideDetails && this.guideDetails['language_data']) {
            let languageData = JSON.parse(this.guideDetails['language_data']);

            return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['tour_description'] ? languageData[this.selectedLanguage]['tour_description'] : ""
        }
        return "";

    }

    getStepTitle(step) {
        if (this.selectedLanguage.startsWith("en")) {
            return step['step_title']
        }
        let languageData = JSON.parse(step['language_data']);
        return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['step_title'] ? languageData[this.selectedLanguage]['step_title'] : ""
    }

    getStepDescription(step) {
        if (this.selectedLanguage.startsWith("en")) {
            return step['step_description']
        }
        let languageData = JSON.parse(step['language_data']);
        return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['step_description'] ? languageData[this.selectedLanguage]['step_description'] : ""
    }

    populateData() {
        this.guide_title = this.getGuideTitle();
        this.guide_description = this.getGuideDescription();
        this.stepData = new Map();
        this.guideDetails['steps'].forEach(step => {
            this.stepData.set(step["step_id"], {
                title: this.getStepTitle(step),
                description: this.getStepDescription(step)
            });
        });
    }

    getTinyEditorConfig = () => ({
        selector: 'textarea',
        readonly: false,
        menubar: false,
        plugins: 'lists link image media',
        toolbar: 'undo redo | bold italic | bullist numlist | link image media',
        skin: 'oxide',
        content_css: 'default',
        base_url: '/assets/tinymce',
        suffix: '.min'
    })

    updateLanguageData() {
        let stepData = this.guideDetails["steps"].map(step => {
            // if (this.defaultLang === this.selectedLanguage) {

            let response = {};
            response['step_id'] = step["step_id"]
            response["step_title"] = this.stepData.get(step["step_id"])?.title || "";
            response["step_description"] = this.stepData.get(step["step_id"])?.description || "";
            response['step_audio_text'] = response["step_title"] !== '' && response["step_title"] !== '' ? this.stripHtmlTags(response["step_title"]) + '. ' + this.stripHtmlTags(response["step_description"]) : ""
            // }
            // let language_data = step["language_data"] ? JSON.parse(step["language_data"]) : {};
            // if (this.selectedLanguage in language_data) {
            //     language_data[this.selectedLanguage]["step_title"] = this.stepData.get(step["step_id"]).title;
            //     language_data[this.selectedLanguage]["step_description"] = this.stepData.get(step["step_id"]).description;
            // } else {
            //     language_data[this.selectedLanguage] = {
            //         step_title: this.stepData.get(step["step_id"])?.title || "",
            //         step_description: this.stepData.get(step["step_id"])?.description || ""
            //     };
            // }
            return response;
        });
        this.guideInventoryService.updateLanguageData(this.guideDetails['tour_id'], this.guide_title, this.guide_description, this.selectedLanguage, stepData).subscribe((response) => {
            if (response['error']) {
                let errorResponse = this.errorResponseService.getErrorResponse(response);
                this.userService.errorMessage = errorResponse;
            } else {
                this.toastService.showToast = true;
                this.toastService.toastType = "translation_updation_success";
                this.toastService.showToastMessage(this.toastService.toastType);
                this.editKey = "";
            }
        });
    }

    setEditMode(key) {
        this.editKey = key
    }

    discard() {
        this.router.navigate([
            "/organization",
            this.userService.organization_id,
            "guide_explorer"
        ]);
    }

    stripHtmlTags(htmlString) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || '';
    }
}
