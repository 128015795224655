if (GmXt === undefined) var GmXt = {};

GmXt.playerLbls = {};

GmXt.playerLbls.en_US = {
    //common
    myGuideTitle : "MyGuide",
    addButtonText : "Add",
    addingButtonText : "Adding...",
    cancelButtonText : "Cancel",
    okButtonText : "OK",
    updateButtonText : "Update",
    updatingButtonText : "Updating...",
    deleteButtonText : "Delete",
    deletingButtonText : "Deleting...",
    uploadButtonText : "Upload",
    uploadingButtonText : "Uploading",
    saveButtonText : "Save",
    savingButtonText : "Saving...",
    saveSettingsButtonText : "Save Settings",
    noDataFoundText : "No Data Found!",
    generateButtonText : "Generate",
    generatingButtonText : "Generating...",
    actionDeclined : "Action Declined!",
    searchPlaceholder : "Search",
    searchNameIdPlaceholder : "Search First/Last Name, Email ID",
    selectDatesPlaceholder : "Select dates",
    enterIDPAttributeNamePlaceholder : "Enter IDP attribute name",
    domainFormatPlaceholder : "name@domain.com",
    error : "Error",
    yes : "Yes",
    no : "No",
    all :"All",
    web : "Web",
    desktop : "Desktop",
    android : "Android",
    ios : "IOS",
    mobileWeb : "Mobile Web",
    user : "User",
    currentAdminPassword : "Current Admin Password",
    note : "Note:",
    domainMessage : "SSO IDP is bound to an organisation by default. So default SSO URL will be triggered if user visits any web domain and session is not found. In this section, you can bind SSO URL to particular domain, so that SSO URL only triggers on associated domain. This way an organisation can have multiple SSO IDPs on multiple domains.",
    refresh : "Refresh",
    iconWorks : "icon works!",
    pageNotWorks : "page-not-found works!",
    valid : "Valid.",
    select : "Select",
    selectApps : "Select Apps",
    selectAll : "Select All",
    guideTitle : "Guide Title",
    copy : "Copy",
    copied : "Copied !!",
    on : "ON",
    off : "OFF",
    publish : "Publish",
    unpublished : "Unpublished",
    locked : "Locked",
    unlocked : "Unlocked",
    superAdmin : "Super Admin",
    download : "Download",
    noDataFound : "No Data Found",
    and : "And",

    //commonGuideRoles

    guideRoleAdministrator : "Org Admin",
    guideRoleCreator : "Creator",
    guideRolePlayer : "Player",
    guideRoleAppAdmin : "App Admin",


    //commonTableHeaders
    tableHeaderStatus : "Status",
    tableHeaderFolder : "Folder",
    tableHeaderProgress : "Progress",
    tableHeaderTitle : "Title",
    tableHeaderType : "Type",
    tableHeaderCreationDate : "Creation Date",
    tableHeaderModificationDate : "Modification Date",
    tableHeaderActions : "Actions",
    tableHeaderMarkAll : "Mark All",
    tableHeaderDescription : "Description",

    //commonHTML
    pleaseSelect : "Please Select",
    filters : "Filters",
    close : "Close",
    clear : "Clear",
    apply : "Apply",
    patternInvalid : "Pattern is invalid",
    isRequired : " is required",




    //formDataHTML
    //passwords
    confirmNewPassword : "Confirm New Password",
    newPassword : "New Password",
    currentPassword : "Current Password",
    //addApplication
    addApplicationAddBreadcrumb : "Add Application",
    addApplicationEditBreadcrumb : "Edit Application",
    addApplicationUploadThumbnail : "Upload Application's Thumbnail Image",
    addApplicationGuideAutomation : "Guide Automation",
    addApplicationApplicationLevelTranslationLanguages : "Application level translation languages",
    addApplicationEnableApplicationLanguageSettings : "Enable application level language settings",
    addApplicationPackageId : "Package ID",
    addApplicationBundleId : "Bundle ID",
    addApplicationMaxApplicationLimit : "New Application can not be added as the organization has exceeded the maximum application limit.",
    addApplicationIdExists : "New Application can not be added as the package id/ bundle id already exists.",
    //addFolder
    addFolderName : "Folder Name",
    //addOrganisation
    addOrganisationName : "Organization Name",
    addOrganisationType : "Organization type",
    //addRoles
    //addUser
    addUserAvtar : "Avtar",
    addUserFirstName : "First Name",
    addUserLastName : "Last Name",
    addUserEmailId : "Email ID",
    addUserOrganisationRole : "App Access Role",
    addUserLockedStatus : "Locked Status",
    addUserRole : "User Role",
    addUserUUName : "UUName",
    //adminSettings
    labelDoItForMe : "DoItForMe",
    labelMyChat : "MyChat",
    labelTeachMe : "TeachMe",
    labelTutorialGuides : "Tutorial Guides",
    labelGuideTranslation : "Guide Translation",
    labelShowBeaconGuidesOnCurrentPage : "Show Beacon Guides On Current Page",
    labelGuideSegmentation : "Guide Segmentation",
    labelShowCurrentPageGuidesOnly : "Show Current Page Guides Only",
    labelTooltipEnhancements : "Tooltip Enhancements",
    labelUserInformationTracking : "User Information Tracking",
    labelSSO : "SSO",
    labelMediaFileCreation : "Media File Creation",
    labelYoutubeSettings : "Youtube Settings",
    labelEnableOrgAndGuidesJSONCaching : "Enable Org & Guides JSON Caching",
    labelGuideAutomation : "Guide Automation",
    labelAPIConnector : "API Connector",
    labelLoadContextChangeOnPageClick : "Load context change on page click",
    labelTaskList : "Task List",
    labelMediaFileTheme : "Media File Themes",
    labelDisplayGuidePublishingDate : "Display Guide Publishing Date",
    labelMaintenanceMode : "Maintenance Mode",
    labelEnableGuidemeAsSecondaryOption : "Enable Guideme as Secondary option",
    labelHashPlayerUsersData : "Hash player user's data",
    labelAllowOnPremiseDeployment : "Allow On Premise Deployment",
    labelFTPEndpointToSaveContent : "FTP endpoint to save content",
    labelEnableInsights : "Enable Insights",
    labelDisplayAcceptCookiePopup : "Display Accept Cookie Popup",
    labelSetRefreshTokensExpiryTime : "Set Refresh Token's Expiry Time (in seconds)",
    labelGuideInsights : "Guide Insights",
    labelRealTimeInsights : "Real Time Insights",
    labelAppInsights : "App Insights : ",
    labelInsightsPriority : "Insights Priority",
    labelMaxGuideLimit : "Max Guide Limit",
    labelMaxApplicationLimit : "Max Application Limit",
    labelMaxStepLimit : "Max Step Limit",
    labelMaxAdminUsers : "Max Admin Users",
    labelMaxCreatorUsers : "Max Creator Users",
    labelMaxPlayerUsers : "Max Player Users",
    //adminSettingsTooltips
    tooltipDoItForMe : "Allows guides to be played in Do it for me (Automated mode)",
    tooltipMyChat : "Enables Virtual Chat Assistant",
    tooltipTeachMe : "Enables guide playback in simulation of host application",
    tooltipTutorialGuides : "Enables Tutorial guide feature",
    tooltipGuideTranslation : "Enables guides to be translated to one or more languages supported by Cornerstone Guide",
    tooltipShowBeaconGuidesOnCurrentPage : "Enables guides having beacon configured to show up on the current page. By default, beacons are rendered on the page and corresponding guides are not visible on the current page.",
    tooltipGuideSegmentation : "Manage the guides that a user can and can not access based upon user attribute data",
    tooltipShowCurrentPageGuidesOnly : "Hides the All Guides tab and folder hierarchy that includes guides not available on the current page.",
    tooltipTooltipEnhancements : "Tooltip Enhancements",
    tooltipUserInformationTracking : "Enables more precise Insights tracking by tracking unique user data",
    tooltipSSO : "Enables Single Sign-on integration with client IDP",
    tooltipMediaFileCreation: "Enables automatic export of Cornerstone Guide content to various formats like .pdf, .docx, etc.",
    tooltipYoutubeSettings : "Enables YouTube connector to publish guides to YouTube.",
    tooltipEnableOrgAndGuidesJSONCaching : "Enables org JSON to include all guides and all steps. Recommended only for orgs having less than 100 published guides. It should be disabled if the total size of org JSON is more than 4mb.",
    tooltipGuideAutomation : " Enables Guide Automation Feature",
    tooltipAPIConnector : "Enables third party API connectors to import data. Supported connectors are Zendesk etc.",
    tooltipLoadContextChangeOnPageClick : "Enables user to restrict rule search on page click",
    tooltipTaskList : "Enables creation of guide based task lists to be assigned to user as Tasks",
    tooltipMediaFileTheme : "Enables selection and customization of media file themes",
    tooltipDisplayGuidePublishingDate : "Enables display of guide publishing date on downloadable formats like video, pdf etc.",
    tooltipMaintenanceMode : "Maintenance Mode",
    tooltipEnableGuidemeAsSecondaryOption : "Enable guideme as secondary mode for guides created with showme as default.",
    tooltipHashPlayerUsersData : "Hash player user's data",
    tooltipAllowOnPremiseDeployment : "Allows the deployment and operate Cornerstone Guide as a Local/Intranet setup",
    tooltipFTPEndpointToSaveContent : "Enter a valid FTP address starting with ftp : //. eg : ftp : //1.2.3.4 : 21 or ftp : //abc.example.com : 21",
    tooltipEnableInsights : "Enabled by default. If disabled it stops all kinds of insights for the organization",
    tooltipDisplayAcceptCookiePopup : "Enables display of accept cookie popup",
    tooltipSetRefreshTokensExpiryTime : "Set expiry time for refresh token",
    tooltipGuideInsights : "Enables guide insights of user interaction with MyGuide guides, tooltips, beacons etc. Enabled by default",
    tooltipRealTimeInsights : "Enabling this feature will provide real-time data reflection for certain sections of insights portal",
    tooltipAppInsights : " Enables application insights of click tracking, page tracking etc. This works in the background without users interacting with any guides, beacons, tooltips etc",
    tooltipInsightsPriority : "Set Insights Priority of Cornerstone Guide",
    tooltipPageTracking: "This enables tracking of various URL (of domains have been added into applications). Along with various metrics like page load time, time spend on page, etc.",
    tooltipFeatureTracking: "This enables click tracking based on feature guides created in application. This tracking is silent and transparent to the user",
    //adminSettingsHTML
    languageTranslationsSettings : "Language Translation Rules",
    englishDefault : "1. Language translation is available only when English (any variant) is selected as the default language.",
    languageEdited : "2. If the default language is edited, you need to reselect the previously added languages from the “Choose Language” option.",
    defaultLanguage : "Default Language",
    defaultVoice : "Default Voice",
    moreLanguage : "More Language",
    guideOrWorkflowAutomation : "Guide Automation or Workflow Automation",
    cannotBeDisabled : "This option cannot be disabled once it is enabled.",
    okToApply : "To apply this setting, please click OK button.",
    addToConnector : "Add Connector",
    doYouWantToDelete : "Do you want to delete?",



    //segmentList
    //segment
    segmentData : "Data:",
    segmentKeyName : "Key Name:",
    //sso
    ssoDescription : "SSO Description",
    ssoIssuerURL : "Issuer URL",
    ssoUrl: "SSO URL",
    ssoCertificate : "x.509 certificate",
    ssoAPIKey : "API Key",
    ssoAPISecret : "API Secret",
    ssoNameIdEncrypted : "Do you want NameId Encrypted?",
    ssoMetaDataSigned : "Do you want Metadata Signed?",
    ssoResponseSigned : "Do you want Response Signed?",
    ssoAssertionSigned : "Do you want Assertion Signed?",
    ssoSignatureAlgorithm : "Signature Algorithm",
    ssoDigestAlgorithm : "Digest Algorithm",
    ssoMessageSigned : "Do you want Message Signed?",
    ssoWish : "Do you wish to use the same certificate for NameId Encryption?",
    ssoEncryptCertificate : "Encryption x509 Certificate",
    ssoAllowAplicationSingleLayout : "Allow application to initiate Single Logout?",
    ssoAuthRequestSigned : "Do you want Authn Request Signed?",
    ssoConfirmationMessage : "SSO confirmation Message",
    ssoButtonMessage : "SSO Button Message",
    ssoSingleLogoutURL : "Single Logout Url",
    //ssoList
    ssoListName : "Name:",
    //userProvisioning
    userProvisioningMethod : "User Provisioning Method",
    //apiConnector
    apiConnectorToken : "Token",
    apiConnectorEmailAddress : "Email Address",
    apiConnectorAPIEndpoint : "API Endpoint",
    apiConnectorFolder : "Folder",
    //organisationForm
    organisationFormDualAuthentication : "Dual Authentication",
    organisationFormUploadOrganisationThumbnail : "Upload Organization Thumbnail Image",
    organisationFormUploadFirstSlide : "Upload First Slide Image",
    organisationFormUploadFirstSlideText : "First Slide Text",
    organisationFormUploadLastSlide : "Upload Last Slide Image",
    organisationFormUploadLastSlideText : "Last Slide Text",
    organisationFormCopyrightText : "Copyright Text",


    //toast messages
    applicationCreatedText : "Application Created",
    applicationUpdatedText : "Application Updated",
    applicationDeletedText : "Application Deleted",
    applicationAssignedText : "Applications Assigned",
    automationStatusUpdatedText : "Automation Status Updated",
    roleCreatedText : "Role Created",
    roleUpdatedText : "Role updated",
    roleDeletedText : "Role deleted",
    roleAssignedText : "Role assigned",
    roleAssignedToApplicationText : "Role assigned to application",
    organisationRoleAssignedText : "Organization Role assigned",
    credentialsCreatedText : "Credentials Saved",
    credentialsDeletedText : "Credentials Deleted",
    segmentCreatedText : "Segment Created",
    segmentUpdatedText : "Segment Updated",
    segmentDeletedText : "Segment Deleted",
    segmentValueCreatedText : "Segment Value Created",
    segmentValueDeletedText : "Segment Value Deleted",
    userCreatedText : "User Created",
    userUpdatedText : "User Updated",
    userDeletedText : "User Deleted",
    domainCreatedText : "Domain Created",
    domainDeletedText : "Domain Deleted",
    updatedDomainSsoMapping : "Updated Domain SSO Mapping",
    apiConnectorAddedText : "API Connector Added",
    apiConnectorDeletedText : "API Connector Deleted",
    taskListCreatedText : "Task List Created",
    taskListUpdatedText : "Task List updated",
    taskListDeletedText : "Task Deleted",
    visibilitySettingsUpdatedText : "The visibility settings have been updated",
    adminSettingsUpdatedText : "Admin Settings Updated",
    importedText : "Imported",
    userUnlockedText : "User unlocked",
    userStatusChangedText : "User status changed",
    sessionClearedforUserText : "Session Cleared for user ",
    passwordChangedText : "Password changed",
    organisationUpdatedText : "Organization Updated",
    organisationActiveStatusChangedText : "Organization Active status changed",
    organisationVerifiedStatusChangedText : "Organization Verified status changed",
    organisationCreatedText : "Organization Added",
    organisationDeletedText : "Organization Deleted",
    ssoCreatedText : "SSO Added",
    ssoDeletedText : "SSO Deleted",
    changesReflectedText : "The changes will reflect on the players portal in a few minutes",
    sessionClearedForOrganisationText : "Session cleared for the organisation succesfully!",
    ssoConfigurationUpdatedText : "SSO Configuration Updated",
    instantUpdateText : "Instant update successful",
    sessionClearedText : "Session cleared",
    successfullyText : "Successfully!",
    successText : "Success!",


    //loginComponent
    login : "Login",
    myGuideLogin : "Login to Cornerstone Guide Admin portal",
    loginInvalidCredentials : "Invalid login credentials. Please try again",
    loginAccessForbidden : "Access forbidden",
    loginPassword : "Password",
    loginFillField : "Please fill out this field.",
    loginRemember : "Remember",
    loginFieldMandatory : "This field is mandatory.",
    loginContactEdcast : "Need any help, please email us at",
    loginCopyright : "Copyright ©",
    support : "support@csod.com",
    commonLoginMsg: "This is common login for Admin portal & Cornerstone Guide Insights",
    //forgotPasswordComponent
    forgotPassword : "Forgot Password",
    resetPassword : "Reset Password",
    forgotPasswordBackToLogin : "Back to Login",
    forgotPasswordResendLink : "Resend Link",
    forgotPasswordInstructions : " - If this is a valid Cornerstone Guide ID, you should receive an email containing instructions on how to create a new password.",
    forgotPasswordLinkSent : "Password reset Link has been sent",
    forgotPasswordDidntRecieveMail : "Didn't receive the email?",
    forgotPasswordCheckSpam : "Check spam or bulk folders for a message coming from \n ",
    //generatePasswordComponent

    generatePasswordSuccesfullyReset : "Password has been successfully reset",
    generatePasswordLinkExpired : "Generate password link expired or invalid OTP",
    generatePasswordMustContain : "New Password must contain:",
    generatePasswordCharacterLimit : "Between 8-20 characters",
    generatePasswordOneNumber : "At least one number",
    generatePasswordOneUpperCase: "At least one uppercase letter",
    generatePasswordOneLowerCase: "At least one lowercase letter",
    generatePasswordOneSpecialCharacter : "At least one special character (eg. @,*,!)",
    generatePasswordShouldMatch : "New Password and Confirm New Password fields should match",

    //organisationComponent
    //organisationHTML
    organisationSelectText : "Select Organisation",
    organisationAddText : "Add Organisation",
    organisationEditText : "Edit Organisation",
    organisationActiveText : "Active",
    organisationVerifiedText : "Verified",
    //organisationService
    organisationPaid : "Paid",
    organisationTrial : "Trial",
    organisationHeading1 : "Video & GIF Settings",
    organisationTooltip1 : "The following settings are used to customize the structure of video and GIF formats of guides generated for the organization",

    //dashboardComponent
    dashboardTitle : "Dashboard | Cornerstone Guide ",
    //dashboardHTML
    dashboardNoOfOrganisationsTitle : "Organisation(s)",
    dashboardNoOfApplicationsTitle : "Application(s)",
    dashboardNoOfUsersTitle : "User(s)",
    dashboardNoOfPartnersTitle : "Partner(s)",
    //dashboardService
    dashboardTriggerInstantUpdateButton : "Trigger instant update",
    dashboardClearSessionButton : "Clear Session",
    dashboardTriggerInstantUpdateButtonTooltip : "Player portals are updated every hour. Click here to trigger an instant update. The changes will reflect in 2 to 10 minutes.",
    dashboardClearSessionButtonTooltip : "Clear session for organisation.",
    dashboardUserAction : "User Action",
    dashboardUserKey : "User Key",
    dashboardRegisteredDate : "Registered Date",






    //apiAndServicesComponent
    apiAndServicesTitle : "API & Services | ",
    apiAndServicesBreadCrumbTitle : "API & Services",
    //apiAndServicesHTML
    apiAndServicesGenrateCrendentialsButton : "Generate Credentials",
    //apiAndServicesService
    apiAndServicesTableHeaderKeyName : "Key Name",
    apiAndServicesTableHeaderKey : "Key",
    apiAndServicesTableHeaderSecret : "Secret",
    apiAndServicesTableHeaderAnonymousAccess : "Anonymous Access",
    apiAndServicesTableHeaderJITProvisioning : "JIT Provisioning",

    //applicationsComponent
    applicationsTitle : "Applications | ",
    applicationsAssignTitle : "Assign Applications",
    applicationsBreadCrumbTitle : "Applications",
    //applicationsService
    applicationsTableHeaderThumbnail : "Thumbnail",
    applicationsTableHeaderApplicationName : "Application Name",
    applicationsTableHeaderApplicationId : "App ID",
    applicationsTableHeaderMobileApplicationId : "Mobile Application Id",
    applicationsTableHeaderAutomationStatus : "Automation Status",
    //applicationsHTML
    applicationsUpdateAutomationStatus : "Update Automation Status",
    changeAutomationStatus : "Do you want to change the automation status of this application?",


    //roles
    //rolesService
    rolesAddRoleButtonText : "Add Role",
    rolesAddingRoleButtonText : "Adding Role...",
    rolesUpdateRoleButtonText : "Update Role",
    rolesUpdatingRoleButtonText : "Updating Role...",

    //users
    usersBreadCrumbTitle : "Users",
    //usersService



    //segments
    //segmentsService
    segmentsTableHeaderKeyName : "Key Name",
    segmentsTableHeaderIsRequired : "Is Required?",
    segmentsListTableHeaderValue : "Value",
    segmentsListTableHeaderIsDefault : "Is Default?",
    segmentsAddSegmentButtonText : "Add Segment",
    segmentsUpdateSegmentButtonText : "Update Segment",
    segmentsAddValueButtonText : "Add Value",
    segmentsAddingValueButtonText:  "Adding Value...",
    segmentsAddingSegmentButtonText : "Adding Segment...",
    segmentsUpdatingSegmentButtonText : "Updating Segment..",
    addSegmentsValue : "Add Segment Values",


    //domains
    //domainsService
    domainsAddDomainDomainName : "Domain Name",
    domainsAddDomainSSOIDP : "SSO IDP",
    domainsAddDomainButtonText : "Add Domain",
    domainsAddingDomainButtonText : "Adding Domain...",


    //apiConnector
    //apiConnectorService
    apiConnectorTableHeaderAPIName : "API Name",
    apiConnectorTableHeaderSyncCompleted : "Sync Completed",


    //ssoList
    //ssoListService
    ssoListTableHeaderSSOName : "SSO Name",
    ssoListDownloadMetadata : "Download Metadata",
    ssoListIdentityProviders : "Identity Providers",
    ssoListDomainMappings : "Domain mappings",
    addSSOIDP : "Add SSO IDP",
    addSSO : "Add SSO",
    updateSSO : "UpdateSSO",


    //adminSettings
    //adminSettingsService
    //adminSettingHTML
    featureSettings : "Feature Settings",
    insightsSettings : "Insights Settings",
    limitSettings : "Limit Settings",
    languageTranslations : "Language Translation",
    chooseImage : "Choose Image",
    adminSettingsWarning : "Warning! Enabling maintenance mode will disable this organization",
    adminSettingsHashPlayersData : "Hash player user's data",
    adminSettingsEnableSure : "Are you sure you want to enable",
    adminSettingsYouCanEither : "You can enable either",
    adminSettingsGuideAutomation : "Guide Automation",
    adminSettingsWorkflowAutomation : "Workflow Automation",
    adminSettingsUnselectProceed : "Unselect one of these to proceed",


    //themes
    //themesService


    //activity
    //activityService
    activityTableHeaderActivity : "Activity",
    activityTableHeaderActivityEmailId : "Activity Email ID",
    activityTableHeaderActivityIPAddress : "IP Address",
    //activityHTML
    activityAdmin : "Admin Activity",
    activityLogin : "Login Activity",
    activityType : "Activity Type",
    activityAction : "Action",
    activityDetails : "Activity Details",
    activityTableHeaderActivityIPAddresss : "IP Address",




    //userProvisioning
    userProvisioningTitle : "User Provisioning | ",
    userProvisioningBreadCrumbTitle : "User Provisioning",
    userProvisioningProvideKey : "Key Name",
    //userProvisioningService


    //exportData
    //exportDataService
    exportDataExportGuideButtonText : "Export Guide Data",
    exportDataExportUserButtonText : "Export User Data",
    exportDataTableHeaderExportType : "Export Type",
    exportDataTableHeaderExportedBy : "Exported By",
    //exportHTML
    guideList : "Guide List",
    userList : "User List",
    selectApplications : "Select Applications",
    selectApplicationsMessage : "Please select application to export guide data",
    exportDetails : "Export Details",



    //taskList
    //taskListService
    taskListAddTaskButtonText : "Add Task",
    taskListAddingTaskButtonText : "Adding Task...",
    taskListUpdateTaskButtonText : "Update Task",
    taskListUpdatingTaskButtonText : "Updating Task...",
    taskListTableHeaderApplication : "Application",
    taskListTableHeaderLeaderboard : "Leaderboard",
    //taskHTML
    addGroup : "Add Group",
    removeGroup : "Remove Group",
    updateTaskList : "Update Task List",
    addTaskList : "Add Task List",

    //formHTML

    formMessage1 : "1. Domain Name must contain at least two parts separated by a period '.'",
    formMessage2 : "2. The domain name part should not start or end with a hyphen '-'.",
    formMessage3 : "3. Domain names can contain the letters a-z, digits 0-9, and the hyphen.",
    formMessage4 : "4. The subsequent parts of the domain name should at least be two characters long.",
    recommendedSize : "(Recommended size 500X500 px) Supported file: JPG, PNG",
    recommendedSize2 : "Recommended size (500px X 500px).",
    topLeft : "Top Left",
    topRight : "Top Right",
    bottomLeft : "Bottom Left",
    bottomRight : "Bottom Right",

    //importUserHTML
    importUserVariable1 : "Upload CSV File for Import Users",
    importUserVariable2 : "Drag and Drop CSV file here",
    importUserVariable4 : " Browse ",
    importUserVariable5 : "Invalid file type",
    importUserVariable6 : "Send Bulk Email:",
    importUserVariable7 : "Yes",
    importUserVariable8 : "No",
    importUserVariable9 : "Upload",
    importUserVariable10 : "Import User File History",
    importUserVariable11 : "Refresh",
    importUserVariable12 : "Upload CSV",
    importUserVariable13 : "OR",

    //generateCredentialsHTML
    generateCredentialsVariable1 : "Information about Generate/Edit Credentials",
    generateCredentialsVariable2 : "Anonymous",
    generateCredentialsVariable3 : "- If users do not want Cornerstone Guide to save their information, select Anonymous.",
    generateCredentialsVariable4 : "JIT",
    generateCredentialsVariable5 : "If you select JIT, then we check whether this email is already registered with Cornerstone Guide, if not, we add the person as a Cornerstone Guide User.",
    generateCredentialsVariable6 : "Key Name",
    generateCredentialsVariable7 : "- In this field, enter the purpose of creating this key. E.g., SSO, Test, Salesforce, .js, etc.",
    generateCredentialsVariable8 : "Choose Image",
    generateCredentialsVariable15 : "Select Applications",
    generateCredentialsVariable16 : "Application is required",

    //headerHTML
    headerVariable1 : "ORGANIZATION:",
    headerVariable2 : "ROLE:",
    headerVariable3 : "Change Password",
    headerVariable4 : "Logout",
    headerVariable5 : "Insights",

    //leftPanelHTML
    // dashboard : "Dashboard",


    //manageContentHTML
    manageContentVariable1 : "Task List:",
    manageContentVariable5 : "Application:",
    manageContentVariable6 : "Preview",
    manageContentVariable10 : "No Guides",
    manageContentVariable12 : "Manage Guides",
    manageContentVariable13 : "Add Folder",
    manageContentVariable17 : "Select All",
    manageContentVariable18 : "Guides selected",
    manageContentVariable21 : "No Published Guides available under this application",
    manageContentVariable27 : "Previous",
    manageContentVariable29 : "Next",
    manageContentVariable30 : "This folder can not be deleted as it contains guides. Remove Guides and try again!",

    //manageRoleHTML
    manageRoleVariable5 : "Add Role",
    manageRoleVariable1 : "Assign Role",

    //otpHTML
    otpVariable1 : "Back to Login",
    otpVariable2 : "Verify Account",
    otpVariable3 : "Unlock User",
    otpVariable4 : "OTP has been sent to ",
    otpVariable6 : "Resend OTP",
    otpVariable7 : "Back to Login",
    otpVariable8 : "User account successfully unlocked.",
    otpVariable9 : ". Please enter the one time password to verify your account",
    otpVariable10 : "Please enter the one time password to unlock your account",
    otpVariable11 : "OTP",
    otpVariable12 : "Invalid OTP",
    otpVariable13 : " Please try with correct OTP",
    otpVariable14 : "Didn't receive an OTP",
    otpVariable15 : "Resend OTP",

    //ssoHTML
    attributeMapping : "Attribute Mapping",

    //themeHTML
    stepTitle : "Step Title",
    stepDescription : "Step Description",
    stepTooltips : "Step Tooltips",
    pageThemeNumber : "Page ##",

    //themeView

    firstPage : "First Page",
    middlePage : "Middle Page",
    lastPage : "Last Page",

    //themeListHTML

    themeListVariable1 : "PPT, Doc & PDF Theme Setting",
    themeListVariable2 : "Info : ",
    themeListVariable3 : "Active theme will be used to create PPT (.pptx), PDF (.pdf) and Document (.docx)",
    themeListVariable4 : "Active",
    themeListVariable5 : "Activate",
    themeListVariable6 : "Theme Activation",
    themeListVariable7 : "Do you want to change default theme?",
    themeListVariable8 : "No",
    themeListVariable9 : "Yes",

    //usersHTML

    usersVariable2 : "Status",
    usersVariable3 : "All",
    usersVariable4 : "Active",
    usersVariable5 : "Suspended",
    usersVariable6 : "All",
    usersVariable7 : "Org Admin",
    usersVariable8 : "Creator",
    usersVariable9 : "Player",
    usersVariable10 : "App Admin",
    usersVariable11 : "MG Admin",
    usersVariable12 : "App Access Role",
    usersVariable13 : "All",
    usersVariable14 : "Export Data",
    usersVariable19 : "Update Locked Status",
    usersVariable20 : "Update Status",
    usersVariable22 : "Do you want to unlock this user?",
    usersVariable23 : "Do you want to update the status of this user?",
    usersVariable25 : "Unlock",
    usersVariable26 : "Yes",
    usersVariable27 : "Add User",
    usersVariable28 : "Import User",
    usersVariable29 : "Update Locked Status",
    usersVariable30 : "Update Status",
    usersVariable31 : "User Role",
    usersVariable32 : "Add Internal User",
    usersVariable33 : "Assign Org",

    //userProvisioning
    usersProvisioningVariable30 : "1. Generating new provisioning token will delete/replace existing provisioning token. This will prevent access to user provisioning APIs using old token.",
    usersProvisioningVariable31 : "2. The credentials generated depends upon the provision method selected below.",
    usersProvisioningVariable1 : "Please copy the above credentials before closing this pop up. It cannot be viewed again after closing this pop up.",

    usersProvisioningVariable2 : "API Token",
    usersProvisioningVariable3 : "Are you sure you want to proceed?",

    //tableHTML
    tableUnmapped : "Unmapped",
    tableTitle1 :"Assign Segment Values",
    tableTitle2 : "Edit",
    tableTitle3 : "Manage Content",
    tableTitle4 : "Click to Edit",
    tableTitle5 : "Sign Out of All Sessions",


    //taskHTML
    visibility : "Visibility",
    taskVariable1 : "Path will always start with a forward slash, only alphanumeric characters and special characters (~,=,<,>,/,* only) are allowed, spaces aren't allowed in the path string.",
    taskVariable2 : "This field is mandatory.",
    taskVariable3 : "Double slash is not allowed.",
    taskVariable4 : "Can't have <> and ~= together after forward slash.",
    taskVariable5 : "Error: _ and - is not allowed except between angular brackets(<>).",
    taskVariable6 : "Can't have ~ or = alone in the path, ~ will always be followed by =.",
    taskVariable7 : "Invalid Parameter: It will always start with a ? and can't have ? followed with = in the parameter",
    taskVariable8 : "Invalid Parameter: & followed with alphanumeric characters and special characters (-, _) are allowed.",
    taskVariable9 : "Invalid Parameter: only alphanumeric characters and special characters (-, _, =, &) are allowed.",
    taskVariable10 : "Invalid Hash: It will always start with a #.",

    //leftPanelHeadings
    applications : "Applications",
    users : "Users",
    manageRoles : "Manage Roles",
    themes : "Themes",
    activity : "Activity",
    exportData : "Export Data",
    taskList : "Task List",
    segments : "Segments",
    domains : "Domains",
    adminSettings : "Admin Settings",
    themeSettings : "Theme Settings",
    customizationCentre : "Customization Center",

    guidePublishingDate : "Display Guide Publishing Date",
    firstSlide : "First Slide",
    lastSlide : "Last Slide",
    setDisplay : "Set Display Position",
    setFirstSlide : "Set First Slide Display Position",
    setLastSlide : "Set Last Slide Display Position",
    markAll : "Mark All",
    language : "Language",
    voice : "Voice",
    uploadProfileImage : "Upload Profile Image",
    downloadSampleCSV : "Download Sample CSV",
    done : "Done",
    started : "Started",
    pending : "Pending",
    failed : "Failed",
    changeUserPassword : "Change User Password",
    segmentValues : "Segment Values",
    tokenGenerated : "Token Generated",
    availableSoon : "Your file will be available soon ",
    exportInProgress : "Export in progress!",
    themeActivated : "Theme Activated",
    themeUpdated : "Theme Activated",
    themeGenerated : "Theme Generated",
    themeActivation : "Theme Activation",
    invalidExtension : "Invalid Extension or Size is more than 2MB.",
    unableUpdateLogo : "Unable to update Logo",
    create : "Create",
    delete : "Delete",
    update : "Update",
    field : "Field",
    originalValue : "Original Value",
    newValue : "New Value",
    message1:"Warning! Once Hash player user's data is enabled it cannot be disabled",
    message2:"To enable Insights, atleast one of the following options must be enabled",
    high : "High",
    medium : "Medium",
    low : "Low",
    view : "View",
    details1 : "Details",
    assign : "Assign",
    label1:"Update Password",
    label2:"Update Profile",
    label3:"Generate Keys",
    label4:"Update Keys",
    label5:"Delete Keys",
    label6:"Assign To App",
    label7:"User Role",
    label8:"Provisioning",
    label9:"Api Keys",
    label10:"Organization",
    label11:"Application",
    label12:"Category Order",
    label13:"Category",
    label14:"Tour Order",
    label15:  "Update Admin Settings",
    label16:  "Create SSO Settings",
    label17:  "Update SSO Settings",
    label18:  "Delete SSO Settings",
    label19:  "Create Application",
    label20:  "Update Application",
    label21:  "Delete Application",
    label22:  "Update Category Order",
    label23:  "Create Sub Category",
    label24:  "Update Sub Category",
    label25:  "Delete Sub Category",
    label26:  "Update Tour Order",
    label27:  "Create Task List",
    label28:  "Delete Task List",
    label29:  "Publish Task List",
    label30:  "Update Task List",
    label31:  "Update Task List Setting",
    label32:  "Create Task List Folder",
    label33:  "Update Task List Folder",
    label34:  "Delete Task List Folder",
    label35:  "Update Task List Content",
    label36:  "Create Task List Completion",
    exportmessage1 :"Export file with guide metadata - name, application, publish status etc.",
    exportmessage2 :"Export file with user details like: First and Last Name, email, role, etc",
    previewMessage :"Video preview does not exist",
    preview : "Preview",
    detailButtonText : "Details",
    taskListNote1 : "Steps to publish a task list : ",
    taskListNote2 : "1. Create a task List",
    taskListNote3 : "2. Add contents",
    taskListNote4 : "3. Add visibility settings",
    taskListNote5 : "4. Change Publish Status",
    serverMessage1 : "Invalid/Empty Header [AccessToken]",
    serverMessage2 : "[AccessToken] expired",
    serverMessage3 : "We're very sorry, it seems there is problem with your request or with our servers. We've been notified of the error and will correct it as soon as possible.",
    serverMessage4 : "User email is not verified",
    serverMessage5 : "Maintenance in progress. Please contact us via support@csod.com ",
    serverMessage6 : "Invalid/Empty Parameter [token]",
    serverMessage7 : "You have reached the maximum limit to create steps",
    serverMessage8 : "Invalid/Empty Parameter [account unlock token]",
    serverMessage9 : "The support for this Admin API is not avalible. We have depricated this. We will remove in future releases.",
    serverMessage10 : "[Authorization] header expired",
    serverMessage11 : "Required parameter/s missing",
    serverMessage12 : "Invalid login credentials. Your account will be locked after multiple unsuccessful attempts.",
    serverMessage13 : "Invalid or expired OTP",
    serverMessage14 : "Uploaded file is not a valid image. (Only JPG and PNG files are allowed)",
    serverMessage15 : "Session expired",
    serverMessage16 : "User ID invalid. Please try again",
    serverMessage17 : "Email ID incorrect. Please try again",
    serverMessage18 : "User Role invalid. Please try again",
    serverMessage19 : "Invalid Parameter [task_type]",
    serverMessage20 : "Invalid/Empty Parameter [application_id]",
    serverMessage21 : "Invalid JSON",
    serverMessage22 : "Invalid/Empty Parameter [organization_id]",
    serverMessage23 : "Invalid/Empty Parameter [parent_id]",
    serverMessage24 : "Invalid/Empty Parameter [category_id]",
    serverMessage25 : "Invalid/Empty Parameters [from_category_id/to_category_id]",
    serverMessage26 : "Parameter required for Super Admin [organization_id]",
    serverMessage27 : "Image Data content should be base64 encoded",
    serverMessage28 : "Invalid Domain ID",
    serverMessage29 : "File type not supported",
    serverMessage30 : "Invalid parameter [file_id]",
    serverMessage31 : "Invalid/Empty Parameter [tour_id]",
    serverMessage32 : "Invalid/Empty Parameter [step_id]",
    serverMessage33 : "Invalid/Empty Header [GoogleToken]",
    serverMessage34 : "Invalid Team URL",
    serverMessage35 : "Invalid/Empty Header [EdcastToken]",
    serverMessage36 : "File size should be less than 5MB",
    serverMessage37 : "Invalid URL",
    serverMessage38 : "Your password must be between 8 and 15 characters, including at least one uppercase letter, one lowercase letter, one numeric value and one special character.",
    serverMessage39 : "Invalid Password",
    serverMessage40 : "Invalid/Empty Parameter [country_id]",
    serverMessage41 : "Invalid/Empty Parameter [city_id]",
    serverMessage42 : "Invalid parameter [language]",
    serverMessage43 : "Authentication ID is invalid",
    serverMessage44 : "Invalid parameter [file_id]",
    serverMessage45 : "Invalid URL ID",
    serverMessage46 : "Invalid/Empty/Expired Header [Authorization]",
    serverMessage47 : "Invalid/Empty Header [AppKey]",
    serverMessage48 : "Invalid/Empty Header [RefreshToken]",
    serverMessage49 : "Invalid Role ID",
    serverMessage50 : "Invalid Segment ID",
    serverMessage51 : "Invalid Domain Name",
    serverMessage52 : "Duplicate segment data",
    serverMessage53 : "Invalid subscription ID",
    serverMessage54 : "Invalid organization role ID",
    serverMessage55 : "Invalid task list ID",
    serverMessage56 : "The task list has been unpublished",
    serverMessage57 : "The task list has been published",
    serverMessage58 : "2044",
    serverMessage59 : "Invalid Folder ID",
    serverMessage60 : "2045",
    serverMessage61 : "Invalid CONTENT ID",
    serverMessage62 : "Invalid parameter [external_user_id]",
    serverMessage63 : "Invalid step language data. Please check step title and description for any invalid or junk characters",
    serverMessage64 : "Invalid Job ID",
    serverMessage65 : "Action not allowed on locked user account",
    serverMessage66 : "Invalid/Empty parameter [segments]",
    serverMessage67 : "Invalid/Empty parameter [group_id]",
    serverMessage68 : "Invalid/Empty parameter [sso_id]",
    serverMessage69 : "Invalid URL provided",
    serverMessage70 : "Invalid/Empty Parameter [group_id]",
    serverMessage71 : "step_audio or step_audio_text fields are required",
    serverMessage72 : "Data integrity failed",
    serverMessage73 : "An old and new password can not be the same.",
    serverMessage74 : "action field are required",
    serverMessage75 : "Invalid/Empty Parameter [id]",
    serverMessage76 : "Subcategory cannot be created inside Category if a Guide is already created.",
    serverMessage77 : "If a user is already a Super Admin, you cannot change the User Role.",
    serverMessage78 : "This action is not allowed on a published guide",
    serverMessage79 : "Locked guide cannot be published.",
    serverMessage80 : "This action is not allowed on published category. Please unpublish it.",
    serverMessage81 : "Application must belong to the given organization",
    serverMessage82 : "You are not authorised to perform this action",
    serverMessage83 : "Action is not allowed for this Subcategory.",
    serverMessage84 : "Guide cannot be created inside Category if subcategory already created",
    serverMessage85 : "To create step/s, you need to unlock this guide",
    serverMessage86 : "To edit step/s, you need to unlock this guide",
    serverMessage87 : "To delete step/s, you need to unlock this guide",
    serverMessage88 : "To copy and paste step/s, you need to unlock this guide",
    serverMessage89 : "Action is not allowed for this tasklist.",
    serverMessage90 : "Action is not allowed on a published tasklist.",
    serverMessage91 : "Action is not allowed for this folder.",
    serverMessage92 : "This is already a draft guide, we can not create a new one",
    serverMessage93 : "The draft guide can not be created if guide is not published on highest environment.",
    serverMessage94 : "This guide already have a draft, we can not create a new draft for this.",
    serverMessage95 : "Invalid values of parameter [envs]",
    serverMessage96 : "You have reached the allowed limit for Guide ids",
    serverMessage97 : "Invalid values of parameter [Keywords]",
    serverMessage98 : "Action not allowed for tag step.",
    serverMessage99 : "Guides cannot be paste in same category",
    serverMessage100 : "steps cannot be paste in same tour",
    serverMessage101 : "Guide will be available for export soon. To check the status go to [Task Status] under account menu.",
    serverMessage102 : "Upload started. Please do not refresh or close the browser until upload is completed. You can check the import status once upload is completed",
    serverMessage103 : "User already exists",
    serverMessage104 : "Email ID is already verified",
    serverMessage105 : "This URL is conflicting with other application configuration, please try to add any other URL. For help, please contact support@csod.com",
    serverMessage106 : "Category must belong to the same application",
    serverMessage107 : "Domain already exists",
    serverMessage108 : "You have reached the maximum limit to create guides",
    serverMessage109 : "Step ID must belong to the same guide",
    serverMessage110 : "This Organization is suspended. Please contact your Administrator.",
    serverMessage111 : "Guide ID/s must belong to the same category",
    serverMessage112 : "Guide already exists with the given pair of Object and Field name",
    serverMessage113 : "User suspended",
    serverMessage114 : "User already verified",
    serverMessage115 : "This feature is disabled on HPE",
    serverMessage116 : "OTP has been sent successfully to your email address",
    serverMessage117 : "Role already exists",
    serverMessage118 : "Country already exists",
    serverMessage119 : "City already exists",
    serverMessage120 : "Given guide/s already added in the queue for Export",
    serverMessage121 : "Category unpublished",
    serverMessage122 : "Category published",
    serverMessage123 : "Your organization is not verified. For help, please contact support@csod.com",
    serverMessage124 : "JSON file is not available for this organization.",
    serverMessage125 : "API credentials are wrong or does not exist.",
    serverMessage126 : "JSON file is not available for this guide.",
    serverMessage127 : "No step available. Create at least one step to publish the guide.",
    serverMessage128 : "Please enter valid work email address.",
    serverMessage129 : "Default application.",
    serverMessage130 : "Default role.",
    serverMessage131 : "API key is used for SSO configuration.",
    serverMessage132 : "This request cannot be processed. Please contact us at contact support@csod.com to enable this request.",
    serverMessage133 : "You have reached the maximum limit to create applications",
    serverMessage134 : "This feature is disabled",
    serverMessage135 : "The timestamp provided is invalid. Please provide a valid timestamp.",
    serverMessage136 : "You have reached the maximum limit to create users.",
    serverMessage137 : "Invalid/Expired Subscription.",
    serverMessage138 : "The scim ID provided is invalid.",
    serverMessage139 : "The filter provided is not supported.",
    serverMessage140 : "The specified resource is not available.",
    serverMessage141 : "Invalid SCIM schema.",
    serverMessage142 : "Invalid parameter.",
    serverMessage143 : "The required User Attribute(s) are missing.",
    serverMessage144 : "This task is already completed.",
    serverMessage145 : "This user account is already unlocked.",
    serverMessage146 : "Your account has been temporarily locked due to excessive login failures. We have sent you an email with steps to unlock it. For more assistance, please contact us at support@csod.com.",
    serverMessage147 : "Invalid data in provided JSON",
    serverMessage148 : "SSO is not enabled for this organization. Please contact us at contact support@csod.com for more information.",
    serverMessage149 : "The audio is not created",
    serverMessage150 : "Excel File of guide list will be available soon.",
    serverMessage151 : "Tours not exist in given organization",
    serverMessage152 : "Your previous action is in progress, once it is completed you can perform next action.",
    serverMessage153 : "Tours already exist in given category",
    serverMessage154 : "The file(s) could not be imported into the application/category specified.",
    serverMessage155 : "Media file themes are not enabled for this organization. Please contact us at support@csod.com for more information.",
    serverMessage156 : "Invalid destination for Guide Import",
    serverMessage157 : "We couldn't find any users associated with this organization.",
    serverMessage158 : "Excel File of user list will be available soon.",
    serverMessage159 : "Redis cache is disabled",
    serverMessage160 : "You can pin up to 5 guides",
    serverMessage161 : "Guide is already pinned",
    serverMessage162 : "Invalid/Empty parameter [mobile_app_id]",
    serverMessage163 : "JSON file is not available for this application.",
    serverMessage164 : "Bundle/Package id already exists.",
    serverMessage165 : "Segment with this name already exist.",
    serverMessage166: "A new password can not be the same as the old 5 password.",
    serverMessage167 : "The Title field may only contain alpha characters and spaces",
    serverMessageDefault : "Sorry for the inconvenience. Please contact us at contact support@csod.com for more information",
    errorMessage1 : "Session expired!",
    errorMessage2 : "Service Not available!",

    //customizationCentre
    customizationCentreBreadcrumb : "Customization Centre",
    customizationCentreTitle : "Customization Centre | ",
    settings : "Settings",
    brandLogo : "Brand logo",
    brandingWatermark : "Branding Watermark",
    widgetIcon : "Widget icon",
    widgetIconPosition : "Widget icon position",
    widgetIconBadge : "Widget icon badge",
    widgetIconZIndex : "Widget z-index",
    hideWidgetIconOnGuidesPage:"Hide widget if no guides on page",
    widgetIconVisibility : "Widget icon visibility",
    false : "False",
    firstSlideImage : "First slide image",
    lastSlideImage : "Last slide image",
    stepBalloonTheme : "Step balloon theme",
    stepTitleText : "Step title text",
    dummyText : "Lorem Ipsum is dummy text",
    prev : "Prev",
    tooltipBalloonTheme : "Tooltip balloon theme",
    tooltipBallonInfoTip : "The first line of the Tooltip text will be treated as the 'Step Title' and the following part as the 'Step Description'",
    applyStepBallonTheme : "Apply step balloon theme",
    stepSelectorBorderWidth : "Step selector border width",
    stepSelectorBorderColor : "Step selector border color",
    enableStepAudio : "Enable step audio",
    preferredColorsForTitleDescription : "Preferred colors for step title and description",
    reset : "Reset",
    tooltipSelectorBGColor : "Tooltip selector background color",
    tutorialSelectorBGColor: "Tutorial selector background color",
    tooltipSelectorBorderColor : "Tooltip selector border color",
    notificationHeaderColor : "Notification header color",
    pushNotificationDelay : "Push notification delay",
    pushNotificationSnoozeTime : "Push notification snooze time",
    chatbotIcon : "Chatbot icon",
    chatbotPosition : "Chatbot position",
    branding:"Branding",
    widget:"Widget",
    videogif:"Video & GIF",
    step:"Step",
    notification:"Notification",
    chatbot:"Chatbot",
    features:"Features",
    labels:"Labels",
    top: "Top",
    bottom: "Bottom",
    left: "Left",
    right: "Right",
    firstSlideText: "Welcome",
    lastSlideText: "Thank you",
    chatbotGreetingMsg: "Chatbot Greeting Message",
    defaultChatbotGreeting: "Hey! Welcome to Cornerstone Guide. How can I help you? Please keep in mind that I am a bot.",
    chatbotGreetingEndMsg: "Chatbot End Of Conversation Message",
    defaultChatbotEnd: "We are done, thank you so much! Anything else before I kick it off?",
    chatbotErrorMsg: "Chatbot Error Message",
    defaultChatbotError: "I'm sorry, I did not understand that. Could you be more specific?",
    miniPlayerHeader: "Mini player header",
    showAllGuidesTab: "Hide “All Guides” tab",
    defaultGuideActionInPlayer: "Default guide action in player",
    defaultWebPlayerMode: "Default web player mode",
    default: "Default",
    miniPlayer: "Mini Player",
    playerPanelDefaultMode: "Player panel default mode",
    open: "Open",
    closed: "Closed",
    configureFeedbackOptn: "Configure feedback option",
    sendFeedbackOptn: "Enable Send Feedback",
    email: "E-mail",
    imageLibrary: "Image Library",
    submit: "Submit",
    classic: "Classic",
    defaultPagePlayer: "Default to “All Guides” view",
    allGuides: "All Guides",
    currentPage: "Current Page",
    circular: "Circular",
    rectangular: "Rectangular",
    width: "Width",
    height: "Height",

    bgColor: "Background Color",
    borderColor: "Border Color",
    iconColor: "Icon Color",
    stepTitleColor: "Step Title Color",
    stepDescColor: "Step Description Color",
    borderRadius: "Border Radius",

    prevBTNBG: "Previous Button Background",
    prevBTNText: "Previous Button Text",
    borderWidth: "Border Width",
    nextBTNBG: "Next Button Background",
    nextBTNText:"Next Button Text",
    popupWidth : "Popup Width",
    colorPicker : "Color Picker",
    brandLogoTooltip : "Image dimensions: 100 x 40 px",
    brandingWatermarkTooltip : "A branding element to overlay on your videos and GIFs at bottom. For best results, use transparency and recommended dimensions\nImage dimensions: 150 x 150 px",
    widgetIconBadgeTooltip : "This badge is visible if there are guides on the current page",
    firstSlideText : "Welcome",
    slideTextTooltip : "Slide text is applicable only on Video and not GIF",
    lastSlideText : "Thank You",
    pushNotifDelayTooltip : "Show push-notification popup after (allowed range 0 to 5 seconds)",
    pushNotifSnoozeTime : "Do not show closed notifications for [select] hours",
    playerPanelOpenLabelTooltip : "Opens player panel every time on page load",
    widgetPosi1 :"Top Right",
    widgetPosi2 :"Top Left",
    widgetPosi3 :"Bottom Right",
    widgetPosi4 :"Bottom Left",
    stepPopupBtnNext : "Step Popup Button Next",
    stepPopupBtnPrev : "Step Popup Button Prev",
    enableChat : "Activate Chat",
    resumeGuideDefaulText : "Resume Guide",
    oopsMessageDefaulText : "It looks like you're performing steps that are not part of this Guide. If you no longer wish to follow the Guide, click on Exit Guide",
    maintenanceDefaulText : "Maintenance in progress. Please contact us via support@csod.com",
    serviceErrorDefaulText : "Oops! An error occurred. Please try again after some time. You can also email us at support@csod.com for any assistance",
    invalidImageText : "Selected files should be in png or jpeg format",
    tutorialSteps: "Tutorial Steps",
    tutorialModal: "Tutorial Balloon Theme",
    tutorial: "Tutorial",
    titleBgColor: "Title Background Color",
    prevBtnLabel: "Previous Button",
    nextBtnLabel: "Next Button",
    color: "Color",
    background: "Background",
    fontSize: "Font Size",
    outerBox: "Outer Box",
    outerBoxShadow: "Outer Box Shadow",
    horizontal: "Horizontal",
    vertical: "Vertical",
    blur: "Blur",
    spreadRadius: "Spread Radius",
    inset: "Inset",
    noApplicationAssigned: "No Application assigned",
    //ruleModal

    URL : "URL",
    urlHostname : "URL Hostname",
    urlPath : "URL Path",
    urlParams:"URL Parameters",
    urlHash:"URL Hash",
    pageTitle : "Page Title",
    equals:"Equals",
    notEquals:"Not Equals",
    contains : "Contains",
    doesNotContain : "Does Not Contain",
    startsWith : "Starts With",
    endsWith : "Ends With",
    discardBtnTxt : "Discard",
    addRule : "Add 'AND' Rule",
    addGroup : "Add 'OR' Group",
    createRules : "Create Rules",
    stepRules : "Step Rules",

    //Image Library
    all : "All",
    building : "Building",
    business : "Business",
    education : "Education",
    fashion : "Fashion",
    finance : "Finance",
    food : "Food",
    healthcare : "Healthcare",
    miscellenous : "Miscellenous",
    nature : "Nature",
    people : "People",
    science : "Science",
    technology : "Technology",
    transport : "Transport",
    work : "Work",
    images : "Images",
    icons : "Icons",
    colors : "Colors",
    accessibility:"Accessibility",
    animal : "Animal",
    audioAndVideo : "Audio & Video",
    automotive : "Automotive",
    charity : "Charity",
    chat : "Chat",
    communication:  "Communication",
    solid : "Solid",
    trendy :"Trendy",
    popular : "Popular",
    random : "Random",
    labels : "Labels",
    resumeGuide : "Resume Guide :",
    oopsMessage : "Oops Message : ",
    maintaninence : "Maintenance  : ",
    serviceError : "Service Error  : ",

    //internalUsers
    internalUsers : "Internal Users",
    externalUsers : "External Users",


    //partner
    partnerId: "Partner ID",
    addPartner: "Add Partner",
    partner: "Partner",
    partnerAdmin: "Partner Admin",
    partners: "Partners",
    editPartner: "Edit Partner",

    //Internal User
    addInternalUser: "Add Internal User",
    editInternalUser: "Edit Internal User",
    unassign: "Unassign",
    orgAssign: "Assign Organization",

    //footer
    version: "Version ",

    //decommission Org
    decommissionBtn: "Decommission",
    deleteBackupBtn: "Delete Backup",
    reactivateBtn: "Reactivate",
    activeOrgs: "Active Orgs",
    suspendedDeletedOrgs: "Suspended / Decommissioned Orgs",
    suspendedByUser: "Suspended By User : ",
    suspendedByEmail: "Suspended By Email : ",
    suspendedOn: "Suspended On : ",
    deletedByUser: "Deleted By User : ",
    deletedByEmail: "Deleted By Email : ",
    deletedOn: "Deleted On : ",
    decommissionContent: "Are you sure you want to decommision this organization? This action cannot be reversed.",
    deleteBackupOf: "Delete Backup of ",
    deleteBackupContent: "Are you sure you want to delete organization backup? This action cannot be reversed.",

    //Enable Media File Creation Notification
    enableMediaNotification: "Enable Media File Creation Notification",

    //ManageKeywords
    guideName: "Guide Name",
    keywords: "Keywords",
    addKeywords: "Add Keyword(s)",
    manageKeywords: "Manage Keywords",
    published: "Published",
    keywordLoading: "Updating keywords. This will take a few minutes. It is safe to navigate away",
    assignKeywords: "Assign Keyword(s)",
    keywordTooltip: "Press enter or comma to add a keyword",

    //Manage Segments
    manageSegments: "Manage Segments",
    guides: "Guides",
    segmentTitle: "Segment Title",
    addSegmentTitle: "Add Segment Title",
    assignSegments: "Assign Segments",
    segmentLoading: "Updating segments. This will take a few minutes. It is safe to navigate away",
    addSegment: "Add Segment(s)",
    segmentTooltip: "Select one or many segments from the list",
    addASegment: "Add Segment",
    deleteSegment : "Delete Segment",
    confirmDeleteSegment: "Are you sure you want to delete segment?",
    selectSegment: "Select a Segment",

    //Find & Replace
    findReplace: "Find & Replace",
    apps: "Apps",
    content: "Content",
    text: "Text",
    element: "Element",
    find: "Find",
    replace: "Replace",
    selectElement: "Select Element",
    elementPreview: "Element Preview",
    action: "Action",
    findReplaceSearchPlaceholder: "What are you looking for?",
    noGuideFound: "No Guide(s) were found to match your search",
    modifySearchCriteria: "Try modifying your search criteria",
    maxGuideSelected: "You can select maximum 50 Guide(s) at a time",

    //GUIDES
    import: "Import",
    paste: "Paste",
    importedGuides: "Imported Guides",
    exportedGuides: "Exported Guides",
    transferGuides: "Transfer Guides",
    export : "Export",

    //Page Tracking
    pageTracking : "Page Tracking",
    featureTracking: "Feature Tracking",
    // Enable Mirror Application
    enableMirrorApplications: "Enable Mirror Applications",

    //onpremise
    selfHostedPlayer : "Self Hosted Player",
    playerPackages: "Player Packages",
    createPlayerPackage: "Create Player Package",
    playerPackageError: "This action is not allowed because job is already in progress",
    selfHostedPlayerURL : "Self Hosted Player URL",
    pasteErrorMessage: "Guides cannot be pasted in same category",
    pasted: "Pasted Successfully !!",
    exportMessage: "Guide will be available for export soon. To check the status, go to 'Exported Guides' under the Guides section",
    importMessage: "Import of guides started. To check the status, go to 'Imported Guides' tab under the Guides section",
    srNo: "Sr No.",
    jsonProcessingIncomplete: "This action is not allowed because json processing is not completed",
    contentCreationNotAllowed: "Content creation is not allowed for Mirror Applications.",

    //Chatbot visibility settings
    chatbotVisibilitySettings: "Chatbot Visibility Settings",

    //label for padding in step theme modal
    padding: "Padding : ",

    //exclude domains
    excludeDomains: "Exclude Domains",
    excludeDomainsTooltip: "Define a list of domains where Cornerstone Guide should not be available",

    //error message
    firstNameError: "First Name should not contain any special characters",
    lastNameError: "Last Name should not contain any special characters",

    //generic tag message
    enterNewTag: "Enter a new tag",

    //Note for customization centre
    noteForCustomizationCentre: "Availability of below functionalities are subject to it’s availability in the target product category (e.g.: Mobile App, Desktop App, etc.) and may change over time.",

    //enforce sequence
    enforceSequence : 'Enforce Sequence',
    guideSequenceUpdated: 'Guide Sequence Updated',
    updateSequence: 'Update Sequence',
    shiftUp: 'Shift Up',
    shiftDown: 'Shift Down',

    //admin setting label for tooltip guides
    mediaFileCreationForTooltipGuides: "Media File Creation for tooltip guides",
    tooltipMediaFileCreationTooltipGuides: "Enables export of Media File Creation for tooltip guides.",
    mediaFileCreationForTooltipGuidesToastInfo: "Enabling tooltip guides for media file creation is only possible when the 'Media File Creation' setting is enabled.",

    //import segment values
    importSegmentValues: "Import Segment Values",
    importSegmentValuesHistory: "Import Segment Values History",

    //Constants for 2024.2.0 sprint
    //Configure help location constant
    configureHelpLocation: "Configure help location",

    //organization
    creataOrganization: "Create Organization",
    createPartner: "Create Partner",
    createSegment: "Create Segment",

    oneOrMoreGuidesPresent: "One or more guides are already present in a different folder on this task list",

    betaFeatures: "Beta Features",
    keyboardShortcuts:"Keyboard Shortcuts",
    keyboardShortcutsTooltip: "Keyboard shortcuts allow you to create shortcuts to play workflow guides. To see a complete list of keyboard shortcuts created, type ? when viewing the customer web application in your browser.",

    dashboardBreadCrumb : "Dashboard",

    //2024.2 (Sprint 2)
    headerVariable6: "Help",
    productType: "Product Type",
    guideInventory: "Guide Inventory",
    guideTitle: "Guide Title" ,
    createdBy: "Created By",
    modifiedBy: "Modified By",
    guideId: "Guide ID",
    botGuide: "Bot Guide",
    tooltipGuide: "Tooltip Guide",
    workflowGuide: "Workflow Guide",
    featureTags: "Feature Tags",
    tutorialGuide: "Tutorial Guide",
    guideType: "Guide Type",

    unpublish: "Unpublish",
    environments: "Environments",
    publishGuide: "Publish Guide",


    importSegment: "Import Segments",
    importSegmentHistory: "Import Segments History",
    guidePublishInfoMessage: "Guide will be published in ",
    guidePublishedSuccessfully: "Guide Published Succesfully",
    guideUnpublishedSuccessfully: "Guide Unpublished Succesfully",

    enableDAP: "Enable DAP Notifications from Insights",
    enableDAPTooltip: "When enabled, uses the default values set in the Insights Portal, under Control Center, for Email Notification Preferences > Weekly Usage Stats and Daily Steps Failure Report. If this setting is disabled, will override the default settings and disable notifications to be sent for Weekly Usage Stats and Daily Steps Failure Report in the Insights Control Center",

    showtooltipsWithWorkflowTutorial: "Show Tooltips with Workflow and Tutorial Guides",
    showtooltipsWithWorkflowTutorialTooltip: "Enables Tooltip guides to show on a page that also has either Workflow or Tutorial guides showing. When disabled, any Tooltip guides are not shown in parallel with Workflow or Tutorial guides",

    exportInventoryToastMessage: "Guide data has been queued for export. To check status, go to Export Data under Manage Guides section",
    keyboardShortcuts: "Keyboard Shortcuts",
    autobot: "AutoBot",
    globalFeatureFlags: "Global Feature Flags",
    beta: "Beta",
    legacy: "Legacy",
    enterprise: "Enterprise",
    featureStatusChanged: "Global feature flag updated successfully",
    confirmChanges: "Confirm changes",
    featureFlagConfirmationMessage: "This will propagate to all organizations, are you sure you want to continue?",
    pageEnteredOutsideRange: "Page number entered is outside range",
    showing: "Showing",
    of: "of",
    segmentation: "Segmentation",
    customization: "Customization",
    administration: "Administration",

    //change of segments to User Attributes
    userAttributes: "User Attributes",
    addUserAttributesButtonText: "Add User Attribute",
    updateUserAttributesButtonText: "Update User Attribute",
    assignUserAttributes: "Assign User Attribute Values",
    userAttributeValues: "User Attribute Values",
    addUserAttributeValues: "Add User Attribute Values",

    importUserAttributeValues: "Import User Attribute Values",
    importUserAttributeValuesHistory: "Import User Attribute Values History",
    importUserAttributes: "Import User Attributes",
    importUserAttributesHistory: "Import User Attribute History",


    addingUserAttributeButtonText: "Adding User Attribute...",
    updatingUserAttributeButtonText: "Updating User Attribute..",


    userAttributeCreatedText: "User Attribute Created",
    userAttributeUpdatedText: "User Attribute Updated",
    userAttributeDeletedText: "User Attribute Deleted",
    userAttributeValueCreatedText: "User Attribute Value Created",
    userAttributeValueDeletedText: "User Attribute Value Deleted",

    //widget zindex tooltip
    widgetIconZIndextooltip: "Z-Index is used to control how Cornerstone Guide shows over elements on the screen. In some cases, Cornerstone Guide components become hidden behind on-screen elements. Setting a higher or lower Z-Index value can improve the chances of showing Cornerstone Guide components over on-screen elements (value between -2147483646 to 2147483646).",
    mirrorApplications: "Mirror Applications",
    enabled: "Enabled",
    disabled: "Disabled",

    appIntelligence: "App Intelligence",
    greaterThan: "Greater Than",
    lessThan: "Less Than",
    userCreationDate: "User Creation Date",

    //SuperAdmin Insights Customer Type
    insightsCustomerType: "Insights Customer Type",
    myguideCustomers: "Cornerstone Guide Customers",
    csodInternal: "CSOD Internal",
    insightsCustomerTypeMessage: "Toggling this setting may lead to purging of old recorded data in Cornerstone Guide Insights",
    insightsCustomerTypeTooltip: "This setting will change databases to shift loads between paying Cornerstone Guide and CSOD customers",

    date: "Date",
    time: "Time",
    rule_dayOfWeek: "<li>Use first 3 letters of the english spelling to refer to a day</li><li>Consider Sunday as the start of the week</li><li>Use <i>hyphen</i> '-' to specify range</li><li>OR mention only a specific day</li><li><i>Pipe Separator</i> '|' can be used in case of multiple options</li><li>Example of accepted values:<br>'Mon - Fri', <br>'Tue', <br>'Mon - Wed | Fri'</li>",
    rule_fixTime: "<li>Enter time in 24-hour format</li><li>Use <i>hyphen</i> '-' to specify range</li><li>OR mention only a start time</li><li>End time will be considered as end of day (23:59) unless a valid value is specified</li><li><i>Pipe Separator</i> '|' can be used in case of multiple options</li><li>Example of accepted values:<br>'09:00 - 18:00', <br>'11:00', <br>'09:00-12:00 | 16:00'</li>",
    rule_dateRange: "<span><b>From</b> and <b>To</b> date fields are mandatory</span>",
    dayOfWeek: "Day of the Week",
    fixedTime: "Fixed Time in the Day",
    dateRange: "Date Range",
    displayFrequency: "Display Frequency",

    platFormUsageReport: "Platform Usage Report",
    platformUsageReportInfo: "A report that shows overall Cornerstone Guide data as a platform, providing insights into growth of users and their consumptions across all organizations",
    totalUsers: "Total Users",
    newUsers: "New Users",
    activeUserSessions: "Active User Sessions",
    guidesCreated: "Guides Created",
    guidesPublished: "Guides Published",
    guidesPlays: "Guides Plays",
    tooltipsCreated: "Tooltips Created",
    tooltipsPublished: "Tooltips Published",
    tooltipsPlays: "Tooltips Plays",
    autoProceedStep: "Auto Proceed to Next Step",
    automationStepDelay: "Auto Proceed to Next Step delay",
    userProvisioning: "User Provisioning",
    hideBrandLogo: "Hide brand logo",
    enableUserSessionTracking: "Enable User Session Tracking",
    //new constants
    viewActivityDetails: "View Activity Details",
    enableUserSessionTracking: "Enable User Session Tracking",
    editingSegment: "Editing a segment",
    selectSegmentUserAttribute: "Select a User Attribute",
    selectUserAttributeAndValues: "Select User Attributes and Values",
    editingSegmentInfoMessage: "Segments allow you to control access to guides for groups of targeted users. User Attributes and their Values are based on users profiles, which are brought in through user integrations, such as Single Sign-On, or manually managed from User Attributes in Segmentation. In addition, use rules to control where guides show for the segmented users, such as specific pages or time frames. User Attributes and Rules are used together to create the segment.",
    numOfPartnerAdminsAllowed: "Number of Partner Admins Allowed",
    numOfPartnerAdminExceeded: "Number of Partner Admins for current Partner has exceeded. Please contact us via support@csod.com",
    valueShouldBeGreaterThan: "Value should be greater than or equal to",
    setVisibilitySetting: "Set Visibility of Guide After Publishing",
    visibilitySettingsUpdated: "Visibility Settings Updated",
    auto_generate_keywords: "Guide Assist for Creators",
    editCredentials: "Edit Credentials",
    createSegmentOptionOne: "Show Guides from Player if a User is Part of the Segment.",
    createSegmentOptionTwo: "Hide Guides from Player if a User is Part of the Segment.",
    createSegmentOptionThree: "Show Guides from Player if a User is Not Part of the Segment.",
    advanceSettings: "Advanced Settings",
    changeIcon: "Change icon",
    selectFromLibrary: "Select From Library",
    uploadFromComputer: "Upload From Computer",
    textIs : "Text Is",
    testIsNot : "Text Is Not",
    textContains :  "Text Contains",
    textMatches : "Text Matches",
    variables: "Variables",
    deleteApplicationContent: "Are you sure you want to delete your application?",
    deleteUser: "Delete User",
    deleteUserContent: "Are you sure you want to delete user?",
    deleteTasklist: "Delete Tasklist",
    deleteTasklistContent: "Are you sure you want to delete tasklist?",
    deleteFolder: "Delete Folder",
    deleteFolderContent: "Are you sure you want to delete folder?",
    deleteKey: "Delete Key",
    deleteKeyContent: "Are you sure you want to delete key?",
    deleteDomain: "Delete Domain",
    deleteDomainContent: "Are you sure you want to delete domain?",
    deleteRole: "Delete Role",
    deleteRoleContent: "Are you sure you want to delete role?",
    deletePartner: "Delete Partner",
    deletePartnerContent: "Are you sure you want to delete partner?",
    deleteUserAttribute: "Delete User Attribute",
    deleteUserAttributeContent: "Are you sure you want to delete user attribute?",
    deleteUserAttributeValue: "Delete User Attribute Value",
    deleteUserAttributeValueContent: "Are you sure you want to delete user attribute value?",
    deleteSSO: "Delete SSO",
    deleteSSOContent: "Are you sure you want to delete SSO?",
    filter: "Filter",
    notifThemeProperties: "Theme Properties",
    notifThemePropertiesInfo: "Set the styling options of push notifications",
    notifHeaderPlaceholder: "Here's your alert name!",
    notifTitle:"Notification Title",
    notifDescription: "Give your notification a brief description",
    primaryAction: "Primary Action",
    secondaryAction: "Secondary Action",
    generalSettings: "General Settings",
    buttons: "Buttons",
    paddings: "Paddings",
    alert: "Alert",
    alertFont: "Alert Font",
    fontType: "Font Type",
    alertTextColor: "Alert Text Color",
    alertBackground: "Alert Background",
    heading: "Heading",
    headingFont: "Heading Font",
    headingTextColor: "Heading Text Color",
    descriptionFont: "Description Font",
    descriptionTextColor: "Description Text Color",
    buttonFont: "Button Font",
    buttonTextColor: "Button text color",
    buttonBackground: "Button Background",
    buttonBorderColor: "Button border color",
    actionButton: "Action Button",
    primaryButton: "Primary Button",
    secondaryButton: "Secondary Button",
    paddingsSetings: "Paddings Settings",
    notificationPreview: "This is your notification window preview",
    notificationWidth: "Notification Width",
    notificationHeight: "Notification Height",
    accessibilityWarning: "Customizing colors may affect accessibility. If changing from default color options, check accessibility compliance.",
    readyToStartYourJourney: "Ready to start your journey with us?",
    viewOrganizations: "View Organizations",
    associatedOrganizaitons: "Associated Organizations",
    email: "Email",
    enterEmail: "Enter Email",
    enterPassword: "Enter Password",
    passwordShort: "Password must be atleast 8 characters",
    passwordLong: "Password must be 20 characters or less",
    generateDashboard: "Generate Dashboard",
    assigned: "Assigned",
    unassigned: "Unassigned",
    biConnector: "Business Intelligence Tools Integration",
    biConnectorTooltip: "Enabling this would allow you to connect to Insights data via various Business Intelligence tools like Power BI, etc. (which are currently supported by Cornerstone Guide)",
    assignedAdmins: "Assigned Admins",
    translation_required: "Translation Required?",
    defaultTooltipImage: "Default Tooltip Image",
    generateVideo: "Generate Video",
    videoGenerationInProgress: "Video generation is in progress",
    downloadVideos: "Download Videos",
    videoGenerationStarted: "Video generation has been triggered successfully",
    rowsPerPage: "Rows per page",
    goto: "Go to",
    role: "Role",
    twelveHours: "12 hours",
    twentyFourHours: "24 hours",
    oneWeek: "1 week",
    twoWeek: "2 weeks",
    fourWeeks: "4 weeks",
    guidesDoNotExist: "We couldn't find any guides for exporting data from selected applications",
    downloadReport: "Download Report",
    reportNotAvailable: "Report not available for this organization",
    customExtention: "Custom Extension",
    customExtentions: "Custom Extensions",
    name: 'Name',
    description:  'Description',
    showWidget: 'Show Widget',
    matches: 'Whitelisted Domains for player',
    exclude_matches: 'Exclude Domains for player extension',
    ssoId: 'SSO ID',
    clientJsBaseUrl: 'Embedded Player Base URL',
    host_permissions: 'Host Permissions',
    login: 'Login',
    customer: 'Customer',
    desktopCommunication: 'Desktop Communication',
    iframeInjection: 'Iframe Injection',
    testme: 'TestMe',
    identifier: 'Identifier',
    addExtension: 'Add Extension',
    deleteExtension: 'Delete Extension',
    deleteExtensionWarning: 'Are you sure you want to delete Extension?',
    extensionAdded: "Extension added successfully",
    extensionDeleted: "Extension deleted successfully",
    extensionUpdate: "Extension updated successfully",
    guideTranslation: "Guide Translation",
    translate: "Translate",
    nolanguagesAdded: "No languages added",
    translationInitiated: "Language translation triggered.",
    editGuideLanguage: "Edit Guide Lanugage",
    translateStepData: "Translate Step Data",
    disableEditLanguageMsg: "No Applicable language to translate",
    translationUpdationSuccessful: "Language translation is updated",
    stepNo: "Step No.",
    translationInProgressInfo: "A translation process is currently in progress. Please try again once it is complete.",
    guideDescription: "Guide Description"
};
module.exports = GmXt.playerLbls.en_US;
