<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen && !userService.dashboardLoading),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <div class="row dashboard-card-wrapper" [ngClass] = "{'rowGap10' : userService.user.role == 'superadmin'}">
            <ng-container *ngIf="(userService.user.role == 'partner_admin') && !org_id">
                <!-- <div class="col-12 col-sm-12 col-md-12 my-2 my-lg-0 my-xl-0 cursor" [ngClass]="{ 'col-lg-6': (userService.user.role == 'superadmin' && !this.org_id) ,'col-lg-4' : (userService.user.role != 'superadmin' || is_local)}" [class.pointer]="userService.organization_id != '000'">
                            <div [routerLink]="userService.organization_id == '000'?['']:['/edit_organization',userService.organization_id]"
                                class="card dashboard-card">
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-5 col-md-4 d-flex align-items-center">
                                            <svg-icon src="../../../assets/svgs/dashboard_org.svg"></svg-icon>
                                        </div>
                                        <div class="col-7 col-md-8">
                                            <div class="text-center">
                                                <h2 class="font-weight-bold">{{dashboard['organizations']}}</h2>
                                                <h6 class="font-weight-bold">{{admin_data.dashboardNoOfOrganisationsTitle}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [class.d-none]="userService.organization_id == '000'"
                                    class="card-footer dashboard-card-footer p-0">
                                    <div class="dashboard-card-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13"
                                            fill="none">
                                            <path
                                                d="M12.8065 6.99084C13.0632 6.72369 13.0658 6.27662 12.8065 6.00953L8.20594 1.20319C7.94523 0.931929 7.5232 0.932614 7.26316 1.20319C7.00245 1.47376 7.00245 1.91323 7.26316 2.1838L10.7302 5.80626L1.66673 5.80626C1.29867 5.80626 1 6.11668 1 6.49987C1 6.88307 1.29873 7.19348 1.66673 7.19348L10.7302 7.19348L7.26316 10.8159C7.00245 11.0872 7.00245 11.526 7.26316 11.7966C7.52386 12.0678 7.9459 12.0678 8.20594 11.7966L12.8065 6.99084Z"
                                                fill="#6F708B" stroke="#6F708B" stroke-width="0.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                <!-- <div class="col-12 col-sm-12 col-md-12 my-2 my-lg-0 my-xl-0 cursor"  [ngClass]="{ 'col-lg-6': (userService.user.role == 'superadmin' && !this.org_id) ,'col-lg-4' : (userService.user.role != 'superadmin' || is_local)}" [class.pointer]="userService.organization_id != '000' && ['superadmin','owner','app_admin','mg_admin'].indexOf(userService.user.role)!=-1">
                            <div [routerLink]="userService.organization_id !='000' && ['superadmin','owner','app_admin', 'mg_admin'].indexOf(userService.user.role)!=-1?['/organization', userService.organization_id,'application']:null"
                                class="card dashboard-card">
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-5 col-md-4 d-flex align-items-center">
                                            <svg-icon src="../../../assets/svgs/dashboard_app.svg"></svg-icon>
                                        </div>
                                        <div class="col-7 col-md-8">
                                            <div class="text-center">
                                                <h2 class="font-weight-bold">{{dashboard['applications']}}</h2>
                                                <h6 class="font-weight-bold">{{admin_data.dashboardNoOfApplicationsTitle}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer dashboard-card-footer p-0">
                                    <div [class.d-none]="userService.organization_id == '000'" class="dashboard-card-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13"
                                            fill="none">
                                            <path
                                                d="M12.8065 6.99084C13.0632 6.72369 13.0658 6.27662 12.8065 6.00953L8.20594 1.20319C7.94523 0.931929 7.5232 0.932614 7.26316 1.20319C7.00245 1.47376 7.00245 1.91323 7.26316 2.1838L10.7302 5.80626L1.66673 5.80626C1.29867 5.80626 1 6.11668 1 6.49987C1 6.88307 1.29873 7.19348 1.66673 7.19348L10.7302 7.19348L7.26316 10.8159C7.00245 11.0872 7.00245 11.526 7.26316 11.7966C7.52386 12.0678 7.9459 12.0678 8.20594 11.7966L12.8065 6.99084Z"
                                                fill="#6F708B" stroke="#6F708B" stroke-width="0.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                <div class="col-12 col-sm-12 col-md-12 my-2 my-lg-0 my-xl-0 cursor"
                    [ngClass]="{ 'col-lg-6': ((userService.user.role == 'superadmin' || userService.user.role == 'partner_admin') && !this.org_id) ,'col-lg-4' : (userService.user.role != 'superadmin' || is_local)}"
                    [class.pointer]="(is_global || is_local)">
                    <div [routerLink]="is_global? ['/users']: (is_local? ['/organization', userService.organization_id,'users'] : null)"
                        class="card dashboard-card">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-5 col-md-4 d-flex align-items-center">
                                    <svg-icon src="../../../assets/svgs/dashboard_users.svg"></svg-icon>
                                </div>
                                <div class="col-7 col-md-8 align-content-center">
                                    <div class="text-center">
                                        <h4 class="font-weight-bold">{{admin_data.usersBreadCrumbTitle}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer dashboard-card-footer p-0">
                            <div [class.d-none]="userService.organization_id == '000'" class="dashboard-card-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                    <path
                                        d="M12.8065 6.99084C13.0632 6.72369 13.0658 6.27662 12.8065 6.00953L8.20594 1.20319C7.94523 0.931929 7.5232 0.932614 7.26316 1.20319C7.00245 1.47376 7.00245 1.91323 7.26316 2.1838L10.7302 5.80626L1.66673 5.80626C1.29867 5.80626 1 6.11668 1 6.49987C1 6.88307 1.29873 7.19348 1.66673 7.19348L10.7302 7.19348L7.26316 10.8159C7.00245 11.0872 7.00245 11.526 7.26316 11.7966C7.52386 12.0678 7.9459 12.0678 8.20594 11.7966L12.8065 6.99084Z"
                                        fill="#6F708B" stroke="#6F708B" stroke-width="0.5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="userService.user.role == 'superadmin' && !this.org_id" class="col-12 col-sm-12 col-md-12 my-2 my-lg-0 my-xl-0 cursor"
                    [ngClass]="{ 'col-lg-6': (userService.user.role == 'superadmin' && !this.org_id) ,'col-lg-4' : (userService.user.role != 'superadmin' || is_local)}"
                    [class.pointer]="['superadmin'].indexOf(userService.user.role)!=-1">
                    <div [routerLink]="userService.organization_id !='000' && ['superadmin'].indexOf(userService.user.role)!=-1?['/organization', userService.organization_id,'users']:['app_admin','creator'].indexOf(userService.user.role)!=-1?null:['/partners']"
                        class="card dashboard-card">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-5 col-md-4 d-flex align-items-center">
                                    <svg-icon src="../../../assets/svgs/dashboard_users.svg"></svg-icon>
                                </div>
                                <div class="col-7 col-md-8 align-content-center">
                                    <div class="text-center">
                                        <h4 class="font-weight-bold">{{admin_data.partners}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer dashboard-card-footer p-0">
                            <div [class.d-none]="userService.organization_id == '000'" class="dashboard-card-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                    <path
                                        d="M12.8065 6.99084C13.0632 6.72369 13.0658 6.27662 12.8065 6.00953L8.20594 1.20319C7.94523 0.931929 7.5232 0.932614 7.26316 1.20319C7.00245 1.47376 7.00245 1.91323 7.26316 2.1838L10.7302 5.80626L1.66673 5.80626C1.29867 5.80626 1 6.11668 1 6.49987C1 6.88307 1.29873 7.19348 1.66673 7.19348L10.7302 7.19348L7.26316 10.8159C7.00245 11.0872 7.00245 11.526 7.26316 11.7966C7.52386 12.0678 7.9459 12.0678 8.20594 11.7966L12.8065 6.99084Z"
                                        fill="#6F708B" stroke="#6F708B" stroke-width="0.5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="d-flex flex-row-reverse">
                <app-button *ngIf="(userService.organization_id!='000') && (userService.user.role == 'owner' || userService.user.role == 'superadmin' || userService.user.role == 'mg_admin' || userService.user.role == 'partner_admin')" 
                            [buttonData]="dbService.trigger_button" 
                            (clickhandler)="trigger()">
                </app-button>

                <app-button *ngIf="(userService.organization_id!='000') && (userService.user.role == 'superadmin')" 
                [buttonData]="dbService.clear_session" 
                (clickhandler)="clear_session()">
                </app-button>
            </div>
            <div *ngIf="userService.user.role == 'superadmin' && this.userService.organization_id === '000'" class="d-flex flex-column col-12">
                <div *ngIf="generatedDashboard" class="pr-3">
                    <div class="d-flex flex-row mb-3">
                        <div class="col-6 card p-0 mr-3">
                            <div class="card-body font20 fontWeight-700">
                                <div>
                                    {{this.admin_data.dashboardNoOfOrganisationsTitle}}
                                </div>
                                <div class="text-center font20 fontWeight-700">
                                    {{this.dashboard['organizations']}}
                                </div>
                            </div>
                            <div class="card-footer text-center bg-white">
                                    <span role="button" (click)="scrollToOrganization()"
                                    class="primaryFont-color font15 fontWeight-700">{{this.admin_data.tableTitle2}}</span>
                            </div>
                        </div>
                        <div class="col-6 card p-0">
                            <div class="card-body font20 fontWeight-700">
                                <div>
                                    {{this.admin_data.dashboardNoOfApplicationsTitle}}
                                </div>
                                <div class="text-center font20 fontWeight-700">
                                    {{this.dashboard['applications']}}
                                </div>
                            </div>
                            <div class="card-footer text-center bg-white">
                                <span role="button"
                                    class="font15 fontWeight-700" style="color: #BBBBBB;">{{this.admin_data.tableTitle2}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row mb-3">
                        <div class="col-6 card p-0 mr-3">
                            <div class="card-body font20 fontWeight-700">
                                <div>
                                    {{this.admin_data.dashboardNoOfUsersTitle}}
                                </div>
                                <div class="text-center font20 fontWeight-700">
                                    {{this.dashboard['users']}}
                                </div>
                            </div>
                            <div class="card-footer text-center bg-white">
                                <span role="button" [routerLink]="['/users']"
                                    class="primaryFont-color font15 fontWeight-700">{{this.admin_data.tableTitle2}}</span>
                            </div>
                        </div>
                        <div class="col-6 card p-0">
                            <div class="card-body font20 fontWeight-700">
                                <div>
                                    {{this.admin_data.dashboardNoOfPartnersTitle}}
                                </div>
                                <div class="text-center font20 fontWeight-700">
                                    {{this.dashboard['partners']}}
                                </div>
                            </div>
                            <div class="card-footer text-center bg-white">
                                <span role="button" [routerLink]="['/partners']"
                                    class="primaryFont-color font15 fontWeight-700">{{this.admin_data.tableTitle2}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex p-0">
                    <div *ngIf="!generatedDashboard" class="col-3 col-sm-4 col-md-4 col-lg-2 col-xl-2 ml-0 p-0 mr-2">
                        <div class="d-flex bg-white p-3 rounded" role="button" (click) = generateDashboard()>
                            <!-- <svg-icon src="../../assets/svgs/flag-icon.svg" class="feature-flag-icon"
                                [applyClass]="trueValue"></svg-icon> -->
                            <span class="ml-2">{{admin_data.generateDashboard}}</span></div>
                    </div>
                    <div class="col-3 col-sm-4 col-md-4 col-lg-2 col-xl-2 ml-0 p-0">
                        <div class="d-flex bg-white p-3 rounded" role="button" (click) = navigateToFeatureFlags()>
                            <svg-icon src="../../assets/svgs/flag-icon.svg" class="feature-flag-icon"
                                [applyClass]="trueValue"></svg-icon>
                            <span class="ml-2">{{admin_data.globalFeatureFlags}}</span></div>
                    </div>
                    <div class="col-3 col-sm-4 col-md-4 col-lg-2 col-xl-2 ml-2 p-0">
                        <div class="d-flex bg-white p-3 rounded" role="button" (click)=navigateToPlatformUsage()>
                            <!-- <svg-icon src="../../assets/svgs/flag-icon.svg" class="feature-flag-icon" [applyClass]="trueValue"></svg-icon> -->
                            <span class="ml-2">{{admin_data.platFormUsageReport}}</span>
                        </div>
                    </div>

                    <div *ngIf="(userService.user.role == 'superadmin')"  class="col-3 col-sm-4 col-md-4 col-lg-2 col-xl-2 ml-2 p-0">
                        <div class="d-flex bg-white p-3 rounded" role="button" (click)=navigateToCustomersExtension()>
                            <!-- <svg-icon src="../../assets/svgs/flag-icon.svg" class="feature-flag-icon" [applyClass]="trueValue"></svg-icon> -->
                            <span class="ml-2">{{admin_data.customExtention}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-3 col-sm-4 col-md-4 col-lg-2 col-xl-2 ml-2 p-0">
                    <div class="d-flex bg-white p-3 rounded" role="button" [routerLink]="['/partners']">
                        <span class="ml-2">{{admin_data.partners}}</span>
                    </div>
                </div> -->
            </div>
            <app-organisation class="w-100" *ngIf="(userService.organization_id=='000')"
                (dashboard)="loadDashboard($event)">
            </app-organisation>
            
            <ng-container *ngIf="(userService.organization_id!=='000' && this.userService.user.role !== 'superadmin')">
                <app-application-list class="w-100 mx-2" ></app-application-list>
            </ng-container>
        </div>
    </div>
</div>