import {
	Component,
	OnInit,
	ChangeDetectorRef,
	AfterViewChecked,
	HostListener,
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
import { UserService } from "./common/services/user.service";
import { LoaderService } from "./common/services/loader.service";
import { LocationStrategy } from "@angular/common";
import { OrganisationService } from "./common/services/organisation.service";
import { Subject } from "rxjs";
import { Subscription } from 'rxjs';
import { environment } from "../../src/environments/environment";
import { filter } from 'rxjs/operators';
import adminLabels from "./constants/ApplicationStrings/trans_utils";
import { API_END_POINTS } from "./constants/api-end-points";
import { HttpClient, HttpHeaders } from "@angular/common/http";
declare const $;

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewChecked {
	//     @HostListener('document:click', ['$event'])
	//   onCLoadEvent(event) {

	//   }
	handoff_token: string;
	firstRedirect: string;
	navigate_to_path: string;
	title = "guideme-web-new";
	isLoading: Subject<boolean> ;
	showLoader = false;
	bottomMargin = true;
	visibleFlag = 1;
	cuurentYear = new Date(Date.now()).getUTCFullYear();
	admin_data = adminLabels;
	appLoader: boolean = true;
	languageLoader: string = "english";
	version: String = "2025.2.0";
	url_path:boolean;
	maintenanceFlag = false;
	private appLoaderSubscription: Subscription;
	private languageLoaderSubscription: Subscription;
	constructor(
		private router: Router,
		public userService: UserService,
		public loader: LoaderService,
		private organisationService: OrganisationService,
		private location: LocationStrategy,
		private cdRef: ChangeDetectorRef,
		private route: ActivatedRoute,
        private http: HttpClient
	) {
        
	this.isLoading = this.userService.visible;
		// this.location.onPopState(()=>{
		//   if(this.router.url=='/login' && this.userService.isLoggedIn()){
		//     this.router.navigate(['']);
		//   }
		// });

		this.appLoaderSubscription = this.loader.getVariable().subscribe(value => {
			this.appLoader = value;
		});

		this.languageLoaderSubscription = this.loader.getLanguage().subscribe(value => {
			this.languageLoader = value;
		});


		//  this.appLoader = this.loader.getVariable("app constructor");


		this.isLoading.subscribe((data) => {
			if (data) {
				this.showLoader = true;
			} else {
				this.showLoader = false;
			}
		});

		this.route.queryParams.subscribe(params => {
			if (localStorage.getItem('language')) {
				localStorage.setItem('language', localStorage.getItem('language'))
			}
			else {
				localStorage.setItem('language', 'english')
			}

			// localStorage.setItem("language", "english");
			this.appLoader = false;
			this.navigate_to_path = params['navigate_to_path'];
			this.handoff_token = params['handoff-token'];
			if (this.handoff_token) {
				if (this.handoff_token !== "") {
					const query = new URLSearchParams(window.location.search);
					const urlData = [];
					query.forEach((v, k) => {
						urlData[k] = v;
					});
					this.router.navigate(["/loader"], { queryParams: urlData });
					this.setHandoffToken(this.handoff_token, this.navigate_to_path);
				}
				else {
					this.router.navigate(["/login"])
				}
			}
			else {
				//   let firstRedirect = btoa("admin");
				//   window.open(environment.INSIGHTS_URL+'/?firstRedirect='+firstRedirect, "_self");
			}
		});

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				this.visibleFlag = window.location.href.includes('redirectURL') ? 0 : 1;
				if (window.location.href.includes('redirectURL')) {
					this.bottomMargin = false;
				} else {
					let routes = ['otp', 'login', 'forgotpassword', 'password', 'loader'];
					this.bottomMargin = true;
					let urlArray = this.router.url.split("/");
					for (let i = 0; i < urlArray.length; i++) {
						if (routes.indexOf(urlArray[i]) != -1) {
							this.bottomMargin = false;
							break;
						}
					}
				}
			});
	}

    ngOnInit() {
        this.cdRef.detectChanges();
        if (localStorage.getItem('user') != null && !window.location.href.includes('handoff-token') && !window.location.href.includes('redirectURL') && !window.location.href.includes('redirectFromMaintenance') && this.userService.isLoggedIn) {
            let user = JSON.parse(localStorage.getItem('user'))
            let urlPath = environment.baseUrl + API_END_POINTS.GET_USER + "?user_id=" + user['user_id'];
            const userOptions = {
                headers: new HttpHeaders({
                    // AppKey: environment.authKey,
                    AccessToken: user['token']
                }),
            };

            this.http.get(urlPath, userOptions).subscribe((response) => {
                let new_user = response['data']['user']
                let settings = JSON.parse(new_user['settings']);
                if ((settings["preferredLanguage"]) && (settings["preferredLanguage"]["UILanguagePreference"] != JSON.parse(user['settings'])["preferredLanguage"]["UILanguagePreference"])){
                    user['settings'] = JSON.stringify(settings)
                    localStorage.setItem('user',JSON.stringify(user));
                    }
            })

        }

		if(window.location.href.includes('maintenance_mode')){
			
			this.maintenanceFlag = true;
			console.log(this.maintenanceFlag)
		}
    }

	ngAfterViewChecked() {
		this.url_path = window.location.href.includes('login');
		this.cdRef.detectChanges();
		let height;
		let elem1 = document.getElementsByClassName('navbar-expand')[0];
		if (elem1) {
			let h1 = document.getElementsByClassName('navbar-expand')[0].clientHeight;
			height = window.innerHeight - h1;
		}
		let el = document.getElementById("sidebar");
		if (el && elem1) {
			if (el.clientHeight != height) {
				el.setAttribute("style", "min-height:" + height + "px");
			}
		}
        if(localStorage.getItem('user')!= null && !window.location.href.includes('redirectURL') && this.userService.isLoggedIn() && !window.location.href.includes('handoff-token')){
            this.userService.checkLanguage()
        }
	}


	ngOnDestroy() {
		this.appLoaderSubscription.unsubscribe();
		this.languageLoaderSubscription.unsubscribe();
	}

	setHandoffToken(handoff_token, navigate_to_path) {
		let token = {};
		let user = {};
		let organization = {};

		this.userService.getUserLoginHandoff(handoff_token).subscribe((response) => {
			if (response["status"] == 'success' && response["data"]["user"]) {
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				localStorage.removeItem("organization");
				localStorage.removeItem("cdn_sign");

				this.userService.user = response["data"]["user"];
				this.userService.user.token = response["data"]["user"]["accesstoken"];
				this.userService.user.refreshtoken = response["data"]["user"]["refreshtoken"];
				this.userService.user.role = response["data"]["user"]["role"];

				token["accesstoken"] = response["data"]["user"]["accesstoken"];
				token["refreshtoken"] = response["data"]["user"]["refreshtoken"];
				let org = response["data"]["user"]["organization"];
				user = response["data"]["user"];

				localStorage.setItem("token", JSON.stringify(token));
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("organization", JSON.stringify(org));

				//   this.organisationService.cdn_signature = this.userService.user.cdn_signature;
				//   this.organisationService.cdn_signature_expiry = this.userService.user.cdn_signature_expiry;

				if ((this.userService.user.role == "superadmin")) {
					this.userService.organization.name = " ";
					this.userService.organization_id = "000";
				} else if (this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin") {
					this.userService.organization_id = response["data"]["user"]["org_ids"][0];
					if (this.userService.user.role == "partner_admin") {
						this.userService.partner_id = response["data"]["user"]["partner_id"]
					}
				}
				else {
					// this.userService.organization.name =
					//   response["data"]["user"]["organization"]["name"];
					this.userService.organization_id = response["data"]["user"]["org_ids"][0];
					this.organisationService.getOrganization(response["data"]["user"]["org_ids"][0])
						.subscribe((responseOrg) => {
							organization = responseOrg["data"]["organization"];
							this.userService.organization.name = responseOrg["data"]["organization"]["name"];
							localStorage.setItem("organization", JSON.stringify(organization));
						});
				}

				//   let cdn_sign={};
				//   organization = response["data"]["user"]["organization"];
				//   cdn_sign["cdn_signature"] = this.organisationService.cdn_signature;
				//   cdn_sign["cdn_signature_expiry"] = this.organisationService.cdn_signature_expiry;
				//   if((this.userService.user.role != "superadmin" && this.userService.user.role != "mg_admin" && this.userService.user.role != "partner_admin")){
				//   localStorage.setItem("organization", JSON.stringify(organization));
				//   }
				//   localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));


				if (navigate_to_path) {
					let myArray = atob(navigate_to_path).split("/");
					let myArrayLength = myArray.length;
					myArray[0] = "/" + myArray[0];
					this.router.navigate([myArray[0], myArray[1], myArray[2]])
				}
				else {
					this.router.navigate([""]);
				}

				// uncomment else block if it causes issues; change "/loader" to "" if issue still persists 
			}
			else {

				//   let appData = {};
				//   // appData['token']=response['data']['token'];
				//   this.userService.token = response["data"]["token"];
				//   this.userService.token = response["data"]["token"];
				//   // localStorage.setItem('appData',JSON.stringify(appData));
				//   let token = {};
				//   token["token"] = this.userService.token;
				//   token["email"] = req["email_id"];
				//   localStorage.setItem("token", JSON.stringify(token));
				this.router.navigate(["/login"]);
			}
		});
	}

}
