
import { Component, OnInit } from '@angular/core';
import { ADD_EXTENSION_FORMADATA } from '../../../constants/FormData/add_extension';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApplicationService } from '../../../common/services/application.service';
import { UserService } from '../../../common/services/user.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CustomersExtensionService } from '../../../common/services/customers-extension.service';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from 'src/app/common/services/toast-modal.service';
import adminLabels from "../../../constants/ApplicationStrings/trans_utils";


@Component({
  selector: 'app-add-edit-extension',
  templateUrl: './add-edit-extension.component.html',
  styleUrl: './add-edit-extension.component.scss'
})

export class AddEditExtensionComponent implements OnInit {

  admin_data = adminLabels;
  id;
  extensionData=[];
  isEdit = false;

  constructor(public userService: UserService, 
    public customersExtensionService: CustomersExtensionService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private toastr: ToastrService, 
    private titleService: Title, 
    private errorResponseService: ErrorResponseService, 
    private toastService: ToastModalService) {

      this.route.paramMap.subscribe((params) => {
        this.id = params["params"]["id"];
        if(this.id){
          this.getExtension();
          this.isEdit = true;
        } else {
          this.userService.hide(); 
          this.isEdit = false;
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.userService.setBreadcrumbs("Add", this.route.url['_value']);
        let breadCrumb = [
          {
            name: this.admin_data.addExtension,
            route: null
          }
        ];

		
        if (this.userService.organization_id === "000") {
          let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
          breadCrumb.unshift(tempDashboardBreadCrumb);
        }
        this.userService.setBreadcrumb(breadCrumb)
        this.breadcrumb = this.userService.breadCrumbs;
      });
  }

  add_extension_formdata = JSON.parse(JSON.stringify(ADD_EXTENSION_FORMADATA));

  formData = new FormData();
  hasUploaded = false;
  breadcrumb;
  formGroup;


  ngOnInit() {



    this.titleService.setTitle(this.admin_data.addExtension + " | " + "Cornerstone Guide");
    this.userService.getUserData();
    // this.organisation.getCDNData();
      this.userService.organization_id = '000';

    let form = {};
    for (let i = 0; i < this.add_extension_formdata.length; i++) {
      form[this.add_extension_formdata[i].name] = new FormControl('');
      let validations = [];
      for (let v of this.add_extension_formdata[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case ('required'):
            validators.push(Validators.required);
            break;
        }
      }
      form[this.add_extension_formdata[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);
    let inputValidateField = ["name", "description"];
    for (let ind = 0; ind < inputValidateField.length; ind++) {
      this.formGroup.get(inputValidateField[ind]).valueChanges.subscribe(x => {
        if (x && !this.userService.pattern.test(x)) {
          this.formGroup.get(inputValidateField[ind]).setValue(x.replace(this.userService.replacePattern, ""), { emitEvent: false });
        }
      });
    }
  }

  addExtension() {

    this.customersExtensionService.add_button.disable = true;
    this.customersExtensionService.add_button.text = this.admin_data.addingButtonText;

	let request = {
				name: this.formGroup.get("name")?.value?.trim() || "",
				description: this.formGroup.get("description")?.value?.trim() || "",
				version: this.formGroup.get("version")?.value?.trim() || "",
        show_widget: this.formGroup.get("showWidget")?.value == "1" ? "true" : "false",
        matches: this.formGroup.get("matches")?.value?.trim() || "",
        exclude_matches: this.formGroup.get("exclude_matches")?.value?.trim() || "",
        sso_id: this.formGroup.get("ssoId")?.value?.trim() || "",
        client_js_base_url: this.formGroup.get("clientJsBaseUrl")?.value?.trim() || "",
        host_permissions: this.formGroup.get("host_permissions")?.value?.trim() || "",
        login: this.formGroup.get("login")?.value?.trim() ,
        customer: this.formGroup.get("customer")?.value?.trim() || "",
        desktop_communication: this.formGroup.get("desktopCommunication")?.value == "1" ? "true" : "false",
        i_frame_injection: this.formGroup.get("iframeInjection")?.value == "1" ? "true" : "false",
        testme: this.formGroup.get("testme")?.value == "1" ? "1" : "0",
        icons_zip_path: 'guideme-player/build-assets/'+this.formGroup.get("customer")?.value?.trim()+'/icons.zip',
	  };

		this.customersExtensionService.addExtension(request).subscribe((response) => {
		this.customersExtensionService.add_button.disable = false;
		this.customersExtensionService.add_button.text = this.admin_data.addButtonText;
		this.toastService.showToast = true;
		this.toastService.toastType = "extension_added";

    this.toastService.showToastMessage('extension_added');


		this.customersExtensionService.add_button.text = this.admin_data.addButtonText;
		this.router.navigate(['/customers_extension']);
		}, error => {
		this.customersExtensionService.add_button.disable = false;
		this.customersExtensionService.add_button.text = this.admin_data.addButtonText;
		})
  }


  updateExtension() {

    this.customersExtensionService.add_button.disable = true;
    this.customersExtensionService.add_button.text = this.admin_data.addingButtonText;
  
	  let request = {
        id: this.id,
				name: this.formGroup.get("name")?.value?.trim() || "",
				description: this.formGroup.get("description")?.value?.trim() || "",
				version: this.formGroup.get("version")?.value?.trim() || "",
        show_widget: this.formGroup.get("showWidget")?.value == "1" ? "true" : "false",
        matches: this.formGroup.get("matches")?.value?.trim() || "",
        exclude_matches: this.formGroup.get("exclude_matches")?.value?.trim() || "",
        sso_id: this.formGroup.get("ssoId")?.value?.trim() || "",
        client_js_base_url: this.formGroup.get("clientJsBaseUrl")?.value?.trim() || "",
        host_permissions: this.formGroup.get("host_permissions")?.value?.trim() || "",
        login: this.formGroup.get("login")?.value?.trim() ,
        customer: this.formGroup.get("customer")?.value?.trim() || "",
        desktop_communication: this.formGroup.get("desktopCommunication")?.value == "1" ? "true" : "false",
        i_frame_injection: this.formGroup.get("iframeInjection")?.value == "1" ? "true" : "false",
        testme: this.formGroup.get("testme")?.value == "1" ? "1" : "0",
        icons_zip_path: 'guideme-player/build-assets/'+this.formGroup.get("customer")?.value?.trim()+'/icons.zip',
	  };

		this.customersExtensionService.updateExtension(request).subscribe((response) => {
		this.customersExtensionService.add_button.disable = false;
		this.customersExtensionService.add_button.text = this.admin_data.addButtonText;
		this.toastService.showToast = true;
		this.toastService.toastType = "extension_updated";

    this.toastService.showToastMessage('extension_updated');


		this.customersExtensionService.add_button.text = this.admin_data.addButtonText;
		this.router.navigate(['/customers_extension']);
		}, error => {
		this.customersExtensionService.add_button.disable = false;
		this.customersExtensionService.add_button.text = this.admin_data.addButtonText;
		})
  }

  unassign() {
    for (let i = 0; i < this.add_extension_formdata.length; i++) {
      this.formGroup.patchValue({
        [this.add_extension_formdata[i].name]: ''
      })
    }
    this.router.navigate(['/customers_extension']);
  }

  getExtension() {
    this.customersExtensionService
			.getExtention(this.id)
			.subscribe((response) => {
				this.extensionData = response["data"];
				this.formGroup.patchValue({
					name: this.extensionData['name'],
					description: this.extensionData['description'],
					version: this.extensionData['version'],
					showWidget: this.extensionData['show_widget'] == "true" ? true : false,
					matches: this.extensionData['matches'],
					exclude_matches: this.extensionData['exclude_matches'],
					ssoId: this.extensionData['sso_id'],
					clientJsBaseUrl: this.extensionData['client_js_base_url'],
					host_permissions: this.extensionData['host_permissions'],
					login: this.extensionData['login'],
					customer: this.extensionData['customer'],
					desktopCommunication: this.extensionData['desktop_communication'] == 'true' ? true : false,
					iframeInjection: this.extensionData['i_frame_injection'] == 'true' ? true : false,
					testme: this.extensionData['testme'] == '1' ? true : false,
				});

	});
  }
}
