<div class="d-flex">
    <app-spinner *ngIf="!loader"></app-spinner>
    <div class="container-fluid content-container p-3 toggle-view">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <section class="component-section">
                <div class="sectionBody">
                    <div class="d-flex justify-content-end mb-3">
            
                        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
                            {{this.admin_data.customExtentions}}
                        </div>
            
                        <div
                            class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right d-flex align-items-center justify-content-end">
                            <!-- <div class="input-group w-50 ml-2 d-inline-flex width-300">
                                <input id="searchInputApp" class="form-control py-2 search" type="search" autocomplete="off"
                                    placeholder={{this.admin_data.searchPlaceholder}}  aria-label="Search"  (input)="search()" (search)="search()">
                            </div>
                          -->
                            <button 
                                type="button" class="btn btn-primary ml-1"
                                [routerLink]="['/add-extension']">{{this.admin_data.addExtension}}</button>
                          
                        </div>
                    </div>

                    <div class="d-flex scroll-x w-1240 table-container marginTop-30">
                        <table *ngIf="configs.length>0" class="table table-bordered break-w mg-table rounded-table">
                            <colgroup>
                                <col style="width: 40%;">
                                <col style="width: 30%;">
                                <col style="width: 30%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th *ngFor="let header of tableHeading; index as i">{{header}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of configs; index as i">
                                    <td>{{data['customer']}}</td>
                                    <td>{{data['identifier']}}</td>
                                    <td>
                                        <svg-icon src="../../../assets/svgs/edit.svg" role="button" (click)="navigateToAddExtension(data['id'])"></svg-icon>
                                        <svg-icon src="../../../assets/svgs/emit_delete.svg" role="button" (click)="showDeleteModal(data['id'])"></svg-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="configs.length == 0" class="no-guide">
                            <img src="./../../assets/svgs/no_guides_image.svg">
                            <div class="no-guide-label-1">
                                <p>{{this.admin_data.noGuideFound}}</p>
                            </div>
                            <div class="no-guide-label-2">
                                <p>{{this.admin_data.modifySearchCriteria}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- <app-pagination [offset]="offset" [totalRecords]="paginationService.total"
                        [currentPage]="paginationService.currentPage" [limit]="limit" (gotoFirstPage)="gotoFirstPage()"
                        (gotoLastPage)="gotoLastPage($event)" (gotoPreviousPage)="prependOffset()"
                        (gotoNextPage)="appendOffset()" (changeLimit)="changeLimit($event)"
                        (gotoAction)="gotoPage($event)"></app-pagination> -->
                    
             
                        <!-- <ng-container>
                            <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
                            [modal_ok_value]="this.userService.delete_button.text" [org]=""
                            [disabled]="this.userService.delete_button.disable"
                            (onDelete)="deleteExtension($event)"></app-modal-confirmation>
                        </ng-container> -->

                        <div class="modal fade" tabindex="-1" role="dialog" id="deleteModal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h6 class="modal-title">{{admin_data.deleteExtension}}</h6>
                                        <svg-icon role="button" (click)="closeModal()" src="../../../assets/svgs/close-icon-large.svg"></svg-icon>
                                    </div>
                                    <div class="modal-body font14 tags">
                                        <div class="d-inline-flex w-100 align-items-left">
                                            <p>{{admin_data.deleteExtensionWarning}}</p>
                                        </div>
                                    </div>
                                    <div class="modal-footer justify-content-end">
                                        <button type="button" class="btn btn-secondary minWidth-120 mx-2" data-dismiss="modal"
                                            (click)="closeModal()">{{this.admin_data.cancelButtonText}}</button>
                                        <button type="button"  class="btn btn-danger minWidth-120 mx-2"
                                            (click)="deleteExtension()" [disabled]="isDeleteDisabled">{{this.admin_data.delete}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        </section>
    </div>
</div>
