import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_END_POINTS } from 'src/app/constants/api-end-points';
import { environment } from "src/environments/environment";
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { Observable, Subject, of, tap } from 'rxjs';
import { HttpClientModule, HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomersExtensionService {
  private extensionDataTimeout: any = null;
  extensionData;
  constructor(private apiService: ApiService) { }

  showConfirmationModal = new Subject<boolean>();

  add_button={
    type:'add_organization',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text: adminLabels.addButtonText
  }
  
  update_button={
    type:'update_organization',
    class:'btn btn-primary minWidth-120 mx-2',
    disable:false,
    text: adminLabels.updateButtonText
  }
  
  cancel_button={
    type:'cancel',
    class:'btn btn-secondary minWidth-120',
    disable:false,
    text: adminLabels.cancelButtonText
  }


  addExtension(request){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_EXTENSION;
    console.log(urlPath)
    return this.apiService.post(urlPath,request);
  }

  updateExtension(request){
    let urlPath=environment.baseUrl+API_END_POINTS.GET_EXTENSION;
    console.log(urlPath)
    return this.apiService.put(urlPath,request);
  }

//   GET_CUSTOM_EXTENSION
    getCustomExtensions(){
		let  urlPath =
		  environment.baseUrl + API_END_POINTS.GET_CUSTOM_EXTENSION
      return this.apiService.get(urlPath);
    }

    getExtention(id){
        let urlPath = environment.baseUrl + API_END_POINTS.GET_EXTENSION + "?id=" + id;
        return this.apiService.get(urlPath).pipe(
          tap((response: any) => {
            this.extensionData = response;
            console.log(response["data"]);
            if (this.extensionDataTimeout) {
              clearTimeout(this.extensionDataTimeout);
            }
    
            this.extensionDataTimeout = setTimeout(() => {
              this.extensionData = null;
            }, 900000);
          })
        );
    }

	deleteExtension(id){
		let httpParams = new HttpParams().set('id', id);
		let urlPath=environment.baseUrl+API_END_POINTS.GET_EXTENSION;
		return this.apiService.delete(urlPath,httpParams);
	}
	  
}
